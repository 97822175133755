import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import { DatePicker } from "antd";
import moment from "moment";
import { Form, Modal, Spinner } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { toast } from "react-toastify";
import CampaignTable from "../../components/Campaign/CampaignTable";
import ChartSkeleton from "../../components/ChartSkeleton";
import { validateCampaign } from "../../resources/Validations";
import services from "../../services";
toast.configure();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f2f2f2",
  },
}));

const Campaigns = (props, { business, setNewBusiness }) => {
  const classes = useStyles();
  const { setPage } = props;
  const theme = useTheme();
  const [value, setValue] = useState(1);
  const { client } = useSelector((state) => state.client);
  const [profilePic, setProfilePic] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const [campaignForm, setCampaignForm] = useState({});
  const [focusedInput, setFocusedInput] = useState(null);
  const [future, setFuture] = useState([]);
  const [past, setPast] = useState([]);
  const [present, setPresent] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [resImage, setResImg] = useState();

  const handleChange = (event, newValue) => setValue(newValue);

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const handleCreate = async () => {
    setLoading(true);
    campaignForm.client = client._id;
    campaignForm.campaign_type = "C";
    await services.CampaignServices.addCampaign(campaignForm)
      .then((res) => {
        notify("success", "Campaign created successfully.");
        setCampaignForm({});
        setShowCreate(false);
        setCampaigns(res?.data);
        setValue(2);
        getCampaigns();
      })
      .catch((err) => {
        console.error("create campaign error: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCampaigns = () => {
    const current = new Date().toUTCString();
    const apast = [],
      apresent = [],
      afuture = [];

    services.CampaignServices.getCampaigns()
      .then((res) => {
        setCampaigns(res?.data?.data?.campaigns);
        res?.data?.data?.campaigns.map((campaign) => {
          const campaign_start_date = new Date(
            campaign.campaign_start_date
          ).toUTCString();
          const campaign_expire_date = new Date(
            campaign.campaign_expiration_date
          ).toUTCString();
          if (moment(campaign_expire_date).isBefore(current))
            apast.push(campaign); //Populate past campaigns
          if (
            moment(campaign_start_date).isSameOrBefore(current) &&
            moment(campaign_expire_date).isSameOrAfter(current)
          )
            apresent.push(campaign); // Populate present campaigns
          if (moment(campaign_start_date).isAfter(current))
            afuture.push(campaign); // Populate future campaigns
          return true;
        });
        setPast(apast);
        setPresent(apresent);
        setFuture(afuture);
      })
      .catch((err) => console.log("getCampaigns error", err));
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const uploadImage = async (e) => {
    var file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImage(base64);
    await services.CampaignServices.uploadImage({
      type: "products",
      data: base64,
    })
      .then((response) => response.json())
      .then((response) => {
        setResImg(response.data.imagePath);
        notify("success", "Image Upload Successfully.");
        // setCampaignForm({});
        setProfilePic(response.data.imagePath);
        // setShowCreate(false);
        setCampaigns(response);
        getCampaigns();
      });
    // var reader = new FileReader();
    // reader.onloadend = function() {
    //   console.log('RESULT', reader.result)
    //   setCampaignForm(campaignForm)
    //   setImage(reader.result)
    // }
    // reader.readAsDataURL(file);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // reader.onloadend = function () {
      //   resolve(reader.result)
      //   console.log('RESULT', reader.result)
      //   setCampaignForm(campaignForm)
      //   setImage(reader.result)
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      // }
    });
  };

  const resetForm = () => {
    setShowCreate(false);
    setCampaignForm({});
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Campaigns</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="row my-3 align-items-center">
        <div className="col-md-7 col-12">
          <h4>Campaigns ({campaigns?.length})</h4>
        </div>
        <div className="col-md-5 col-12 text-left text-md-right">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AddCircleIcon />}
            onClick={(_) => setShowCreate(true)}
          >
            CAMPAIGN
          </Button>
        </div>
      </div>
      <div
        className={"col pt-3 " + classes.root}
        style={{ paddingLeft: 0, paddingRight: "0" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          Campaigns
          textColor="primary"
          variant="fullWidth"
          style={{ overflow: "auto" }}
        >
          <Tab
            style={{ minWidth: "230px" }}
            label={"Past Campaigns (" + past?.length + ")"}
            {...a11yProps(0)}
          />
          <Tab
            style={{ minWidth: "230px" }}
            label={"Current Campaigns (" + present?.length + ")"}
            {...a11yProps(1)}
          />
          <Tab
            style={{ minWidth: "230px" }}
            label={"Future Campaigns (" + future?.length + ")"}
            {...a11yProps(2)}
          />
        </Tabs>
        {past ? (
          <>
            <TabPanel value={value} index={0} dir={theme.direction}>
              <CampaignTable
                headings={["Campaign", "Offer Count", "Expired", "View"]}
                data={past}
                period="past"
                {...props}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <CampaignTable
                headings={["Campaign", "Offer Count", "Expires", "View"]}
                data={present}
                period="present"
                {...props}
              />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <CampaignTable
                headings={["Campaign", "Offer Count", "Starts", "View"]}
                data={future}
                period="future"
                {...props}
              />
            </TabPanel>
          </>
        ) : (
          <ChartSkeleton />
        )}

        <Modal
          centered
          show={showCreate}
          onHide={() => setShowCreate(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>CREATE A CAMPAIGN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row mt-3">
                <div className="col-lg-6 col-12">
                  <Form.Label>Campaign Name</Form.Label>
                  <Form.Control
                    className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                    placeholder="Campaign Name"
                    type="text"
                    value={campaignForm.campaign_name || ""}
                    onChange={(event) =>
                      setCampaignForm({
                        ...campaignForm,
                        campaign_name: event.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                  <Form.Label>Campaign Run Period</Form.Label>
                  {/* <RangePicker 
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={campaignForm.campaign_start_date}
                    endDate={campaignForm.campaign_expiration_date}
                    focusedInput={focusedInput}
                    onFocusChange={focused => setFocusedInput(focused)}
                    onChange={({ startDate, endDate }) => setCampaignForm({ ...campaignForm, campaign_start_date: startDate, campaign_expiration_date: endDate })}
                    /> */}
                  <DateRangePicker
                    orientation={
                      window.innerWidth >= 768 ? "horizontal" : "vertical"
                    }
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={campaignForm.campaign_start_date}
                    endDate={campaignForm.campaign_expiration_date}
                    onDatesChange={({ startDate, endDate }) =>
                      setCampaignForm({
                        ...campaignForm,
                        campaign_start_date: startDate,
                        campaign_expiration_date: endDate,
                      })
                    }
                    focusedInput={focusedInput}
                    onFocusChange={(focused) => setFocusedInput(focused)}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <Form.Label>Campaign Description</Form.Label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={campaignForm.campaign_description || ""}
                    onChange={(event) =>
                      setCampaignForm({
                        ...campaignForm,
                        campaign_description: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* <Form.Row className="mt-3">
                  <Form.Group as={Col} md={7}>
                    <Form.Label>CAMPAIGN CODE</Form.Label>
                    <Form.Control
                      style={{ height: 48 }}
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      placeholder="Campaign Code"
                      value={campaignForm.campaign_code || ''}
                      onChange={(event) => setCampaignForm({ ...campaignForm, campaign_code: event.target.value })} />
                  </Form.Group>
                  <Form.Group as={Col} >
                    <Form.Label>CAMPAIGN AMOUNT</Form.Label>
                    <Form.Control
                      style={{ height: 48 }}
                      className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                      placeholder="Campaign Amount"
                      value={campaignForm.campaign_amount || ''}
                      onChange={(event) => setCampaignForm({ ...campaignForm, campaign_amount: event.target.value })} />
                  </Form.Group>
                </Form.Row>

                <Form.Row className="mt-3">
                  <Form.Group as={Col} md={7}>
                    <Form.Label>CAMPAIGN IMAGE</Form.Label>
                    <div className="row mb-5">
                      <div className="col">
                        <input
                          required
                          id="logo"
                          type="file"
                          onChange={(e) => uploadImage(e)}
                          // onChange={(event) => setCampaignForm({ ...campaignForm, campaign_image: event.target.value })}
                          placeholder="Upload logo" />
                      </div>
                      <div className="col">
                        <div className="preview">
                          {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            <img src={`${config.apiUrl}` + profilePic} alt="Brand logo" width="150" />
                          )}
                        </div>
                      </div>
                    </div>
                  </Form.Group>
                </Form.Row> */}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              disabled={validateCampaign(campaignForm) || loading}
              onClick={() => handleCreate()}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="mr-2"
                />
              )}
              Create Campaign
            </Button>
            <Button
              variant="outlined"
              onClick={() => resetForm()}
              style={{ marginLeft: "20px" }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* {loading ? (
          <Spinner animation="border" />
        ) : (
          <img src={profilePic} alt="Brand logo" width="150" />
        )} */}
      </div>
    </>
  );
};

export default Campaigns;
