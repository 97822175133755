import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HomeIcon from "@material-ui/icons/Home";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { toast } from "react-toastify";
import BusinessTable from "./BusinessTable";

toast.configure();

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #f2f2f2",
    },
}));

const ListOfBusiness = (props) => {
    //   const couponImage = require("../../assets/images/logo-placeholder.png");
    const classes = useStyles();
    const { setPage } = props;
    const theme = useTheme();
    const [value, setValue] = React.useState(1);
    const [past, setPast] = useState([]);
    const [present, setPresent] = useState([]);
    const [selectMonthFilter, setSelectMonthFilter] = useState("");
    const [output1, setOutput1] = useState("");

    const LoginBOData = JSON.parse(localStorage.getItem('client'))

    // Copy the invite URL to the clipboard
    const handleInviteClick = () => {
        // const urlToCopy = `http://localhost:3000/auth?referenceId=663350325f306ce9bf84a209`
        const urlToCopy = `${location.origin}/auth?referenceId=${LoginBOData._id}`
        // const urlToCopy = 'https://dev.xclusit.com/#/auth?referenceId=123456';
        navigator.clipboard.writeText(urlToCopy)
            .then(() => {
                alert('URL copied to clipboard!');
            })
            .catch((error) => {
                console.error('Failed to copy URL: ', error);
            });
    };

    return (
        <>
            <div className="row">
                <div className="col">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            color="inherit"
                            href="javascript:;"
                            onClick={() => setPage("dashboard")}
                        >
                            <HomeIcon />
                        </Link>
                        <Typography color="textPrimary">List Of Business</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div
                className="row my-3 align-items-center"
                style={{ marginLeft: "0", marginRight: "0" }}
            >
                <div className="col-md-7 col-12 pl-0 pr-0">
                    <h4>List Of Business</h4>
                </div>
                <div className="col-md-5 col-12 text-left text-md-right pl-0 pr-0">
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        startIcon={<AddCircleIcon />}
                        onClick={handleInviteClick}
                    >
                        INVITE
                    </Button>
                </div>
            </div>
            <div className="row mt-3" style={{ marginLeft: "0", marginRight: "0" }}>
                <div className={"col pl-0 pr-0 " + classes.root}>
                    {past ? (
                        <>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                                <BusinessTable
                                    headings={[
                                        "LOGO",
                                        "CLIENT NAME",
                                        "FIRST NAME",
                                        "LAST NAME ",
                                        "EMAIL",
                                        "ADDRESS",
                                        "CITY",
                                        "PHONE",
                                        "STATE",
                                        "WEBSITE",
                                        "ACTIVATE / DEACTIVATE",
                                        "ISVERIFIED",
                                    ]}
                                    setSelectMonthFilter={setSelectMonthFilter}
                                    data={selectMonthFilter ? output1 : present}
                                    period="present"
                                    {...props}
                                />
                            </TabPanel>
                        </>
                    ) : (
                        <ChartSkeleton />
                    )}
                </div>
            </div>
        </>
    );
};

export default ListOfBusiness;
