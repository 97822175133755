import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";

const DailyUsageChart = ({ data, title }) => {
  let days = [];
  let value = [];
  for (var i = 0; i < 7; i++) {
    const useDate = moment().subtract(i, "days");
    const useCount =
      data?.filter(
        (use) =>
          moment(use.created_at).format("MM-DD-Y") === useDate.format("MM-DD-Y")
      ) || [];
    days.push(useDate.format("ddd"));
    value.push(useCount?.length);
  }

  const options = {
    chart: {
      type: "areaspline",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: days.reverse(),
    },
    yAxis: {
      title: {
        text: `# of ${title}`,
      },
    },
    tooltip: {
      formatter: function () {
        return "<b>" + this.series.name + "</b><br/>" + this.x + ": " + this.y;
      },
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
    },
    series: [
      {
        data: value.reverse(),
        name: title,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DailyUsageChart;
