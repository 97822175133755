import { Box, Button, Grid } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { Alert } from "@mui/material";
import moment from "moment";
import { axiosConfig, notify } from "../../utils/utils";
import { toast } from "react-toastify";

const SubscriptionPlan = ({ setPage, setCustomerId }) => {
  const [AllPlans, setAllPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [valueTabs, setValueTabs] = useState("ActiveSubscription");
  const [activeSubscriptionData, setActiveSubscriptionData] = useState({});

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };
  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const getAllPlans = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/plans/get-plans`,
        null,
        accessToken
      );
      let allPlans = response?.data?.data?.plans;

      // Remove the current plan from all plans
      if (activeSubscriptionData?.activePlan?.isActive) {
        allPlans = allPlans.filter(
          (plan) => plan._id !== activeSubscriptionData?.activePlan?._id
        );
      }
      setAllPlans(allPlans);
    } catch (error) {
      console.error("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (valueTabs == "AllSubscription") {
      getAllPlans();
    }
  }, [valueTabs]);

  const handleStartSubscription = async (subscriptionId) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    const payload = {
      planId: subscriptionId,
    };

    try {
      const response = await axiosConfig(
        "post",
        `${config.apiUrl}/businessadmin/${LoginBOData._id}/start-subscription`,
        payload,
        accessToken
      );
      window.open(response?.data?.redirectUrl, "_blank");
      notify("success", response?.data?.message)
    } catch (error) {
      console.error("start-subscription", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateSubscription = async (subscriptionId) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    const payload = {
      planId: subscriptionId,
    };

    try {
      await axiosConfig(
        "patch",
        `${config.apiUrl}/businessadmin/${LoginBOData._id}/update-subscription`,
        payload,
        accessToken
      );
      // window.open(response.data.redirectUrl, '_blank')
    } catch (error) {
      console.error("update-subscription", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async (AdminId) => {
    const accessToken = localStorage.getItem("token");
    try {
      await axiosConfig(
        "patch",
        `${config.apiUrl}/businessadmin/${AdminId}/cancel-subscription`,
        null,
        accessToken
      );
      getActiveSubScription();
    } catch (error) {
      console.log("cancel-subscription", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getActiveSubScription = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessadmin/${LoginBOData._id}/get-active-subscription`,
        null,
        accessToken
      );
      setActiveSubscriptionData(response?.data?.data);
      setCustomerId(response?.data?.data?.stripeId);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (valueTabs == "ActiveSubscription") {
      getActiveSubScription();
    }
  }, [valueTabs]);

  const featuresActiveSubScription =
    activeSubscriptionData?.activePlan?.description?.trim()?.split("\n") || [];

  const AllSubscriptionName = (PlanId) => {
    if (
      activeSubscriptionData?.isActiveSubscription &&
      activeSubscriptionData?.activePlan?._id === PlanId
    ) {
      return "Current Subscription";
    }
    if (
      activeSubscriptionData?.isActiveSubscription &&
      activeSubscriptionData?.activePlan?._id !== PlanId
    ) {
      return "Update Subscription";
    }
    return "Get Started";
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Plans</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div
        className="row my-3 align-items-center"
        style={{ marginLeft: "0", marginRight: "0" }}
      >
        <div className="col-md-7 col-12 pl-0 pr-0">
          <h4>Plans</h4>
        </div>
      </div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          value={valueTabs}
          onChange={handleChangeTabs}
          aria-label="disabled tabs example"
        >
          <Tab
            value="ActiveSubscription"
            label="Current Subscription"
            style={{ padding: "12px 16px" }}
          />
          <Tab
            value="AllSubscription"
            label="Available Plans"
            style={{ padding: "12px 16px" }}
          />
        </Tabs>
      </Box>
      {/* ActiveSubscription Tab UI */}
      {valueTabs == "ActiveSubscription" && (
        <>
          {!!activeSubscriptionData?.activePlan?.isActive ? (
            <div
              className="d-flex mt-3 justify-content-center"
              style={{ marginLeft: "0", marginRight: "0" }}
            >
              {isLoading ? (
                <Box
                  sx={{
                    height: "150px",
                    background: "#FFFFFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 32,
                        }}
                        spin
                      />
                    }
                  />
                </Box>
              ) : (
                <Grid item xs={12} md={6} lg={4} xl={3}>
                  <div
                    style={{
                      border: "1px solid #e0e0e0",
                      borderRadius: "20px",
                      padding: "32px",
                      background: "#FFFFFF",
                      height: "100%",
                    }}
                  >
                    <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
                      {activeSubscriptionData?.activePlan?.name} (
                      {activeSubscriptionData?.activePlan?.interval})
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "48px",
                        fontWeight: 700,
                        padding: "6px 0px 12px 0px",
                      }}
                    >
                      ${activeSubscriptionData?.activePlan?.amount}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "20px",
                        fontWeight: 600,
                        paddingBottom: "28px",
                      }}
                    >
                      Whats include:
                    </Typography>
                    <Box sx={{ minHeight: "150px" }}>
                      {featuresActiveSubScription.map((subItem, index) => {
                        return (
                          <Typography
                            key={index}
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              lineHeight: "32px",
                            }}
                          >
                            {subItem}
                          </Typography>
                        );
                      })}
                    </Box>
                    {activeSubscriptionData?.isActiveSubscription ? (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{
                            width: "auto",
                            height: "48px",
                            marginTop: "20px",
                          }}
                          onClick={() => {
                            handleCancelSubscription(
                              activeSubscriptionData?.client_id
                            );
                          }}
                          // disabled={!activeSubscriptionData?.isActiveSubscription}
                        >
                          Cancel Subscription
                        </Button>
                      </Box>
                    ) : (
                      <Alert severity="info">
                        Your subscription is canceled, but you can use it until{" "}
                        <span className="font-weight-bold">
                          {moment(activeSubscriptionData?.endDate).format(
                            "DD, MMMM, YYYY"
                          )}
                        </span>
                      </Alert>
                    )}
                  </div>
                </Grid>
              )}
            </div>
          ) : (
            <Box
              sx={{
                height: "150px",
                background: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              className="mt-3"
            >
              No Current Subscription...
            </Box>
          )}
        </>
      )}
      {/* AllSubscription Tab UI */}
      {valueTabs == "AllSubscription" && (
        <>
          {AllPlans?.length === 0 ? (
            <Box
              sx={{
                height: "150px",
              }}
              className="bg-white d-flex align-items-center justify-content-center w-100"
            >
              No Plans Available...
            </Box>
          ) : (
            <div className="d-flex mt-3 justify-content-center">
              <Grid container spacing={2} justify="center">
                {isLoading ? (
                  <Box
                    sx={{
                      height: "150px",
                    }}
                    className="bg-white d-flex align-items-center justify-content-center w-100"
                  >
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 32,
                          }}
                          spin
                        />
                      }
                    />
                  </Box>
                ) : (
                  AllPlans.map((Item, planIndex) => {
                    const features = Item.description?.trim()?.split("\n");
                    return (
                      <Grid item xs={12} md={6} lg={4} xl={3} key={planIndex}>
                        <div
                          style={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "20px",
                            padding: "32px",
                            background: "#FFFFFF",
                            height: "100%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "22px", fontWeight: 600 }}
                          >
                            {Item.name} ({Item.interval})
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "48px",
                              fontWeight: 700,
                              padding: "6px 0px 12px 0px",
                            }}
                          >
                            ${Item.amount}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "20px",
                              fontWeight: 600,
                              paddingBottom: "28px",
                            }}
                          >
                            Whats include:
                          </Typography>
                          <Box sx={{ minHeight: "150px" }}>
                            {features.map((subItem, featureIndex) => {
                              return (
                                <Typography
                                  key={featureIndex}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: 500,
                                    lineHeight: "32px",
                                  }}
                                >
                                  {subItem}
                                </Typography>
                              );
                            })}
                          </Box>
                          <Button
                            disabled={
                              activeSubscriptionData?.isActiveSubscription &&
                              activeSubscriptionData?.activePlan?._id ===
                                Item?._id
                            }
                            variant="contained"
                            style={{
                              width: "100%",
                              height: "48px",
                              marginTop: "20px",
                            }}
                            onClick={() => {
                              !activeSubscriptionData?.isActiveSubscription
                                ? handleStartSubscription(Item.stripePlanId)
                                : handleUpdateSubscription(Item.stripePlanId);
                            }}
                          >
                            {AllSubscriptionName(Item?._id)}
                          </Button>
                        </div>
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SubscriptionPlan;
