import { Home as HomeIcon } from "@material-ui/icons";
import {
  Breadcrumbs,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AddCard as AddCardIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CheckoutForm from "./CheckoutForm";
import Swal from "sweetalert2";
import config from "../../config";
import { Modal } from "react-bootstrap";

const PaymentMethods = ({ setPage, customerId }) => {
  const [paymentMethodsList, setPaymentMethodsList] = useState([]);
  const [isMethodLoading, setIsMethodLoading] = useState(true);
  const [isAddCardOpen, setIsAddCardOpen] = useState(false);

  const handleIsAddCardModalClose = () => {
    setIsAddCardOpen(false);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  // const options = {
  //  clientSecret: process.env.REACT_APP_STRIPE_SECRET_KEY,
  // };

  const fetchPaymentMethods = () => {
    if (customerId) {
      axios
        .get("https://api.stripe.com/v1/payment_methods", {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
          params: {
            customer: customerId,
          },
        })
        .then((response) => {
          // fetch customer
          axios
            .get(`https://api.stripe.com/v1/customers/${customerId}`, {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
              },
            })
            .then((res) => {
              setIsMethodLoading(false);
              const methodList = response?.data?.data.map((i) => ({
                ...i,
                is_default:
                  i?.id === res?.data?.invoice_settings?.default_payment_method,
              }));
              setPaymentMethodsList(methodList);
            })
            .catch((error) => {
              setIsMethodLoading(false);
              console.error(
                "There was an error with the fetch customer request:",
                error
              );
            });
        })
        .catch((error) => {
          console.error("There was an error with the request:", error);
        })
        .finally(() => {
          handleIsAddCardModalClose();
        });
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [customerId]);

  const handleSetDefaultMethod = (id) => {
    axios
      .post(
        `https://api.stripe.com/v1/customers/${customerId}`,
        { "invoice_settings[default_payment_method]": id },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
          },
        }
      )
      .then((response) => {
        fetchPaymentMethods();
      })
      .catch((error) => {
        console.error("There was an error with the request:", error);
      });
  };

  const deleteCardApi = async (id) => {
    setIsMethodLoading(true);
    const LoginBOData = JSON.parse(localStorage.getItem("client"));
    const payload = {
      paymentMethodId: id,
    };
    await axios
      .delete(`${config.apiUrl}/businessadmin/${LoginBOData._id}/delete-card`, {
        data: payload,
      })
      .then((res) => {
        if (res?.data?.success) {
          setIsMethodLoading(false);
          fetchPaymentMethods();
          handleIsAddCardModalClose();
        }
      })
      .catch((err) => {
        setIsMethodLoading(false);
        console.error("delete card error: ", err);
      });
  };

  const handleDeleteCard = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your card has been deleted.",
          icon: "success",
        });
        deleteCardApi(id);
      }
    });
  };

  const loadingCard = () => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <CardContent
          className="bg-white border rounded d-flex align-items-center justify-content-center"
          style={{ minHeight: "178px" }}
        >
          <div>
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 32,
                  }}
                  spin
                />
              }
            />
          </div>
        </CardContent>
      </Grid>
    );
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          href="javascript:;"
          onClick={() => setPage("dashboard")}
        >
          <HomeIcon />
        </Link>
        <Typography color="textPrimary">Payment Method</Typography>
      </Breadcrumbs>

      <div className="mt-3">
        <Grid container spacing={2}>
          {paymentMethodsList?.length ? (
            isMethodLoading ? (
              loadingCard()
            ) : (
              paymentMethodsList?.map((item, index) => (
                // <div className="col-md-3 px-2 mb-3">
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <CardContent className="bg-white border rounded">
                    <div
                      style={{ gap: "10px" }}
                      className="d-flex align-items-center justify-content-between mb-3"
                    >
                      <Typography variant="h5" component="div">
                        {item?.card?.brand}
                      </Typography>
                      <div
                        style={{ gap: "10px" }}
                        className="d-flex align-content-center"
                      >
                        <Chip
                          size="small"
                          label={item?.is_default ? "Default" : "Make Default"}
                          onClick={() => handleSetDefaultMethod(item.id)}
                          disabled={item?.is_default}
                        />
                      </div>
                    </div>

                    <Typography sx={{ mb: 3 }} color="text.secondary">
                      XXXX XXXX XXXX {item?.card?.last4}
                    </Typography>

                    <div className="d-flex justify-content-between">
                      {/* <div>
                          <Typography sx={{ fontWeight: 600 }} variant="body2">
                            Card Holder Name
                          </Typography>
                          <Typography variant="body2">
                            {item?.billing_details?.name
                              ? item?.billing_details?.name
                              : "N/A"}
                          </Typography>
                        </div> */}

                      <div>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">
                          Expiry Date
                        </Typography>
                        <Typography variant="body2">
                          {item?.card?.exp_month}/{item?.card?.exp_year}
                        </Typography>
                      </div>

                      {item?.is_default ? null : (
                        <IconButton
                          aria-label="delete"
                          color="error"
                          size="small"
                          onClick={() => handleDeleteCard(item?.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </div>
                  </CardContent>
                </Grid>
                // </div>
              ))
            )
          ) : isMethodLoading ? (
            loadingCard()
          ) : (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CardContent
                className="bg-white border rounded d-flex align-items-center justify-content-center"
                style={{ minHeight: "178px" }}
              >
                <div>Currently No Payment Method Found!</div>
              </CardContent>
            </Grid>
          )}

          {/* no payment found */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            onClick={() => setIsAddCardOpen(true)}
            sx={{ cursor: "pointer" }}
          >
            <CardContent
              className="bg-white border rounded d-flex align-items-center justify-content-center"
              style={{ minHeight: "178px" }}
            >
              <div>
                <AddCardIcon /> Add New Payment Method
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </div>

      <Modal
        show={isAddCardOpen}
        onHide={handleIsAddCardModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Elements
            stripe={stripePromise}
            // options={options}
          >
            <CheckoutForm
              successCallback={fetchPaymentMethods}
            />
          </Elements>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PaymentMethods;
