import React, { useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import axios from "axios";
import Chart from "react-apexcharts";
import config from "../../../config";
import { extractChartData, isAllDataEmpty } from "../../../utils/chartUtils";
import ChartSkeleton from "../../ChartSkeleton";
import { axiosConfig } from "../../../utils/utils";

const Dashboard = (props) => {
  const { setPage } = props;
  const [chartName, setChartName] = useState("TotalUsers");
  const [isLoading, setIsLoading] = useState(false);
  const [allDashboardData, setAllDashboardData] = useState({});

  useEffect(() => {
    const getDashBoardData = async () => {
      const accessToken = localStorage.getItem("token");
      setIsLoading(true);
      try {
        const response = await axiosConfig(
          "get",
          `${config.apiUrl}/superadmin`,
          null,
          accessToken
        );
        setAllDashboardData(response?.data?.data);
      } catch (error) {
        console.log("Error getting all business", error);
      } finally {
        setIsLoading(false);
      }
    };
    getDashBoardData();
  }, []);

  console.log("allDashboardData", allDashboardData);

  const chartSections = {
    TotalUsers: [
      {
        label: "Age Chart",
        data: allDashboardData?.user?.userDetails[0]?.ageGroups || [],
      },
      {
        label: "Gender Chart",
        data: allDashboardData?.user?.userDetails[0]?.genders || [],
      },
      {
        label: "Race Chart",
        data: allDashboardData?.user?.userDetails[0]?.races || [],
      },
      {
        label: "State Chart",
        data: allDashboardData?.user?.userDetails[0]?.states || [],
      },
      {
        label: "City Chart",
        data: allDashboardData?.user?.userDetails[0]?.cities || [],
      },
    ],
    TotalUsersRevenue: [
      {
        label: "Age Chart",
        data: allDashboardData?.revenue?.revenueDetails[0]?.ageGroups || [],
      },
      {
        label: "Gender Chart",
        data: allDashboardData?.revenue?.revenueDetails[0]?.genders || [],
      },
      {
        label: "Race Chart",
        data: allDashboardData?.revenue?.revenueDetails[0]?.races || [],
      },
      {
        label: "State Chart",
        data: allDashboardData?.revenue?.revenueDetails[0]?.states || [],
      },
      {
        label: "City Chart",
        data: allDashboardData?.revenue?.revenueDetails[0]?.cities || [],
      },
    ],
    TotalBusinesses: [
      {
        label: "State Chart",
        data: allDashboardData?.businessAdmin?.businessDetails[0]?.states || [],
      },
      {
        label: "City Chart",
        data: allDashboardData?.businessAdmin?.businessDetails[0]?.cities || [],
      },
    ],
    TotalBusinessesRevenue: [
      {
        label: "State Chart",
        data:
          allDashboardData?.businessRevenue?.businessRevenueDetails[0]
            ?.states || [],
      },
      {
        label: "City Chart",
        data:
          allDashboardData?.businessRevenue?.businessRevenueDetails[0]
            ?.cities || [],
      },
    ],
  };

  const CardItem = ({ title, name, subheader, value, onClick }) => (
    <Grid
      item
      lg={3}
      md={6}
      xs={12}
      className="box"
      style={{ display: "grid", padding: "12PX" }}
    >
      {isLoading ? (
        <ChartSkeleton />
      ) : (
        <Card
          onClick={onClick}
          style={{
            position: "relative",
            border: chartName === name ? "1px solid black" : "none",
          }}
        >
          <CardHeader title={title} subheader={subheader} />
          <CardContent className="sa_dashboard_card_content">
            <Typography
              variant="h2"
              color="textSecondary"
              component="h2"
              className="text-center"
            >
              {value}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Grid>
  );

  console.log("chartName", chartName);

  const ChartSection = ({ title, charts }) => (
    <Box
      sx={{ margin: "12px 0px" }}
      style={{
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardHeader title={title} />
      <Grid container spacing={0}>
        {isAllDataEmpty(charts) ? (
          <Typography variant="h6" style={{ padding: "16px" }}>
            No data found
          </Typography>
        ) : (
          charts.map(({ label, data }, index) => {
            const { series, options } = extractChartData(data);
            return data?.length ? (
              <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                <CardHeader subheader={label} />
                <CardContent>
                  <Chart options={options} series={series} type="pie" />
                </CardContent>
              </Grid>
            ) : null;
          })
        )}
      </Grid>
    </Box>
  );

  return (
    <div>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Dashboard</Typography>
          </Breadcrumbs>
        </div>
      </div>

      <Grid container className="my-3">
        <CardItem
          title="Total Users"
          name="TotalUsers"
          subheader="users"
          value={allDashboardData?.user?.totalUser}
          onClick={() => setChartName("TotalUsers")}
        />
        <CardItem
          title="Total User Revenue"
          name="TotalUsersRevenue"
          subheader="Revenue"
          value={allDashboardData?.revenue?.totalRevenue?.toFixed(2)}
          onClick={() => setChartName("TotalUsersRevenue")}
        />
        <CardItem
          title="Business Owner List"
          name="BusinessOwners"
          subheader="Business Owners"
          value={allDashboardData?.totalBusinessOwner}
        />
        <CardItem
          title="Business Admin List"
          name="TotalBusinesses"
          subheader="Business Admins"
          value={allDashboardData?.businessAdmin?.totalBusinessAdmin}
          onClick={() => setChartName("TotalBusinesses")}
        />
        <CardItem
          title="Total Businesses Revenue"
          name="TotalBusinessesRevenue"
          subheader="Business revenue"
          value={allDashboardData?.businessRevenue?.totalBusinessRevenue}
          onClick={() => setChartName("TotalBusinessesRevenue")}
        />
      </Grid>

      {chartSections[chartName] && (
        <ChartSection
          title={chartName.replace(/([A-Z])/g, " $1").trim()}
          charts={chartSections[chartName]}
        />
      )}
    </div>
  );
};

export default Dashboard;
