import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import SearchIcon from '@material-ui/icons/Search';
import moment from "moment";
// import TablePagination from "@material-ui/core/TablePagination";
import { EyeOutlined, LoadingOutlined, RedoOutlined } from "@ant-design/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Spin } from "antd";
import axios from "axios";
import config from "../../config";
import { setCurrentCampaign } from "../../store/actions/index";
import { axiosConfig } from "../../utils/utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  // root: {
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    // marginLeft: '5px'
    // border: 'none',
  },
  button: {
    marginLeft: "10px",
  },
  view_td: {
    [theme.breakpoints.down("lg")]: {
      width: "380px",
    },
    display: "flex",
  },
}));

const useStylesChat = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1),
    maxHeight: 500,
    overflowY: "auto",
  },
  message: {
    marginBottom: theme.spacing(1),
  },
  left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  right: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  agentAvatar: {
    marginRight: theme.spacing(1),
    fontSize: "12px !important",
    height: "32px !important",
    width: "32px !important",
  },
  userAvatar: {
    marginLeft: theme.spacing(1),
    fontSize: "12px !important",
    height: "32px !important",
    width: "32px !important",
  },
  agentName: {
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
    borderRadius: "16px",
    border: "0px solid #FFFFFF !important",
  },
  sendIcon: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const PromotionTable = ({
  headings,
  data,
  period,
  setPage,
  enable_disable_promotion,
}) => {
  const [page, setPage1] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [promotionCommentsData, setPromotionCommentsData] = useState([]);
  const [commentsId, setCommentsId] = useState();
  const [replayMessageData, setReplayMessageData] = useState();
  const [isChatLoading, setIsChatLoading] = useState(false);

  const classesChat = useStylesChat();
  const classes = useStyles();

  const dispatch = useDispatch();

  const currentLoginUserData = JSON.parse(localStorage.getItem("client"));

  const setPromotion = (campaign) => {
    localStorage.setItem("campaignid", campaign._id);
    dispatch(setCurrentCampaign(campaign));
    setPage("promotion-details");
  };

  const handleChangePage = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage1(0);
  };

  const DateDiff = (date_string) => {
    var new_d = date_string.replace(/-/g, "/");
    var new_obj = new_d.split(" ");
    var obj = new Date(new_obj[0]);
    var formatted_Date =
      obj.getMonth() + 1 + "/" + obj.getDate() + "/" + obj.getFullYear();
    var formatted_time = new_obj[1].split(".")[0];
    formatted_Date = formatted_Date + " " + formatted_time;
    var d1 = new Date(formatted_Date);
    var d2 = new Date(); // now
    var diff = d2 - d1,
      sign = diff < 0 ? -1 : 1,
      milliseconds,
      seconds,
      minutes,
      hours,
      days;
    diff /= sign; // or diff=Math.abs(diff);
    diff = (diff - (milliseconds = diff % 1000)) / 1000;
    diff = (diff - (seconds = diff % 60)) / 60;
    diff = (diff - (minutes = diff % 60)) / 60;
    days = (diff - (hours = diff % 24)) / 24;

    if (sign === 1) {
      if (days > 0) {
        return days + " days " + hours + " hours " + minutes + " minutes ago";
      } else {
        return hours + " hours " + minutes + " minutes ago";
      }
    } else {
      if (days > 0) {
        return (
          "in " + days + " days " + hours + " hours " + minutes + " minutes "
        );
      } else {
        return "in " + hours + " hours " + minutes + " minutes ";
      }
    }
  };

  // Open Popover commits button
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickChatPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseChatPopover = () => {
    setAnchorEl(null);
    // setPromotionCommentsData([])
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Commits chat logic hear and api integration
  const chatRef = useRef(null);

  const [newMessage, setNewMessage] = useState("");

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendMessage = async () => {
    const accessToken = localStorage.getItem("token");
    if (newMessage.trim() === "") return;

    const payload = {
      promotion_id: commentsId,
      user_id: currentLoginUserData._id,
      comment: newMessage,
      parent_id: replayMessageData ? replayMessageData._id : undefined,
    };

    setIsChatLoading(true);
    try {
      await axiosConfig(
        "post",
        `${config.apiUrl}/comments`,
        payload,
        accessToken
      );
      // setPromotionCommentsData([...promotionCommentsData, response.data.data]);
      promotionCommentsGet();
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setNewMessage("");
      setReplayMessageData();
      setIsChatLoading(false);
    }
  };

  // Scroll to bottom when new message is added
  useEffect(() => {
    // Scroll to the bottom of the chat after a short delay to ensure DOM rendering
    const scrollTimeout = setTimeout(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    }, 100);

    return () => clearTimeout(scrollTimeout); // Clear the timeout to avoid memory leaks
  }, [promotionCommentsData]);

  const promotionCommentsGet = async (Id, recall) => {
    const accessToken = localStorage.getItem("token");
    setIsChatLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/comments?promotion_id=${Id || commentsId}`,
        null,
        accessToken
      );
      setPromotionCommentsData(response?.data?.data);
      recall === "delete" ? null : readPromotionComments(Id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsChatLoading(false);
    }
  };

  const readPromotionComments = async (Id) => {
    const accessToken = localStorage.getItem("token");
    setIsChatLoading(true);
    try {
      await axiosConfig(
        "get",
        `${config.apiUrl}/comments/read-all?promotion_id=${Id || commentsId}`,
        null,
        accessToken
      );
      // setPromotionCommentsData(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsChatLoading(false);
    }
  };

  // Promotion comments Delete API intigration
  const deletePromotionComments = async (Id) => {
    setIsChatLoading(true);
    await axios
      .delete(`${config.apiUrl}/comments?comment_id=${Id}`)
      .then((res) => {
        promotionCommentsGet(commentsId, "delete");
      })
      .catch((err) => {
        console.error("comment delete error: ", err);
      })
      .finally(() => {
        setIsChatLoading(false);
      });
  };

  // Message Hover Time UI show
  const [hoveredMessage, setHoveredMessage] = useState(null);

  const handleMouseEnter = (messageId) => {
    setHoveredMessage(messageId);
  };

  const handleMouseLeave = () => {
    setHoveredMessage(null);
  };

  return (
    <>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headings.map((head, index) => (
                <StyledTableCell key={index} align="left">
                  {head}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((campaign) => {
              const date =
                period === "future"
                  ? campaign.campaign_start_date
                  : campaign.campaign_expiration_date;
              return (
                <StyledTableRow hover key={campaign._id}>
                  <StyledTableCell align="left">
                    {campaign.campaign_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {" "}
                    {campaign && campaign.enabled ? "Yes" : "No"}{" "}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {moment(date).format("MMM Do YYYY")}{" "}
                    <small> - ({DateDiff(date)}) </small>
                  </StyledTableCell>
                  <StyledTableCell align="left" className={classes.view_td}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setPromotion(campaign)}
                      className={classes.button}
                      // startIcon={<SearchIcon />}
                      startIcon={<EyeOutlined />}
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="Secondary"
                      onClick={() => {
                        enable_disable_promotion(
                          campaign._id,
                          campaign.enabled
                        );
                      }} //this is where it needs to be passed
                      className={classes.button}
                      startIcon={<RedoOutlined />}
                    >
                      {campaign && campaign.enabled ? "Disable" : "Enable"}
                    </Button>
                    <Box
                      sx={{ position: "relative", width: "fit-content" }}
                      className={classes.button}
                    >
                      <Button
                        aria-describedby={id}
                        variant="contained"
                        onClick={(event) => {
                          handleClickChatPopOver(event);
                          setCommentsId(campaign._id);
                          promotionCommentsGet(campaign._id);
                        }}
                      >
                        Comments
                      </Button>
                      {campaign.comment_count !== 0 && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: -5,
                            right: -6,
                            color: "#FFFFFF",
                            background: "#000000",
                            height: "16px",
                            width: "16px",
                            borderRadius: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            {campaign.comment_count}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Mui Popover for comments chat */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseChatPopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "0 35px 60px -15px rgba(0,66,86,0.08)",
          },
        }}
      >
        <Box className={classes.root} ref={chatRef}>
          <Box
            component="ul"
            sx={{ height: "380px", overflow: "scroll", marginTop: "12px" }}
          >
            {isChatLoading ? (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0px 12px",
                  width: "350px",
                  minWidth: "350px",
                }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 32,
                      }}
                      spin
                    />
                  }
                />
              </Box>
            ) : (
              <Box>
                {promotionCommentsData?.length ? (
                  promotionCommentsData?.map((comment) => (
                    <React.Fragment key={comment.id}>
                      {/* Render parent comment */}
                      <Box
                        component="li"
                        className={classesChat.message}
                        display="flex"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        sx={{
                          padding: "0px 12px",
                          width: "350px",
                          minWidth: "350px",
                        }}
                        onMouseEnter={() => handleMouseEnter(comment.id)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <React.Fragment>
                          <Avatar
                            className={classesChat.agentAvatar}
                            sx={{ marginTop: "0px" }}
                          >
                            {comment.user?.first_name?.[0]?.toUpperCase() +
                              comment.user?.last_name?.[0]?.toUpperCase()}
                          </Avatar>
                          <Typography
                            component="span"
                            sx={{
                              width: "300px",
                              color: "#000000",
                              fontWeight: "600",
                            }}
                          >
                            {comment.comment}
                          </Typography>
                        </React.Fragment>

                        {hoveredMessage === comment.id && (
                          <>
                            <IconButton
                              sx={{ padding: "4px" }}
                              onClick={() =>
                                deletePromotionComments(comment.id)
                              }
                            >
                              <DeleteIcon
                                sx={{ height: "18px", width: "18px" }}
                              />
                            </IconButton>
                            <IconButton
                              sx={{ padding: "4px" }}
                              onClick={() => setReplayMessageData(comment)}
                            >
                              <ReplyIcon
                                sx={{ height: "18px", width: "18px" }}
                              />
                            </IconButton>
                          </>
                        )}
                      </Box>
                      {/* Render children comments */}
                      {comment.children &&
                        comment.children?.length > 0 &&
                        comment.children.map((childComment) => (
                          <Box
                            key={childComment.id}
                            component="li"
                            className={classesChat.message}
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            sx={{
                              padding: "0px 12px",
                              width: "350px",
                              minWidth: "350px",
                              marginLeft: "40px",
                            }}
                            onMouseEnter={() =>
                              handleMouseEnter(childComment.id)
                            }
                            onMouseLeave={handleMouseLeave}
                          >
                            <React.Fragment>
                              <Avatar
                                className={classesChat.agentAvatar}
                                sx={{ marginTop: "0px" }}
                              >
                                {childComment.user?.first_name?.[0]?.toUpperCase() +
                                  childComment.user?.last_name?.[0]?.toUpperCase()}
                              </Avatar>
                              <Typography
                                component="span"
                                sx={{ width: "300px", color: "#776b6b" }}
                              >
                                {childComment.comment}
                              </Typography>
                            </React.Fragment>

                            {hoveredMessage === childComment.id && (
                              <>
                                <IconButton
                                  sx={{ padding: "4px" }}
                                  onClick={() =>
                                    deletePromotionComments(childComment.id)
                                  }
                                >
                                  <DeleteIcon
                                    sx={{ height: "18px", width: "18px" }}
                                  />
                                </IconButton>
                              </>
                            )}
                          </Box>
                        ))}
                    </React.Fragment>
                  ))
                ) : (
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0px 12px",
                      width: "350px",
                      minWidth: "350px",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontWeight: "600",
                      }}
                    >
                      Currently there are no comments!
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {replayMessageData && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px 12px",
                margin: "0px 12px 12px",
                background: "#D3D3D3",
                borderRadius: "16px",
                height: "auto",
                width: "350px",
                minWidth: "350px",
              }}
            >
              <Typography>{replayMessageData.comment}</Typography>
              <IconButton
                sx={{ padding: "4px" }}
                onClick={() => setReplayMessageData()}
              >
                <DeleteIcon
                  sx={{ height: "18px", width: "18px", marginLeft: "16px" }}
                />
              </IconButton>
            </Box>
          )}

          {replayMessageData && (
            <TextField
              className={classesChat.textField}
              variant="outlined"
              placeholder="Type your message..."
              fullWidth
              value={newMessage}
              onChange={handleMessageChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSendMessage();
                }
              }}
              InputProps={{
                endAdornment: (
                  <SendIcon
                    className={classesChat.sendIcon}
                    onClick={handleSendMessage} // Optionally add onClick handler for the icon
                  />
                ),
                sx: { padding: "12px", border: "none !impotent" },
              }}
            />
          )}
        </Box>
      </Popover>
      {/* <TablePagination
    rowsPerPageOptions={[5, 25, 100]}
    component="div"
    count={data?.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onChangePage={handleChangePage}
    onChangeRowsPerPage={handleChangeRowsPerPage}
  /> */}
    </>
  );
};

export default PromotionTable;
