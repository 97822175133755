import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

const AppAutoLogin = () => {
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const decodedString = decodeURIComponent(search);

      const urlParams = new URLSearchParams(decodedString);

      //   console.log("decodedString", decodedString);
      //   console.log("urlParams", urlParams.get("client"));

      const clientObj = urlParams.get("client");
      const isAuth = urlParams.get("isAuth");
      const expiresAt = urlParams.get("expiresAt");
      const userToken = urlParams.get("token");

      if (clientObj && isAuth && expiresAt && userToken) {
        const parsedObject = JSON.parse(clientObj);
        // console.log("parsedObject", parsedObject);

        // Store values in localStorage
        localStorage.setItem("client", JSON.stringify(parsedObject));
        localStorage.setItem("isAuth", isAuth);
        localStorage.setItem("expiresAt", expiresAt);
        localStorage.setItem("token", userToken);

        console.log("Reached");

        // Redirect to a specified URL
        window.location.href = `${window?.location?.origin}/app`;
        console.log("passed");
      }

      //   window.location.href = "http://localhost:3001/app";
    })();
  }, [search, history]);

  return <div></div>;
};

export default AppAutoLogin;
