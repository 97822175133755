import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { axiosConfig } from "../../utils/utils";
import config from "../../config";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Followers = ({ setPage }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showFollowers, setShowFollowers] = useState();

  const LoginBAData = JSON.parse(localStorage.getItem("client"));

  const fetchFollowersList = () => {
    setIsLoading(true);
    const accessToken = localStorage.getItem("token");
    axiosConfig(
      "get",
      `${config.apiUrl}/businessadmin/get-follower-list/${LoginBAData._id}`,
      null,
      accessToken
    )
      .then((res) => {
        setShowFollowers(res?.data);
      })
      .catch((err) => {
        console.error("followers error: ", err);
      })
      .finally((res) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchFollowersList();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Followers</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <Paper className="mt-3">
        <TableContainer>
          <Table style={{ minWidth: "750px" }}>
            <TableHead>
              <TableRow>
                {/* {headings.map((head, index) => ( */}
                <StyledTableCell align="left">First Name</StyledTableCell>
                <StyledTableCell align="left">Last Name</StyledTableCell>
                <StyledTableCell align="left">Email</StyledTableCell>
                {/* ))} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {showFollowers?.follow?.length ? (
                showFollowers?.follow?.map((item, mainIndex) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={mainIndex}
                    >
                      <TableCell style={{ minWidth: "150px" }}>
                        {item?.user_id?.first_name ?? "-"}
                      </TableCell>
                      <TableCell style={{ minWidth: "150px" }}>
                        {item?.user_id?.last_name ?? "-"}
                      </TableCell>
                      <TableCell style={{ minWidth: "205px" }}>
                        {item?.user_id?.email ?? "-"}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : isLoading ? (
                <TableCell colSpan={11} className="text-center">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 32,
                        }}
                        spin
                      />
                    }
                  />
                </TableCell>
              ) : (
                <TableCell colSpan={10} className="text-center">
                  No data found!
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={pageCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </>
  );
};

export default Followers;
