import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentProduct } from "../../store/actions/index";
import config from "../../config";
import { axiosConfig } from "../../utils/utils";
import { debounce } from "lodash";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "category", label: "ISO\u00a0Code", minWidth: 101 },
  { id: "subcategory", label: "ISO\u00a0Code", minWidth: 102 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
];

const ProductTable = ({ headings, data, setPage }) => {
  const { category } = useSelector((state) => state.client);
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState("ASC");
  const [searchQuery, setSearchQuery] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [FilterData, setFilterData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const dispatch = useDispatch();

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      const client = JSON.parse(localStorage.getItem("client"));
      const accessToken = localStorage.getItem("token");

      setPageCount(0);

      await axiosConfig(
        "get",
        `${config.apiUrl}/products?page=1&count=${rowsPerPage}&search=${query}&client_id=${client._id}`,
        null,
        accessToken
      ).then((res) => {
        setLastSearchQuery(query);
        setProductsData(res?.data?.products);
        setTotal(res?.data?.total);
      });
    }, 1000),
    [rowsPerPage]
  );

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query !== lastSearchQuery) {
      debouncedSearch(query);
    }
  };

  const setCampaign = (campaign) => {
    localStorage.setItem("producrid", campaign._id);
    dispatch(setCurrentProduct(campaign));
    setPage("product-coupon-details");
  };

  const handleChangePage = async (event, newPage) => {
    const client = JSON.parse(localStorage.getItem("client"));
    const accessToken = localStorage.getItem("token");
    await axiosConfig(
      "get",
      `${config.apiUrl}/products?page=${newPage + 1}&count=10&category=${
        FilterData ? FilterData : ""
      }&search=${searchQuery}&client_id=${client._id}`,
      null,
      accessToken
    ).then((res) => {
      setPageCount(newPage);
      setProductsData(res?.data?.products);
      setTotal(res?.data?.total);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPageCount(0);
  };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      data = [sorted];
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...data].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      data = [sorted];
      setOrder("ASC");
    }
  };

  const SearchProduct = async () => {
    const client = JSON.parse(localStorage.getItem("client"));
    const accessToken = localStorage.getItem("token");

    await axiosConfig(
      "get",
      `${config.apiUrl}/products?page=${
        pageCount + 1
      }&count=${rowsPerPage}&search=${searchQuery}&client_id=${client._id}`,
      null,
      accessToken
    ).then((res) => {
      setProductsData(res?.data?.products);
      setTotal(res?.data?.total);
    });
  };

  useEffect(() => {
    SearchProduct();
  }, [pageCount, rowsPerPage]);

  const FilteredProduct = async (e) => {
    setFilterData(e);
    const client = JSON.parse(localStorage.getItem("client"));
    const accessToken = localStorage.getItem("token");
    setPageCount(0);
    await axiosConfig(
      "get",
      `${config.apiUrl}/products?page=1&count=10&category=${e}&search=${searchQuery}&client_id=${client._id}`,
      null,
      accessToken
    ).then((res) => {
      setProductsData(res?.data?.products);
      setTotal(res?.data?.total);
    });
  };

  const ResetProducts = async () => {
    const accessToken = localStorage.getItem("token");
    const client = JSON.parse(localStorage.getItem("client"));
    await axiosConfig(
      "get",
      `${config.apiUrl}/products?page=1&count=10&search&client_id=${client._id}`,
      null,
      accessToken
    ).then((res) => {
      setProductsData(res?.data?.products);
      setTotal(res?.data?.total);
    });
  };

  return (
    <>
      <div className="search mb-2">
        <div className="row">
          <div className="col-lg-4 col-12 products_select mb-lg-0 mb-3">
            <select
              className="mr-lg-2 mr-0 form-control products_select_box"
              style={{ height: "38px" }}
              onChange={(e) => FilteredProduct(e.currentTarget.value)}
            >
              <option disabled>Select Category..</option>
              <option value="">All</option>
              {category?.length !== 0 &&
                category.map((cat, catIndex) => (
                  <option value={cat.category} key={catIndex}>
                    {cat.category}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-lg-5 col-12 pr-lg-0 pr-auto">
            <div className="searchFrom products_search_bar w-100">
              <input
                type="text"
                value={searchQuery}
                style={{ height: "38px" }}
                onChange={handleSearchInputChange}
                id="kt_filter_search"
                className="form-control"
                placeholder="Search.."
              />
              {searchQuery ? (
                <SearchOutlined onClick={(e) => SearchProduct()} />
              ) : (
                <SearchOutlined />
              )}
            </div>
          </div>
          <div
            className="col-lg-3 col-12 reset_button pl-0"
            style={{ textAlign: "end" }}
          >
            <Button
              style={{ height: "38px" }}
              variant="contained"
              color="primary"
              onClick={(e) => ResetProducts()}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
      {productsData?.length === 0 ? (
        <TableContainer>
          <Table style={{ minWidth: "750px" }}>
            <TableHead>
              <TableRow>
                {headings?.map((head, index) => (
                  <StyledTableCell key={index} align="left">
                    {head}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
          <div className="text-center">No data found...</div>
        </TableContainer>
      ) : (
        <>
          <TableContainer>
            <Table style={{ minWidth: "750px" }}>
              <TableHead>
                <TableRow>
                  {headings?.map((head, index) => (
                    <StyledTableCell
                      onClick={() => sorting("name")}
                      key={index}
                      align="left"
                    >
                      {head}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {productsData.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value ?? "N/A"}
                          </TableCell>
                        );
                      })}
                      <StyledTableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setCampaign(row)}
                          // startIcon={<SearchIcon />}
                          startIcon={<EyeOutlined />}
                        >
                          View
                        </Button>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={pageCount}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </>
  );
};

export default ProductTable;
