import { LoadingOutlined } from "@ant-design/icons";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import config from "../../config";
import { extractChartData, isAllDataEmpty } from "../../utils/chartUtils";
import { axiosConfig, formatRevenue } from "../../utils/utils";

const RevenueBa = ({ setPage, SubscriptionPlanName }) => {
  const [chartName, setChartName] = useState("");
  const [loading, setLoading] = useState(false);
  const [getAllRevenueData, setGetAllRevenueData] = useState({});

  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const getRevenueData = async () => {
    const accessToken = localStorage.getItem("token");
    setLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessadmin/${LoginBOData._id}/get-revenue`,
        null,
        accessToken
      );
      setGetAllRevenueData(response?.data?.data);
      if (response?.data?.data?.isShowChart) setChartName("daily");
    } catch (error) {
      console.error("Error getting Total Revenue Data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRevenueData();
  }, []);

  const chartSections = {
    daily: [
      {
        label: "Age",
        data:
          getAllRevenueData?.demographics?.daily?.userDetails[0]?.ageGroups ||
          [],
      },
      {
        label: "Gender",
        data:
          getAllRevenueData?.demographics?.daily?.userDetails[0]?.genders || [],
      },
      {
        label: "Race",
        data:
          getAllRevenueData?.demographics?.daily?.userDetails[0]?.races || [],
      },
      {
        label: "State",
        data:
          getAllRevenueData?.demographics?.daily?.userDetails[0]?.states || [],
      },
      {
        label: "City",
        data:
          getAllRevenueData?.demographics?.daily?.userDetails[0]?.cities || [],
      },
    ],
    weekly: [
      {
        label: "Age",
        data:
          getAllRevenueData?.demographics?.weekly?.userDetails[0]?.ageGroups ||
          [],
      },
      {
        label: "Gender",
        data:
          getAllRevenueData?.demographics?.weekly?.userDetails[0]?.genders ||
          [],
      },
      {
        label: "Race",
        data:
          getAllRevenueData?.demographics?.weekly?.userDetails[0]?.races || [],
      },
      {
        label: "State",
        data:
          getAllRevenueData?.demographics?.weekly?.userDetails[0]?.states || [],
      },
      {
        label: "City",
        data:
          getAllRevenueData?.demographics?.weekly?.userDetails[0]?.cities || [],
      },
    ],
    monthly: [
      {
        label: "Age",
        data:
          getAllRevenueData?.demographics?.monthly?.userDetails[0]?.ageGroups ||
          [],
      },
      {
        label: "Gender",
        data:
          getAllRevenueData?.demographics?.monthly?.userDetails[0]?.genders ||
          [],
      },
      {
        label: "Race",
        data:
          getAllRevenueData?.demographics?.monthly?.userDetails[0]?.races || [],
      },
      {
        label: "State",
        data:
          getAllRevenueData?.demographics?.monthly?.userDetails[0]?.states ||
          [],
      },
      {
        label: "City",
        data:
          getAllRevenueData?.demographics?.monthly?.userDetails[0]?.cities ||
          [],
      },
    ],
  };

  const ChartSection = ({ title, charts }) => (
    <Box
      sx={{ margin: "12px 0px" }}
      style={{
        background: "#FFFFFF",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      }}
    >
      <CardHeader title={title} />
      <Grid container spacing={0}>
        {isAllDataEmpty(charts) ? (
          <Typography variant="h6" style={{ padding: "16px" }}>
            No data found
          </Typography>
        ) : (
          charts.map(({ label, data }, index) => {
            const { series, options } = extractChartData(data);
            return data?.length ? (
              <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
                <CardHeader subheader={`${label} Chart`} />
                <CardContent>
                  <Chart options={options} series={series} type="pie" />
                </CardContent>
              </Grid>
            ) : null;
          })
        )}
      </Grid>
    </Box>
  );

  const renderCard = (title, name, value) => (
    <Grid item md={4} xs={12} style={{ padding: "12px" }}>
      {loading ? (
        <ChartSkeleton />
      ) : (
        <Card
          onClick={() => {
            if (getAllRevenueData?.isShowChart) setChartName(name);
          }}
          style={{ border: chartName === name ? "1px solid black" : "none" }}
        >
          <CardHeader title={title} subheader={name} />
          <CardContent>
            <Typography
              variant="h2"
              color="textSecondary"
              className="text-center"
            >
              ${formatRevenue(Number(value) || 0, 2)}
            </Typography>
          </CardContent>
        </Card>
      )}
    </Grid>
  );

  return (
    <div>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("ListOfBusiness")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Total Revenue</Typography>
          </Breadcrumbs>
        </div>
      </div>
      {loading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 12px",
            width: "100%",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 32,
                }}
                spin
              />
            }
          />
        </Box>
      ) : (
        <Grid container spacing={2} className="my-3">
          {renderCard("Daily", "daily", getAllRevenueData?.daily)}
          {renderCard("Weekly", "weekly", getAllRevenueData?.weekly)}
          {renderCard("Monthly", "monthly", getAllRevenueData?.monthly)}
        </Grid>
      )}

      {/*BA buy SubscriptionPlan = Premium so access this chart  */}

      {SubscriptionPlanName.planName === "Premium" &&
      getAllRevenueData?.isShowChart
        ? !loading && (
            <ChartSection
              title={chartName.charAt(0).toUpperCase() + chartName.slice(1)}
              charts={chartSections[chartName]}
            />
          )
        : null}
    </div>
  );
};

export default RevenueBa;
