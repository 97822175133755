import config from "../config";
import { axiosConfig } from "../utils/utils";

const accessToken = localStorage.getItem("token");

const login = async (credentials) => {
  return await fetch(`${config.apiUrl}/clientlogin`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  });
};

const register = async (business) => {
  return await fetch(`${config.apiUrl}/clientregister`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(business),
  });
};

const forgotPassword = async (email) => {
  return await fetch(`${config.apiUrl}/clientforgotpassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  });
};

const verifyResetPassword = async (verify) => {
  return await fetch(`${config.apiUrl}/verifyresetpassword`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(verify),
  });
};

// const login = async (credentials) => {
//   return await axiosConfig(
//     "put",
//     `${config.apiUrl}/clientlogin`,
//     credentials,
//     accessToken
//   );
// };

// const register = async (business) => {
//   return await axiosConfig(
//     "post",
//     `${config.apiUrl}/clientregister`,
//     business,
//     accessToken
//   );
// };

// const forgotPassword = async (email) => {
//   return await axiosConfig(
//     "post",
//     `${config.apiUrl}/clientforgotpassword`,
//     email,
//     accessToken
//   );
// };

// const verifyResetPassword = async (verify) => {
//   return await axiosConfig(
//     "post",
//     `${config.apiUrl}/verifyresetpassword`,
//     verify,
//     accessToken
//   );
// };

const updateProfile = async (businessId, business) => {
  return await axiosConfig(
    "patch",
    `${config.apiUrl}/clients/${businessId}`,
    business,
    accessToken
  );
};

const getDashboardAnalytics = async (businessId) => {
  return await axiosConfig(
    "get",
    `${config.apiUrl}/clients/${businessId}/dashboard`,
    null,
    accessToken
  );
};

const getCategory = async () => {
  return await axiosConfig(
    "get",
    `${config.apiUrl}/category/get-category`,
    null,
    accessToken
  );
};

export default {
  forgotPassword,
  verifyResetPassword,
  getDashboardAnalytics,
  login,
  register,
  updateProfile,
  getCategory,
};
