export const generateGray = () => {
  const grayValue = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  return `#${grayValue}${grayValue}${grayValue}`;
};

export const chartOptions = (group) => ({
  chart: { type: "pie" },
  labels: group.map((i) => i?._id || ""),
  colors: Array(5).fill().map(generateGray),
  legend: { show: false, floating: true },
});

export const extractChartData = (dataGroup) => {
  const series = dataGroup.map((item) => item?.count || 0);
  const options = chartOptions(dataGroup);
  return { series, options };
};

export const isAllDataEmpty = (charts) =>
  charts.every((chart) => chart.data?.length === 0);
