import { LoadingOutlined } from "@ant-design/icons";
import { Box, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { axiosConfig } from "../../../utils/utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    // border: 'none',
  },
});

const BusinessTable = ({ headings }) => {
  const [businessData, setBusinessData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState("ASC");

  const classes = useStyles();

  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const getInvitedBusinessesData = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      // const response = await axios.get(`${config.apiUrl}/businessowner/663350325f306ce9bf84a209/business`);
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner/${LoginBOData._id}/business`,
        null,
        accessToken
      );
      setBusinessData(response.data.data);
    } catch (error) {
      console.log("fetch invited business data error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInvitedBusinessesData();
  }, []);

  const userActiveInactive = async (UserID) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      // const response = await axios.patch(`${config.apiUrl}/businessowner/663350325f306ce9bf84a209/business/${UserID}`);
      await axiosConfig(
        "patch",
        `${config.apiUrl}/businessowner/${LoginBOData._id}/business/${UserID}`,
        null,
        accessToken
      );
      getInvitedBusinessesData();
    } catch (error) {
      console.log("user active/inactive error: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {businessData && businessData.allInvitedBusinesses?.length === 0 && (
        <TableContainer>
          <Table style={{ minWidth: "750px" }}>
            <TableHead>
              <TableRow>
                {headings.map((head, index) => (
                  <StyledTableCell key={index} align="left">
                    {head}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
          <div className="text-center">No data found...</div>
        </TableContainer>
      )}
      {isLoading && (
        <Box
          sx={{
            height: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 12px",
            width: "100%",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 32,
                }}
                spin
              />
            }
          />
        </Box>
      )}
      {!isLoading &&
        businessData &&
        businessData.allInvitedBusinesses?.length !== 0 && (
          <TableContainer>
            <Table style={{ minWidth: "750px" }}>
              <TableHead>
                <TableRow>
                  {headings.map((head, index) => (
                    <StyledTableCell key={index} align="left">
                      {head}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {businessData &&
                  businessData.allInvitedBusinesses.map((Item, I) => {
                    const clientLogoUrl = `https://xclusit-dev.s3.amazonaws.com${Item.client_logo}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={I}>
                        <TableCell style={{ minWidth: "60px" }}>
                          <img
                            src={clientLogoUrl}
                            alt="Client Logo"
                            style={{ height: "50px", width: "50px" }}
                          />
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.client_name}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.first_name}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.last_name}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.email}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.address}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.city}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.phone}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.state}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          <a
                            href={Item.website}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {Item.website}
                          </a>
                        </TableCell>
                        <StyledTableCell style={{ minWidth: "150px" }}>
                          <Button
                            variant="contained"
                            color={Item.active ? "Secondary" : "primary"}
                            onClick={() => userActiveInactive(Item._id)}
                          >
                            {Item.active ? "DEACTIVATE" : "ACTIVATE"}
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell style={{ minWidth: "150px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              background: Item.isVerified
                                ? "#DCF4EF"
                                : "#F4DACD",
                              width: "fit-content",
                              padding: "4px 8px",
                              borderRadius: "30px",
                              gap: "4px",
                              color: Item.isVerified ? "#00805D" : "#FF7100",
                              fontWeight: "600",
                            }}
                          >
                            {Item.isVerified ? (
                              <VerifiedIcon
                                sx={{ height: "18px", width: "18px" }}
                              />
                            ) : (
                              <HourglassTopIcon
                                sx={{ height: "18px", width: "18px" }}
                              />
                            )}
                            <Typography
                              variant="body1"
                              style={{ fontSize: "12px" }}
                            >
                              {Item.isVerified ? "VERIFIED" : "PENDING"}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
    </>
  );
};

export default BusinessTable;
