import axios from "axios";
import config from "../config";
import { axiosConfig } from "../utils/utils";

const createCoupon = async (coupon) => {
  const accessToken = localStorage.getItem("token");
  return await axiosConfig(
    "post",
    `${config.apiUrl}/coupons`,
    coupon,
    accessToken
  );
};

const retrieveCoupon = async (coupon) => {
  const accessToken = localStorage.getItem("token");
  return await axiosConfig(
    "get",
    `${config.apiUrl}/coupons/${coupon}`,
    null,
    accessToken
  );
};

const updateCoupon = async (couponId, coupon) => {
  const accessToken = localStorage.getItem("token");
  return await axiosConfig(
    "patch",
    `${config.apiUrl}/coupons/${couponId}`,
    coupon,
    accessToken
  );
};

const updateProduct = async (id, editCoupon) => {
  const accessToken = localStorage.getItem("token");
  return await axiosConfig(
    "put",
    `${config.apiUrl}/product?id=${id}`,
    editCoupon,
    accessToken
  );
};

const deleteCoupon = async (coupon) => {
  const accessToken = localStorage.getItem("token");
  return await axiosConfig(
    "delete",
    `${config.apiUrl}/coupons/${coupon}`,
    null,
    accessToken
  );
};

const deleteProduct = async (id) => {
  const accessToken = localStorage.getItem("token");
  return await axiosConfig(
    "delete",
    `${config.apiUrl}/product?id=${id}`,
    null,
    accessToken
  );
};

export default {
  createCoupon,
  retrieveCoupon,
  updateCoupon,
  updateProduct,
  deleteCoupon,
  deleteProduct,
};
