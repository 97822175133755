import React, { useEffect, useState } from "react";
import RequestsTable from "./RequestsTable";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import axios from "axios";
import config from "../../config";
import HomeIcon from "@material-ui/icons/Home";

const Requests = (props) => {
  const { setPage } = props;
  const [requestsList, setRequestsList] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const getRequestsList = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${config.apiUrl}/businessadmin/${
          LoginBOData._id
        }/get-request-list?page=${pageCount + 1}&limit=${rowsPerPage}`
      )
      .then((res) => {
        setIsLoading(false);
        setRequestsList(res?.data?.data);
      })
      .catch((err) => {
        console.error("orders list get error: ", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getRequestsList();
  }, [pageCount, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPageCount(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(0);
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Requests</Typography>
          </Breadcrumbs>
        </div>
      </div>

      <RequestsTable
        headings={[
          "PRODUCT-IMAGE",
          "PRODUCT-NAME",
          "PRODUCT-DESCRIPTION",
          "PRICE",
          "CATEGORY",
          "SUB-CATEGORY",
          "CODE",
          "REQUEST-DATE",
          "VARIANTS",
          "STATUS",
        ]}
        data={requestsList?.orderList}
        total={requestsList?.total}
        isLoading={isLoading}
        pageCount={pageCount}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        getRequestsList={getRequestsList}
        {...props}
      />
    </div>
  );
};

export default Requests;
