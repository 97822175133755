import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const PayoutHistoryTable = ({ headings, data, historyLoading }) => {
  return (
    <Paper className="mt-3">
      <TableContainer>
        <Table style={{ minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              {headings.map((head, index) => (
                <StyledTableCell key={index} align="left">
                  {head}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {historyLoading ? (
              <TableCell colSpan={11} className="text-center">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 32,
                      }}
                      spin
                    />
                  }
                />
              </TableCell>
            ) : data?.payouts?.length ? (
              data?.payouts?.map((item, mainIndex) => (
                <TableRow hover tabIndex={-1} key={mainIndex}>
                  <TableCell style={{ minWidth: "150px" }}>
                    ${item?.amount}
                  </TableCell>
                  <TableCell style={{ minWidth: "150px" }}>
                    {moment.unix(item?.arrival_date).format("DD, MMMM, YYYY")}
                  </TableCell>
                  <TableCell style={{ minWidth: "90px" }}>
                    {item?.method}
                  </TableCell>
                  {/* <TableCell style={{ minWidth: "130px" }}>
                    {item?.source_type}
                  </TableCell> */}
                  <TableCell style={{ minWidth: "130px" }}>
                    {item?.status}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={5} className="text-center">
                No data found!
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={pageCount}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
};

export default PayoutHistoryTable;
