// /* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import PrintIcon from "@material-ui/icons/Print";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { Spin } from "antd";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { axiosConfig } from "../../utils/utils";

const Invoice = ({ setPage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceAllData, setInvoiceAllData] = useState([]);

  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const getInvoiceData = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessadmin/${LoginBOData._id}/get-invoice`,
        null,
        accessToken
      );
      setInvoiceAllData(response?.data?.data);
    } catch (error) {
      console.log("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInvoiceData();
  }, []);

  const removePdfQuery = (url) => {
    const searchString = "/pdf?s=ap";
    if (url.includes(searchString)) {
      return url.replace(searchString, "");
    }
    return url;
  };

  return (
    <div className={window.innerWidth >= 992 ? "container" : ""}>
      <div className="row">
        <div className="col pl-md-0 pl-auto pr-md-0 pr-auto">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="primary">Invoices</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col pl-md-0 pl-auto pr-md-0 pr-auto">
          <Card>
            <CardContent>
              <div className="mt-3">
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Box sx={{ fontWeight: "bold" }}>Invoice Date</Box>
                        </TableCell>
                        <TableCell>
                          <Box sx={{ fontWeight: "bold" }}>Plan Name</Box>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ fontWeight: "bold" }}>Invoice Id</Box>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ fontWeight: "bold" }}>Amount</Box>
                        </TableCell>
                        <TableCell align="left">
                          <Box sx={{ fontWeight: "bold" }}>Status</Box>
                        </TableCell>
                        <TableCell align="right">
                          <Box sx={{ fontWeight: "bold" }}>Invoice</Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Box
                              sx={{
                                height: "200px",
                                background: "#FFFFFF",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <Spin
                                indicator={
                                  <LoadingOutlined
                                    style={{
                                      fontSize: 32,
                                    }}
                                    spin
                                  />
                                }
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : invoiceAllData?.length ? (
                        invoiceAllData.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.invoiceDate}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.planName}
                            </TableCell>
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell align="left">
                              <Box
                                sx={{
                                  color: "#00805D",
                                  fontWeight: "bold",
                                }}
                              >
                                ${row.amount}
                              </Box>
                            </TableCell>
                            <TableCell align="left">{row.status}</TableCell>
                            <TableCell align="right">
                              <div
                                onClick={() =>
                                  window.open(
                                    removePdfQuery(row.pdf_link),
                                    "_blank"
                                  )
                                }
                              >
                                <Tooltip title="Print Invoice">
                                  <IconButton aria-label="Print Invoice">
                                    <PrintIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableCell colSpan={10} className="text-center">
                          No data found!
                        </TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
