import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import logo from '../../../assets/images/CoupifyLogo-2.png';
import ListOfBusinessAdmin from './ListOfBusinessAdmin';
import ListOfBusinessOwner from './ListOfBusinessOwner';
import SidebarMenu from './SidebarMenu';
// import RevenueBo from './RevenueBo';
import Dashboard from './Dashboard';
import Plans from './Plans';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: 1040,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: 1040,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    coupifyLogo: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 3),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

const SaDashboard = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const [page, setPage] = useState("Dashboard");

    useEffect(() => {
        if (window.innerWidth < 767.9) {
            setOpen(false);
        }
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const PageLoadBO = () => {
        switch (page) {
            case "ListOfBusinessOwner":
                return <ListOfBusinessOwner {...props} page={page} setPage={setPage} />;
            case "ListOfBusinessAdmin":
                return <ListOfBusinessAdmin {...props} page={page} setPage={setPage} />;
            case "Plans":
                return <Plans {...props} page={page} setPage={setPage} />;
            // return <ListOfBusinessAdmin {...props} page={page} setPage={setPage} />;
            // case "Revenue":
            //     return <RevenueBo {...props} page={page} setPage={setPage} />;

            default:
                return <Dashboard {...props} page={page} setPage={setPage} />
        }
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                color="white"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className="headerStyle">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className='p-0'>
                        {/* {client.client_name} */}
                        SUPER ADMIN
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className="row" style={{ width: '100%' }}>
                    <div className={classes.coupifyLogo + " col"}>
                        <img src={logo} alt="" width="100" className="cooppyLogo" />
                    </div>
                    <div className={classes.toolbar + " col-1"} style={{ padding: '0' }}>
                        <IconButton onClick={handleDrawerClose} style={{ padding: '0' }}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                </div>
                <Divider />
                <SidebarMenu {...props} page={page} setPage={setPage} />
            </Drawer>
            <main className={classes.content + " mainContent"}>
                <div className={classes.toolbar} />
                {PageLoadBO()}
            </main>
        </div>
    );
}

export default SaDashboard;