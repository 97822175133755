import config from "../config";

const token = localStorage.getItem("token");

const SaLogin = async (credentials) => {
  return await fetch(`${config.apiUrl}/adminlogin`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(credentials),
  });
};

export default {
  SaLogin,
};
