import {
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Col, Form, Modal, Spinner } from "react-bootstrap";
import { returnDaysOptionList, validatePrice } from "../../utils/utils";
import { List, Upload } from "antd";
import { Delete as DeleteIcon } from "@mui/icons-material";
import Select from "react-select";

const ProductAddUpdateModal = (props) => {
  const {
    showCreate,
    validated,
    onCloseModal,
    handleCreate,
    campaignForm,
    setCampaignForm,
    handlePurchaseOptionsChange,
    subCategories,
    propsUploadFile,
    handlePreview,
    errorMessage,
    setErrorMessage,
    fileList,
    handleRemoveFile,
    loading,
    isMode,
    allVariantsList,
    handleVariantChange,
    handleAddVariant,
    handleRemoveVariant,
    allCategories,
    handleCategoryChange,
    handleXclusitStoreChange,
    taxCategories,
    handleProductFieldChange,
    handleAddNewMainFoodCategory,
    allNewMainCategoriesList,
    handleRemoveMainCategory,
    handleRemoveAddonOption,
    handleAddNewAddonOptions,
    handleAddonCategoryChange,
    handleAddonOptionChange,
    addonCatErrors,
  } = props;

  console.log("campaignForm====>", campaignForm);

  const couponImage = require("../../assets/images/logo-placeholder.png");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      minHeight: 48,
    }),
  };

  const variantRow = (mainIndex, index, innerIndex, item, isPaid) => {
    const isRequired = isPaid && item.property === "Price";
    const isNameRequired = item.property === "Name";
    return (
      <Col key={`${index}-${innerIndex}`}>
        {item?.propertyType === "Radio" ? (
          <Form.Group controlId={`variant-${index}-${innerIndex}`}>
            <Form.Label>{item.property}</Form.Label>
            <Form.Check
              inline
              label={item?.value ? "Yes" : "No"}
              name={`${item.property}-${index}-${innerIndex}`}
              style={{ height: 48 }}
              className="return-radios"
              checked={item.value}
              onChange={(e) => handleVariantChange(e, index, innerIndex)}
              type="checkbox"
              id="return"
              required={
                !!campaignForm?.purchase_options.find(
                  (i) => i === "xclusit_store"
                )
              }
            />
            <Form.Control.Feedback type="invalid">
              Please add product {item.property}!
            </Form.Control.Feedback>
          </Form.Group>
        ) : (
          <>
            {(isRequired || isNameRequired || item.property !== "Price") && (
              <Form.Group controlId={`variant-${index}-${innerIndex}`}>
                <Form.Label>{item.property}</Form.Label>
                <Form.Control
                  type={
                    item.property !== "quantity"
                      ? item?.propertyType === "number"
                        ? "number"
                        : "text"
                      : "number"
                  }
                  name={item.property}
                  style={{ height: 48 }}
                  // className={`${
                  //   validated && !validatePrice.test(item.value)
                  //     ? "border-danger"
                  //     : ""
                  // }`}
                  value={item.value}
                  onChange={(e) => {
                    if (campaignForm?.category === "Food and Drink") {
                      handleAddonOptionChange(
                        mainIndex,
                        index,
                        innerIndex,
                        "value",
                        e.target.value
                      );
                    } else {
                      handleVariantChange(e, index, innerIndex);
                    }
                  }}
                  min={campaignForm?.category === "Food and Drink" ? "0" : "1"}
                  step={item.property === "Price" ? "any" : undefined}
                  placeholder={item.property}
                  required={
                    isNameRequired || // Always required for "Name"
                    (campaignForm?.category === "Food and Drink"
                      ? isRequired
                      : !!campaignForm?.purchase_options.find(
                          (i) => i === "xclusit_store"
                        ))
                  }
                  // isInvalid={validated && !validatePrice.test(item.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please add product {item.property}!
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </>
        )}
      </Col>
    );
  };

  return (
    <div>
      <Modal centered show={showCreate} onHide={() => onCloseModal()} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {isMode === "add" ? "CREATE A PRODUCT" : ""}
          </Modal.Title>
        </Modal.Header>
        <div>
          <Form noValidate validated={validated} onSubmit={handleCreate}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <Form.Group>
                    <Form.Label>Product Name</Form.Label>
                    <Form.Control
                      style={{ height: "48px" }}
                      placeholder="Product Name"
                      type="text"
                      name="name"
                      value={campaignForm?.name || ""}
                      // onChange={(event) =>
                      //   setCampaignForm({
                      //     ...campaignForm,
                      //     name: event.target.value,
                      //   })
                      // }
                      onChange={handleProductFieldChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the product name!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>Product Description</Form.Label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="description"
                      value={campaignForm.description || ""}
                      // onChange={(event) =>
                      //   setCampaignForm({
                      //     ...campaignForm,
                      //     description: event.target.value,
                      //   })
                      // }
                      onChange={handleProductFieldChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the product description!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 col-12">
                  <Form.Group>
                    <Form.Label>Product Code</Form.Label>
                    <Form.Control
                      style={{ height: "48px" }}
                      placeholder="Product Code"
                      type="text"
                      name="code"
                      value={campaignForm.code || ""}
                      // onChange={(event) =>
                      //   setCampaignForm({
                      //     ...campaignForm,
                      //     code: event.target.value,
                      //   })
                      // }
                      onChange={handleProductFieldChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the product code!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-lg-5 col-12 mt-3 mt-lg-0">
                  <Form.Group>
                    <Form.Label>Product Price</Form.Label>
                    <Form.Control
                      type="text"
                      style={{ height: 48 }}
                      className={`${
                        validated && !validatePrice.test(campaignForm?.price)
                          ? "border-danger"
                          : ""
                      }`}
                      placeholder="Product Price"
                      name="price"
                      value={campaignForm.price || ""}
                      // onChange={(event) => {
                      //   const { value } = event.target;
                      //   setCampaignForm({
                      //     ...campaignForm,
                      //     price: value,
                      //   });
                      // }}
                      onChange={handleProductFieldChange}
                      required
                      isInvalid={
                        validated && !validatePrice.test(campaignForm.price)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the product price!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex align-items-center">
                {/* <FormControlLabel
                  control={<Checkbox />}
                  label="Xclusit Store"
                  onChange={handleXclusitStoreChange}
                  value={campaignForm?.isXclusitStore}
                  name="xclusit_store"
                  checked={campaignForm?.isXclusitStore}
                /> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="purchase_options"
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Xclusit Store"
                    onChange={handleXclusitStoreChange}
                    value="xclusit_store"
                    checked={campaignForm?.purchase_options.find(
                      (i) => i === "xclusit_store"
                    )}
                    // name="purchase_options"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Make reservations"
                    onChange={handlePurchaseOptionsChange}
                    value="make_reservations"
                    // name="purchase_options"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Book appointments"
                    onChange={handlePurchaseOptionsChange}
                    value="book_appointments"
                    // name="purchase_options"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Shop Online"
                    onChange={handlePurchaseOptionsChange}
                    value="shop_online"
                    // name="purchase_options"
                  />
                </RadioGroup>
              </div>

              <div className="row">
                <div className="col-lg-6 col-12" controlId="category">
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control
                      as="select"
                      style={{ height: 48 }}
                      value={campaignForm.category}
                      onChange={handleCategoryChange}
                      required
                    >
                      <option value="">Choose a category</option>
                      {allCategories.map((cat, index) => (
                        <option key={index} value={cat.category}>
                          {cat.category}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please enter the product category!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div
                  className="col-lg-6 col-12 mt-3 mt-lg-0"
                  controlId="subcategory"
                >
                  <Form.Group>
                    <Form.Label>Sub-Category</Form.Label>
                    <Form.Control
                      as="select"
                      style={{ height: 48 }}
                      name="subcategory"
                      value={campaignForm.subcategory}
                      // onChange={(event) =>
                      //   setCampaignForm({
                      //     ...campaignForm,
                      //     subcategory: event.target.value,
                      //   })
                      // }
                      onChange={handleProductFieldChange}
                      // required
                    >
                      {<option value="">Choose a category first</option>}
                      {!_.isEmpty(campaignForm.category) &&
                        _.isEmpty(subCategories?.[0]?.subcategory) && (
                          <option value="">There are no subcategories</option>
                        )}
                      {subCategories[0]?.subcategory.map(
                        (subcategory, index) => (
                          <option key={index} value={subcategory}>
                            {subcategory}
                          </option>
                        )
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please enter the product sub category!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              {/* {campaignForm?.isXclusitStore ? ( */}
              {campaignForm?.purchase_options.find(
                (i) => i === "xclusit_store"
              ) ? (
                <>
                  <div className="row">
                    {campaignForm?.category !== "Food and Drink" ? (
                      <div className="col-md-6 col-12 mt-3 mt-lg-0">
                        <Form.Group>
                          <Form.Label>Return Options</Form.Label>
                          <Form.Check
                            inline
                            label="Return"
                            name="isReturnable"
                            value={true}
                            className="return-radios"
                            onChange={(event) => {
                              setCampaignForm({
                                ...campaignForm,
                                isReturnable:
                                  event.target.value === "true" ? true : false,
                              });
                            }}
                            type="radio"
                            id="return"
                            required={
                              !!campaignForm?.purchase_options.find(
                                (i) => i === "xclusit_store"
                              ) && campaignForm?.category !== "Food and Drink"
                            }
                          />
                          <Form.Check
                            inline
                            label="Non Return"
                            name="isReturnable"
                            value={false}
                            className="return-radios"
                            onChange={(event) => {
                              setCampaignForm({
                                ...campaignForm,
                                isReturnable:
                                  event.target.value === "true" ? true : false,
                              });
                            }}
                            type="radio"
                            id="non_return"
                            required={
                              !!campaignForm?.purchase_options.find(
                                (i) => i === "xclusit_store"
                              ) && campaignForm?.category !== "Food and Drink"
                            }
                          />
                        </Form.Group>
                      </div>
                    ) : null}
                  </div>

                  {campaignForm?.isReturnable ? (
                    <div className="row">
                      <div className="col-lg-6 col-12" controlId="returnDays">
                        <Form.Group>
                          <Form.Label>Product Return days</Form.Label>
                          <Form.Control
                            as="select"
                            style={{ height: 48 }}
                            name="returnDays"
                            value={campaignForm.returnDays}
                            onChange={handleProductFieldChange}
                            required={campaignForm?.isReturnable}
                          >
                            <option value="">Choose days</option>
                            {returnDaysOptionList.map((cat, index) => (
                              <option key={index} value={cat.value}>
                                {cat.label}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please enter the product return days!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                      <Form.Group>
                        <Form.Label>Tax category</Form.Label>
                        {/* <Form.Control
                         as="select"
                         style={{
                           height: 48,
                           width: "100%",
                           textOverflow: "ellipsis",
                           overflow: "hidden",
                           whiteSpace: "nowrap",
                         }}
                         value={campaignForm.taxCode}
                         onChange={(e) => {
                           const { value } = e.target;
                           setCampaignForm({
                             ...campaignForm,
                             taxCode: value,
                           });
                         }}
                         required
                       >
                         <option value="">Select Tax Category</option>
                         {taxCategories.map((cat, index) => (
                           <option
                             key={index}
                             value={cat.product_tax_code}
                             style={{
                               textOverflow: "ellipsis",
                               overflow: "hidden",
                               whiteSpace: "nowrap",
                             }}
                           >
                             {cat.name}
                           </option>
                         ))}
                       </Form.Control> */}
                        <Select
                          name="taxCode"
                          options={taxCategories}
                          onChange={(e) => {
                            setCampaignForm({
                              ...campaignForm,
                              taxCode: e.value,
                            });
                          }}
                          styles={customStyles}
                          required={
                            !!campaignForm?.purchase_options.find(
                              (i) => i === "xclusit_store"
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the product tax category!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 col-12 mt-3 mt-lg-0">
                      <Form.Group>
                        <Form.Label>Delivery Fees</Form.Label>
                        <Form.Control
                          type="text"
                          style={{ height: 48 }}
                          className={`${
                            validated &&
                            !validatePrice.test(campaignForm?.delivery_fees)
                              ? "border-danger"
                              : ""
                          }`}
                          placeholder="Delivery Fees"
                          name="delivery_fees"
                          value={campaignForm.delivery_fees || ""}
                          // onChange={(event) => {
                          //   const { value } = event.target;
                          //   setCampaignForm({
                          //     ...campaignForm,
                          //     delivery_fees: value,
                          //   });
                          // }}
                          onChange={handleProductFieldChange}
                          required
                          isInvalid={
                            validated &&
                            !validatePrice.test(campaignForm.delivery_fees)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the delivery fees!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    {campaignForm?.category !== "Food and Drink" ? (
                      <div className="col-md-6 col-12 mt-3 mt-lg-0">
                        <Form.Group>
                          <Form.Label>Shipping Days</Form.Label>
                          <Form.Control
                            type="text"
                            style={{ height: 48 }}
                            placeholder="Shipping Days"
                            name="shipping_days"
                            value={campaignForm.shipping_days || ""}
                            onChange={handleProductFieldChange}
                            required={
                              campaignForm?.category !== "Food and Drink"
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter the shipping days!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </div>
                    ) : // <div className="col-md-6 col-12 mt-3 mt-lg-0">
                    //   <Form.Group>
                    //     <Form.Label>{`Shipping Time (minutes)`}</Form.Label>
                    //     <Form.Control
                    //       type="text" // TODO: verified type of shipping hours
                    //       style={{ height: 48 }}
                    //       placeholder="Shipping time"
                    //       className={`${
                    //         validated &&
                    //         !validatePrice.test(campaignForm?.shipping_hours)
                    //           ? "border-danger"
                    //           : ""
                    //       }`}
                    //       name="shipping_hours"
                    //       value={campaignForm.shipping_hours || ""}
                    //       onChange={handleProductFieldChange}
                    //       required={
                    //         campaignForm?.category === "Food and Drink"
                    //       }
                    //       isInvalid={
                    //         validated &&
                    //         !validatePrice.test(campaignForm?.shipping_hours)
                    //       }
                    //     />
                    //     <Form.Control.Feedback type="invalid">
                    //       Please enter the shipping hours!
                    //     </Form.Control.Feedback>
                    //   </Form.Group>
                    // </div>
                    null}
                  </div>

                  {campaignForm?.category !== "Food and Drink" ? (
                    <>
                      {allVariantsList.map((variantObj, index) => (
                        <div key={index}>
                          <div className="row align-items-center">
                            {variantObj.variant.map(
                              (item, innerIndex) =>
                                // item.property !== "quantity" ? (
                                //   <Col key={`${index}-${innerIndex}`}>
                                //     <Form.Group controlId={`property-${index}-${innerIndex}`}>
                                //       <Form.Label>{item.property}</Form.Label>
                                //       <Form.Control
                                //         as="select"
                                //         name={item.property}
                                //         value={item.value}
                                //         onChange={(e) =>
                                //           handleVariantChange(e, index, innerIndex)
                                //         }
                                //         required
                                //       >
                                //         <option value="">Select {item.property}</option>
                                //         {variantsList
                                //           .find((v) => v.property === item.property)
                                //           ?.value.map((optItem, optIndex) => (
                                //             <option key={optIndex} value={optItem}>
                                //               {optItem}
                                //             </option>
                                //           ))}
                                //       </Form.Control>
                                //       <Form.Control.Feedback type="invalid">
                                //         Please select product {item.property}!
                                //       </Form.Control.Feedback>
                                //     </Form.Group>
                                //   </Col>
                                // ) : (
                                //   <Col md={2} key={`${index}-${innerIndex}`}>
                                //     <Form.Group controlId={`quantity-${index}-${innerIndex}`}>
                                //       <Form.Label>Quantity</Form.Label>
                                //       <Form.Control
                                //         type="number"
                                //         name="quantity"
                                //         value={item.value}
                                //         onChange={(e) =>
                                //           handleVariantChange(e, index, innerIndex)
                                //         }
                                //         min="1"
                                //         placeholder="quantity"
                                //         required
                                //       />
                                //       <Form.Control.Feedback type="invalid">
                                //         Please add product quantity!
                                //       </Form.Control.Feedback>
                                //     </Form.Group>
                                //   </Col>
                                // )

                                variantRow("", index, innerIndex, item, "")
                              // <Col key={`${index}-${innerIndex}`}>
                              //   {item?.propertyType === "Radio" ? (
                              //     <Form.Group
                              //       controlId={`quantity-${index}-${innerIndex}`}
                              //     >
                              //       <Form.Label>{item.property}</Form.Label>
                              //       <Form.Check
                              //         inline
                              //         label={item?.value ? "Yes" : "No"}
                              //         name={`${item.property}-${index}-${innerIndex}`}
                              //         // value={false}
                              //         className="return-radios"
                              //         onChange={(e) =>
                              //           handleVariantChange(
                              //             e,
                              //             index,
                              //             innerIndex
                              //           )
                              //         }
                              //         type="checkbox"
                              //         id="return"
                              //         required={
                              //           !!campaignForm?.purchase_options.find(
                              //             (i) => i === "xclusit_store"
                              //           )
                              //         }
                              //       />
                              //       <Form.Control.Feedback type="invalid">
                              //         Please add product {item.property}!
                              //       </Form.Control.Feedback>
                              //     </Form.Group>
                              //   ) : (
                              //     <Form.Group
                              //       controlId={`quantity-${index}-${innerIndex}`}
                              //     >
                              //       <Form.Label>{item.property}</Form.Label>
                              //       <Form.Control
                              //         type={
                              //           item.property !== "quantity"
                              //             ? "text"
                              //             : "number"
                              //         }
                              //         name={item.property}
                              //         value={item.value}
                              //         onChange={(e) =>
                              //           handleVariantChange(
                              //             e,
                              //             index,
                              //             innerIndex
                              //           )
                              //         }
                              //         min="1"
                              //         placeholder={item.property}
                              //         required={
                              //           !!campaignForm?.purchase_options.find(
                              //             (i) => i === "xclusit_store"
                              //           )
                              //         }
                              //       />
                              //       <Form.Control.Feedback type="invalid">
                              //         Please add product {item.property}!
                              //       </Form.Control.Feedback>
                              //     </Form.Group>
                              //   )}
                              // </Col>
                            )}
                            <Col md="auto">
                              {index !== 0 && (
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleRemoveVariant(index)}
                                  className="p-2"
                                  style={{ minWidth: "fit-content" }}
                                >
                                  <DeleteIcon />
                                </Button>
                              )}
                            </Col>
                          </div>
                        </div>
                      ))}
                      {allVariantsList?.length ? (
                        <div className="d-flex justify-content-end">
                          <Button variant="outlined" onClick={handleAddVariant}>
                            {/* {campaignForm?.category !== "Food and Drink" */}
                            {/* ? "Add Variant" */}
                            {/* : "Add Food Sub cat."} */}
                            Add Variant
                          </Button>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    allNewMainCategoriesList?.map((mainItem, mainIndex) => (
                      <div key={mainIndex}>
                        <div className="row">
                          {mainItem?.addon_category?.map(
                            (mainCatItem, mainCatItemIndex) => {
                              const isMultiChoice =
                                mainItem?.addon_category?.find(
                                  (cat) => cat.name === "ismultichoice"
                                )?.value;
                              const isAddonCatRequired =
                                mainCatItem.name === "mainvariantname";
                              return (
                                <div
                                  // className="col-md-4 col-12 mt-3 mt-lg-0"
                                  className="col mt-3 mt-lg-0"
                                  key={mainCatItemIndex}
                                >
                                  {mainCatItem?.propertyType === "checkbox" ? (
                                    <Form.Group>
                                      <Form.Label>
                                        {mainCatItem?.label}
                                      </Form.Label>
                                      <Form.Check
                                        inline
                                        label={"Yes"}
                                        name={`radio-group-${mainIndex}-${mainCatItemIndex}`}
                                        className="return-radios"
                                        checked={mainCatItem?.value}
                                        onChange={(e) =>
                                          handleAddonCategoryChange(
                                            mainIndex,
                                            mainCatItemIndex,
                                            "value",
                                            e.target.checked
                                          )
                                        }
                                        type="checkbox"
                                        id={`radio-group-${mainIndex}-${mainCatItemIndex}`}
                                        required={
                                          !!campaignForm?.purchase_options.find(
                                            (i) => i === "xclusit_store"
                                          ) && mainCatItem?.isRequired
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please enter the {mainCatItem?.label}!
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  ) : (
                                    (mainCatItem?.name !== "maxselection" ||
                                      isMultiChoice) && (
                                      <Form.Group>
                                        <Form.Label>
                                          {mainCatItem?.label}
                                        </Form.Label>
                                        <Form.Control
                                          type={mainCatItem?.propertyType}
                                          min={
                                            mainCatItem?.propertyType ===
                                            "number"
                                              ? "1"
                                              : "0"
                                          }
                                          style={{ height: 48 }}
                                          placeholder={mainCatItem?.label}
                                          name={mainCatItem?.name}
                                          value={mainCatItem?.value || ""}
                                          className={`${
                                            mainCatItem?.name === "maxselection"
                                              ? !!addonCatErrors[
                                                  `maxselection-${mainIndex}`
                                                ]
                                                ? "border-danger"
                                                : ""
                                              : ""
                                          }`}
                                          onChange={(e) =>
                                            handleAddonCategoryChange(
                                              mainIndex,
                                              mainCatItemIndex,
                                              "value",
                                              e.target.value
                                            )
                                          }
                                          required={
                                            (campaignForm?.category ===
                                              "Food and Drink" &&
                                              mainCatItem?.isRequired &&
                                              isMultiChoice) ||
                                            isAddonCatRequired
                                          }
                                          isInvalid={
                                            mainCatItem?.name === "maxselection"
                                              ? !!addonCatErrors[
                                                  `maxselection-${mainIndex}`
                                                ]
                                              : false
                                          }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          {mainCatItem?.name === "maxselection"
                                            ? mainCatItem?.value == 0 ||
                                              mainCatItem?.value === ""
                                              ? `Please enter atleast 1 value`
                                              : addonCatErrors[
                                                  `maxselection-${mainIndex}`
                                                ]
                                            : `Please enter the ${mainCatItem?.label}!`}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )
                                  )}
                                </div>
                              );
                            }
                          )}
                          <Col md="auto" className="align-self-center">
                            {mainIndex !== 0 && (
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() =>
                                  handleRemoveMainCategory(mainIndex)
                                }
                                className="p-2"
                                style={{ minWidth: "fit-content" }}
                              >
                                <DeleteIcon />
                              </Button>
                            )}
                          </Col>
                        </div>

                        {mainItem?.addon_options?.map(
                          (subCatItem, subCatIndex) => (
                            <div key={subCatIndex} className="row">
                              <React.Fragment>
                                {subCatItem?.variant.map(
                                  (varItem, varIndex) => (
                                    <React.Fragment
                                      key={`${subCatIndex}-${varIndex}`}
                                    >
                                      {variantRow(
                                        mainIndex,
                                        subCatIndex,
                                        varIndex,
                                        varItem,
                                        mainItem.addon_category.find(
                                          (cat) => cat.name === "ispaid"
                                        )?.value
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                                <Col md="auto" className="align-self-center">
                                  {subCatIndex !== 0 && (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={() =>
                                        handleRemoveAddonOption(
                                          mainIndex,
                                          subCatIndex
                                        )
                                      }
                                      className="p-2"
                                      style={{ minWidth: "fit-content" }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  )}
                                </Col>
                              </React.Fragment>
                            </div>
                          )
                        )}

                        <Button
                          variant="outlined"
                          className="mb-3"
                          onClick={() => handleAddNewAddonOptions(mainIndex)}
                        >
                          Add Addon Opt.
                        </Button>
                      </div>
                    ))
                  )}
                  {campaignForm?.category === "Food and Drink" ? (
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="outlined"
                        className="mr-2"
                        onClick={handleAddNewMainFoodCategory}
                      >
                        Add Addon Cat.
                      </Button>
                    </div>
                  ) : null}
                </>
              ) : (
                <div>
                  <Form.Group>
                    <Form.Label>Product URL</Form.Label>
                    <Form.Control
                      style={{ height: 48 }}
                      type="url"
                      placeholder="https://example.com"
                      // pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                      name="url"
                      value={campaignForm.url || ""}
                      // onChange={(event) =>
                      //   setCampaignForm({
                      //     ...campaignForm,
                      //     url: event?.target?.value,
                      //   })
                      // }
                      onChange={handleProductFieldChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the product url!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}

              <div className="row">
                <div className="col-12">
                  <div>
                    <Form.Label>Product Image / Video (50 MB max.)</Form.Label>
                    <Upload
                      {...propsUploadFile}
                      onPreview={handlePreview}
                      accept="image/*,video/*"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="choose_file_button"
                          onClick={() => setErrorMessage("")}
                        >
                          Choose file
                        </Button>
                      </div>
                    </Upload>
                    {errorMessage && (
                      <div className="mt-1 text-danger">{errorMessage}</div>
                    )}
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <Form.Label>Product Preview</Form.Label>
                  {fileList?.length === 0 && (
                    <img
                      src={couponImage}
                      alt="Coupon"
                      width="150px"
                      height="100px"
                    />
                  )}
                  <List
                    grid={{ gutter: 16 }}
                    dataSource={fileList}
                    renderItem={(file) => (
                      <List.Item
                        style={{
                          position: "inherit",
                          zIndex: 0,
                          display: "flex",
                        }}
                      >
                        {file.type.startsWith("image/") ? (
                          <div className="position-relative">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveFile(file)}
                            >
                              X
                            </div>
                            <img
                              src={URL?.createObjectURL(file)}
                              alt={file.name}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        ) : (
                          <div className="position-relative">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveFile(file)}
                            >
                              X
                            </div>
                            <video
                              controls
                              src={URL?.createObjectURL(file)}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <Button
                variant="outlined"
                type="submit"
                // disabled={
                //   validateProduct(campaignForm) || loading || errorMessage
                // }
                // onClick={handleCreate}
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"
                  />
                )}
                Create Product
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  onCloseModal();
                }}
                style={{ marginLeft: "20px" }}
              >
                Close
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default ProductAddUpdateModal;
