import * as types from "../actions/index";

const initialState = {
  isAuthenticated: false,
  client: JSON.parse(localStorage.getItem("client")) || {},
  campaign: {},
  product: {},
  order: {},
  coupon: {},
  category: [],
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH:
      return {
        ...state,
        isAuthenticated: action.auth,
      };

    case types.SET_BUSINESS:
      return {
        ...state,
        client: action.business,
      };

    case types.SET_CAMPAIGN:
      return {
        ...state,
        campaign: action.campaign,
      };

    case types.SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };

    case types.SET_ORDER:
      return {
        ...state,
        order: action.order,
      };

    case types.SET_COUPON:
      return {
        ...state,
        coupon: action.coupon,
      };

    case types.SET_CATEGORY:
      return {
        ...state,
        category: action.category,
      };

    default:
      return state;
  }
};

export default businessReducer;
