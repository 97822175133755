import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import BoDashboard from "./components/BusinessOwners/BoDashboard/BoDashboard";
import SignUp from "./components/BusinessOwners/SignUp/SignUp";
import ContactUs from "./components/FrontEnd/ContactUs";
import Discovery from "./components/FrontEnd/Discovery";
import Home from "./components/FrontEnd/Home";
import Privacy from "./components/FrontEnd/privacy";
import Terms from "./components/FrontEnd/terms";
import Layout from "./components/Layout";
import SaDashboard from "./components/SuperAdmin/SaDashboard/SaDashboard";
import ThankyouPage from "./components/ThankyouPage/ThankyouPage";
import MainPage from "./pages/MainPage/MainPage";
import SuperAdminLogin from "./components/SuperAdmin/SaLogin/SuperAdminLogin";
import AppAutoLogin from "./components/FrontEnd/AppAutoLogin";

const App = () => {
  return (
    <Switch>
      {/* Public Routes */}
      <Route exact path="/auth" component={MainPage} />
      <Route exact path="/auth/:id" component={MainPage} />
      <Route exact path="/" component={Home} />
      <Route exact path="/app_login" component={AppAutoLogin} />
      <Route exact path="/business-owners/signup" component={SignUp} />
      <Route
        exact
        path="/admin"
        roles={["admin"]}
        component={SuperAdminLogin}
      />

      {/* Private Routes */}
      {/* BUSINESS ADMIN / USER role router */}
      <RoleRoute
        exact
        path="/Thankyou"
        roles={["business_admin"]}
        component={ThankyouPage}
      />
      <RoleRoute
        exact
        path="/contact"
        roles={["business_admin"]}
        component={ContactUs}
      />
      <RoleRoute
        exact
        path="/terms"
        roles={["business_admin"]}
        component={Terms}
      />
      <RoleRoute
        exact
        path="/privacy"
        roles={["business_admin"]}
        component={Privacy}
      />
      <RoleRoute
        exact
        path="/discovery"
        roles={["business_admin"]}
        component={Discovery}
      />
      <RoleRoute
        exact
        path="/app"
        roles={["business_admin"]}
        render={(props) => <Layout {...props} loadPage="home" />}
      />

      {/* BUSINESS OWNERS(BO) role router */}
      <RoleRoute
        exact
        path="/business-owners"
        roles={["business_owner"]}
        component={BoDashboard}
      />

      {/* SUPER ADMIN(SA) role router */}
      <RoleRoute
        exact
        path="/super-admin/dashboard"
        roles={["admin"]}
        component={SaDashboard}
      />

      {/* Redirect to auth page for routes with wrong access */}
      <Redirect exact from="*" to="/auth" />
    </Switch>
  );
};

const RoleRoute = ({ roles, ...props }) => {
  let userRolesGet = JSON.parse(localStorage.getItem("client"));
  // Simulate userRoles for demonstration
  // const userRoles = ["superAdmin"]; // Assuming user is logged in as a "user"
  const userRoles = userRolesGet?.roles; // Assuming user is logged in as a "user"

  console.log("userRoles", userRoles);

  // If userRoles include any of the required roles, render the component, else, redirect to auth
  if (roles.some((role) => userRoles?.includes(role))) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/auth" />;
  }
};

export default App;
