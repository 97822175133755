import { Chip, FormControl, MenuItem, Select } from "@mui/material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import config from "../../config";
import axios from "axios";
import { requestStatus } from "../../utils/utils";
import moment from "moment";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const RequestsTable = ({
  headings,
  data,
  total,
  isLoading,
  pageCount,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [status, setStatus] = useState([]);

  useEffect(() => {
    if (data) {
      const initialStatuses = data.map((item) => item.status || "");
      setStatus(initialStatuses);
    }
  }, [data]);

  const updateRequestStatus = async (orderId, newStatus) => {
    const payload = { status: newStatus };
    await axios
      .patch(
        `${config.apiUrl}/businessadmin/${orderId}/update-request-status`,
        payload
      )
      .then((res) => {
      })
      .catch((err) => console.error("order update status error: ", err));
  };

  const handleRequestStatusChange = (e, index, orderId) => {
    const newStatus = e.target.value;
    if (newStatus !== "") {
      setStatus((prev) => {
        const updatedStatuses = [...prev];
        updatedStatuses[index] = newStatus;
        return updatedStatuses;
      });
      updateRequestStatus(orderId, newStatus);
    }
  };

  const requestStatusList = requestStatus.reduce((acc, status) => {
    acc[status.value] = status;
    return acc;
  }, {});

  return (
    <div>
      <Paper className="mt-3">
        <TableContainer>
          <Table style={{ minWidth: "750px" }}>
            <TableHead>
              <TableRow>
                {headings.map((head, index) => (
                  <StyledTableCell key={index} align="left">
                    {head}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length ? (
                data?.map((item, mainIndex) => {
                    const ordersImgUrl = `https://xclusit-dev.s3.amazonaws.com${item?.orderId?.productId?.image}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={mainIndex}
                    >
                      <TableCell
                        style={{ minWidth: "160px" }}
                        className="text-center"
                      >
                        <img
                          src={ordersImgUrl}
                          width={60}
                          height={60}
                          style={{ objectFit: "contain" }}
                          alt="product-picture"
                        />
                      </TableCell>
                      <TableCell style={{ minWidth: "150px" }}>
                        {item?.orderId?.productId?.name}
                      </TableCell>
                      <TableCell style={{ minWidth: "205px" }}>
                        {item?.orderId?.productId?.description}
                      </TableCell>
                      <TableCell style={{ minWidth: "90px" }}>
                        ${item?.orderId?.productId?.price}
                      </TableCell>
                      <TableCell style={{ minWidth: "130px" }}>
                        {item?.orderId?.productId?.category}
                      </TableCell>
                      <TableCell style={{ minWidth: "150px" }}>
                        {item?.orderId?.productId?.subcategory}
                      </TableCell>
                      <TableCell style={{ minWidth: "150px" }}>
                        {item?.orderId?.productId?.code}
                      </TableCell>
                      <TableCell style={{ minWidth: "145px" }}>
                        {moment(item?.requestDate).format("DD, MMMM, YYYY")}
                      </TableCell>
                      <TableCell style={{ minWidth: "155px" }}>
                        {`${item?.orderId?.color || ""} ${
                          item?.orderId?.size || ""
                        } ${item?.orderId?.quantity || ""}`}
                      </TableCell>

                      {/* <TableCell style={{ minWidth: "100px" }}>
                      <Badge
                        pill
                        variant={
                          item?.productId?.isActive ? "primary" : "secondary"
                        }
                      >
                        {item?.productId?.isActive ? "Active" : "Inactive"}
                      </Badge>
                    </TableCell> */}
                      <TableCell style={{ minWidth: "150px" }}>
                        {/* {item?.status} */}
                        <FormControl variant="standard" fullWidth size="small">
                          {/* <InputLabel variant="standard" htmlFor="status">
                          Status
                        </InputLabel> */}
                          <Select
                            // value={status[mainIndex] || ""}
                            value={item.status}
                            onChange={(e) =>
                              handleRequestStatusChange(e, mainIndex, item._id)
                            }
                            inputProps={{
                              name: "status",
                              id: "status",
                            }}
                            renderValue={(selected) => {
                              const selectedStatus =
                                requestStatusList[selected] || {};
                              return (
                                <Chip
                                  label={selected}
                                  size="small"
                                  color={selectedStatus.color || "primary"}
                                />
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>select status</em>
                            </MenuItem>
                            {requestStatus.map((i, index) => (
                              <MenuItem key={index} value={i.value}>
                                {i.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : isLoading ? (
                <TableCell colSpan={10} className="text-center">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 32,
                        }}
                        spin
                      />
                    }
                  />
                </TableCell>
              ) : (
                <TableCell colSpan={10} className="text-center">
                  No data found!
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={pageCount}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default RequestsTable;
