import React, { useState } from 'react';
import config from '../../config';
import { Form, Input, Select } from 'antd';

const Step3 = ({ business, setNewBusiness }) => {
  const [isTouched, setTouched] = useState(false);
  const [inputValue, setValue] = useState("");
  const [isValid, setValid] = useState(true);
  const { states } = config;
  const formatNumber = (event) => {
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10);
    const areaCode = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input?.length > 6) {
      target.value = `(${areaCode}) ${middle}-${last}`;
    } else if (input?.length > 3) {
      target.value = `(${areaCode}) ${middle}`;
    } else if (input?.length > 0) {
      target.value = `(${areaCode}`;
    }

    setNewBusiness({ ...business, [event.target.id]: target.value });
  }

  function isText(val) {
    return /^[a-zA-Z]+ [a-zA-Z]+$/;
  }
  const handleInput = (event) => {
    setValue(event.target.value);
    validate(event.target.value);
    setNewBusiness({ ...business, [event.target.id]: event.target.value })
  }
  const validate = val => {

    if (business.address) {
      if (isText(val)) setValid(true);
      else setValid(false);
    }
    if (business.city) {
      if (isText(val)) setValid(true);
      else setValid(false);
    }

  }
  return (
    <>
      <h5 className="wizard-title py-2">Contact Info</h5>


      {/* <Form

        className="login-form"
        initialValues={{ remember: true }}
      >
        <Form.Item
          name="client_name"
          rules={[
            {
              required: true,
              message: 'Please input your Business Name!',
            }
          ]}
        >
          <Input
            value={business?.client_name}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Business Name"
          />
        </Form.Item>
        <Form.Item
          name="address"
          rules={[
            {
              required: true,
              message: 'Please input your Address!',
            }
          ]}
        >
          <Input
            value={business?.address}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Enter address"
          />
        </Form.Item>

        <Form.Item
          name="city"
          rules={[
            {
              required: true,
              message: 'Please input your city!',
            }
          ]}
        >
          <Input
            value={business?.city}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Enter city"
          />
        </Form.Item>

        <div className="form-group">
          <select
            id="state"
            name="state"
            className="form-control"
            defaultValue={business?.state}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })} >
            <option value="">Select a state</option>
            {
              states.map((state, index) => {
                return (
                  <option key={index} value={state}>{state}</option>
                )
              })
            }
          </select>
        </div>

        <Form.Item
          name="zip_code"
          rules={[
            {
              required: true,
              message: 'Please input your zip Code!',
            }
          ]}
        >



          <Input
            // maxLength={5}

            value={business?.zip}
            onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Enter zip"

          />


        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: 'Please input your phone!',
            }
          ]}
        >
          <Input

            // maxLength={12}
            value={business?.phone}
            onChange={(event) => formatNumber(event)}
            placeholder="Enter phone No."
          />
        </Form.Item>

      </Form> */}


      {/* {business.businessType[0] === "Storefront" ?

      
        <Form

          className="login-form"
          initialValues={{ remember: true }}
        >
           <div className="form-group">
            <input
              value={business?.client_name}
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
              placeholder="Business Name"
            />
         </div>
            <input
              className="form-control"
              id="address"
              name="address"
              type="text"
              placeholder="Enter address"
              value={business?.address} // Prop: The address input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
              required
            />

          
            <input
              className="form-control"
              id="city"
              name="city"
              type="text"
              placeholder="Enter city"
              value={business?.city} // Prop: The city input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
              required
            />

          <div className="form-group">
            <select
              id="state"
              name="state"
              className="form-control"
              defaultValue={business?.state}
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })} >
              <option value="">Select a state</option>
              {
                states.map((state, index) => {
                  return (
                    <option key={index} value={state}>{state}</option>
                  )
                })
              }
            </select>
          </div>

          

            <input
              className="form-control"
              id="zip"
              name="zip"
              type="text"
              minLength={4}
              maxLength={6}
              placeholder="Enter zip"
              value={business?.zip} // Prop: The zip input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />



 
            <input
              className="form-control"
              id="phone"
              name="phone"
              type="text"
              minLength={10}
              maxLength={12}
              placeholder="Enter phone"
              value={business?.phone} // Prop: The phone input data
              onChange={(event) => formatNumber(event)}
              required
            />


        </Form>
        :
        <Form

          className="login-form"
          initialValues={{ remember: true }}
        >

         
            <input
              value={business?.client_name}
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
              placeholder="Business Name"
            />

           <input
              className="form-control"
              id="address"
              name="address"
              type="text"
              placeholder="Enter address"
              value={business?.address} // Prop: The address input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />

          
            <input
              className="form-control"
              id="city"
              name="city"
              type="text"
              placeholder="Enter city"
              value={business?.city} // Prop: The city input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />
          <div className="form-group">
            <select
              id="state"
              name="state"
              className="form-control"
              defaultValue={business?.state}
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })} >
              <option value="">Select a state</option>
              {
                states.map((state, index) => {
                  return (
                    <option key={index} value={state}>{state}</option>
                  )
                })
              }
            </select>
          </div>

          
            <input
              className="form-control"
              id="zip"
              name="zip"
              type="text"
              minLength={4}
              maxLength={6}
              placeholder="Enter zip"
              value={business?.zip} // Prop: The zip input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />



          
             <input
              className="form-control"
              id="phone"
              name="phone"
              
              type="text"
              minLength={10}
              maxLength={12}
              placeholder="Enter phone"
              value={business?.phone} // Prop: The phone input data
              onChange={(event) => formatNumber(event)}
            />


        </Form>
      } */}



      {business.businessType[0] === "Storefront"

        ?
        <>
          <div className="form-group">
            <input
              className="form-control"
              id="address"
              name="address"
              type="text"
              placeholder="Enter address"
              value={business?.address} // Prop: The address input data
              onBlur={() => setTouched(true)}

              onChange={(event) => handleInput(event)}
            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}

            />
          </div>
          {isTouched && !inputValue && (
            <p style={{ color: "red" }}>Please Enter Address</p>
          )}
          <div className="form-group">
            <input
              className="form-control"
              id="city"
              name="city"
              type="text"
              placeholder="Enter city"
              onBlur={() => setTouched(true)}

              onChange={(event) => handleInput(event)}
              value={business?.city} // Prop: The city input data
            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}

            />
          </div>
          {isTouched && !inputValue && (
            <p style={{ color: "red" }}>Please Enter City</p>
          )}
          <div className="form-group">
            <select
              id="state"
              name="state"
              className="form-control"
              defaultValue={business?.state}
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })} >
              <option value="">Select a state</option>
              {
                states.map((state, index) => {
                  return (
                    <option key={index} value={state}>{state}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              id="zip"
              name="zip"
              type="text"
              minLength="4"
              maxLength="6"
              placeholder="Enter zip"

              value={business?.zip} // Prop: The zip input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              id="phone"
              name="phone"
              type="text"

              minLength="10"
              maxLength="14"
              placeholder="Enter phone"
              value={business?.phone} // Prop: The phone input data
              onChange={(event) => formatNumber(event)}
              required
            />
          </div>
        </>
        :
        <>
          <div className="form-group">
            <input
              className="form-control"
              id="address"
              name="address"
              type="text"
              placeholder="Enter address"
              value={business?.address} // Prop: The address input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              id="city"
              name="city"
              type="text"
              placeholder="Enter city"
              value={business?.city} // Prop: The city input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />
          </div>
          <div className="form-group">
            <select
              id="state"
              name="state"
              className="form-control"
              defaultValue={business?.state}
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })} >
              <option value="">Select a state</option>
              {
                states.map((state, index) => {
                  return (
                    <option key={index} value={state}>{state}</option>
                  )
                })
              }
            </select>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              id="zip"
              name="zip"
              type="text"
              minLength="4"
              maxLength="6"
              placeholder="Enter zip"
              value={business?.zip} // Prop: The zip input data
              onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              id="phone"
              name="phone"
              type="text"
              minLength="10"

              maxLength="14"
              placeholder="Enter phone"
              value={business?.phone} // Prop: The phone input data
              onChange={(event) => formatNumber(event)}
            />
          </div>
        </>
      }


    </>
  )
}

export default Step3;
