import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React, { useState } from "react";
import styles from "./SignUpPage.module.css";
import axios from "axios";
import config from "../../../config";
import { toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { birthdateValidation } from "../../../utils/utils";

const SignUp = () => {
  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const history = useHistory();

  const [boData, setBoData] = useState({});
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const steps = ["Basic Information", "Business Information"];

  const cities = [
    "New York City",
    "Los Angeles",
    "Chicago",
    "Houston",
    "Philadelphia",
    "Phoenix",
    "San Antonio",
    "San Diego",
    "Dallas",
    "San Jose",
    "Austin",
    "Jacksonville",
    "Fort Worth",
    "Columbus",
    "Charlotte",
    "San Francisco",
    "Indianapolis",
    "Seattle",
    "Denver",
    "Washington",
    "Boston",
    "El Paso",
    "Detroit",
    "Nashville",
    "Portland",
    "Memphis",
    "Oklahoma City",
    "Las Vegas",
    "Louisville",
    "Baltimore",
    "Milwaukee",
    "Albuquerque",
    "Tucson",
    "Fresno",
    "Sacramento",
    "Kansas City",
    "Long Beach",
    "Mesa",
    "Atlanta",
    "Colorado Springs",
    "Virginia Beach",
    "Raleigh",
    "Omaha",
    "Miami",
    "Oakland",
    "Minneapolis",
    "Tulsa",
    "Wichita",
    "New Orleans",
    "Arlington",
    "Cleveland",
    "Bakersfield",
    "Tampa",
    "Aurora",
    "Honolulu",
    "Anaheim",
    "Santa Ana",
    "Corpus Christi",
    "Riverside",
    "St. Louis",
    "Lexington",
    "Stockton",
    "Pittsburgh",
    "Saint Paul",
    "Anchorage",
    "Cincinnati",
    "Henderson",
    "Greensboro",
    "Plano",
    "Newark",
    "Lincoln",
    "Toledo",
    "Orlando",
    "Chula Vista",
    "Irvine",
    "Fort Wayne",
    "Jersey City",
    "Durham",
    "St. Petersburg",
    "Laredo",
    "Buffalo",
    "Madison",
    "Lubbock",
    "Chandler",
    "Scottsdale",
    "Reno",
    "Glendale",
    "Norfolk",
    "Winston–Salem",
    "North Las Vegas",
    "Irving",
    "Chesapeake",
    "Gilbert",
    "Hialeah",
    "Garland",
    "Fremont",
    "Baton Rouge",
    "Richmond",
    "Boise",
    "San Bernardino",
    "Spokane",
    "Des Moines",
    "Modesto",
    "Birmingham",
    "Tacoma",
    "Fontana",
    "Rochester",
    "Oxnard",
    "Moreno Valley",
    "Fayetteville",
    "Aurora",
    "Glendale",
    "Yonkers",
    "Huntington Beach",
    "Amarillo",
    "Mobile",
    "Grand Rapids",
  ];

  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const isValid18YearsOld = (value) => {
    const today = new Date();
    const birthDate = new Date(value);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age >= 18;
  };

  const validationSchemaStep1 = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    birthday: Yup.string()
      .required("Birthday is required")
      .test("is-18", "You must be at least 18 years old", isValid18YearsOld),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zipCode: Yup.string().required("Zip code is required"),
    password: Yup.string().required("password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const validationSchemaStep2 = Yup.object().shape({
    focusedBusiness: Yup.string().required("Business type is required"),
    primaryAudienceRegion: Yup.string().required(
      "Primary audience is required"
    ),
    socialAudienceRegion: Yup.string().required(
      "Largest social audience is required"
    ),
    isPaidForContents: Yup.string().required("Paid content is required"),
    postOnSocialMedia: Yup.string().required("Post frequency is required"),
  });

  const initialValuesStep1 = {
    name: "",
    email: "",
    phone: "",
    birthday: "",
    city: "",
    state: "",
    zipCode: "",
    password: "",
    confirmPassword: "",
  };

  const initialValuesStep2 = {
    focusedBusiness: "",
    primaryAudienceRegion: "",
    socialAudienceRegion: "",
    isPaidForContents: "",
    postOnSocialMedia: "",
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmitBusinessOwner = async (values) => {
    setBoData({ ...values });

    if (step === 1) {
      setIsLoading(true);
      const payLoad = {
        name: boData.name,
        email: boData.email,
        password: boData.password,
        phone: boData.phone,
        birthday: boData.birthday,
        city: boData.city,
        state: boData.state,
        zip: boData.zipCode,
        focusedBusiness: values.focusedBusiness,
        primaryAudienceRegion: values.primaryAudienceRegion,
        socialAudienceRegion: values.socialAudienceRegion,
        isPaidForContents: values.isPaidForContents == "Yes" ? true : false,
        postOnSocialMedia: values.postOnSocialMedia,
      };

      try {
        const response = await axios.post(
          `${config.apiUrl}/businessownerregister`,
          payLoad
        );
        notify("success", response?.data?.message);

        // setResponseData(response.data);
      } catch (error) {
        console.log("error: ", error);
        notify("error", error.data.message);
      } finally {
        history.push("/auth");
        setIsLoading(false);
      }
    } else if (step === 0) {
      handleNextStep();
      setBoData({ ...values });
    }
  };

  return (
    <div className={styles.main + " container-fluid d-flex mainPage"}>
      <div className={styles.right_side + ""}>
        <p style={{ color: "#FFFFFF", fontSize: "28px" }}>
          BUSINESS OWNER SIGN UP
        </p>
        {isLoading ? (
          <Box
            sx={{
              height: "600px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 12px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 32,
                  }}
                  spin
                />
              }
            />
          </Box>
        ) : (
          <div className="container mt-5">
            <Formik
              initialValues={
                step !== 0 ? initialValuesStep2 : initialValuesStep1
              }
              validationSchema={
                step !== 0 ? validationSchemaStep2 : validationSchemaStep1
              }
              onSubmit={(values, event) => {
                handleSubmitBusinessOwner(values);
              }}
              enableReinitialize
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="card card-body">
                    <div className="mb-3">
                      <Stepper activeStep={step} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>

                    {step === 0 && (
                      <div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="text"
                              name="name"
                              placeholder="Name"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="email"
                              name="email"
                              placeholder="Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="tel"
                              name="phone"
                              placeholder="Phone"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="date"
                              name="birthday"
                              max={birthdateValidation()}
                              placeholder="Birthday"
                            />
                            <ErrorMessage
                              name="birthday"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              as="select"
                              className="form-control"
                              name="city"
                            >
                              <option value="">Select City</option>
                              {cities.map((city, index) => (
                                <option key={index} value={city}>
                                  {city}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              as="select"
                              className="form-control"
                              name="state"
                            >
                              <option value="">Select State</option>
                              {states.map((state, index) => (
                                <option key={index} value={state}>
                                  {state}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="state"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="number"
                              name="zipCode"
                              placeholder="Zip code"
                            />
                            <ErrorMessage
                              name="zipCode"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="password"
                              name="password"
                              placeholder="Password"
                            />
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {step === 1 && (
                      <div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="text"
                              name="focusedBusiness"
                              placeholder="What type of businesses or content do you plan to focus on"
                            />
                            <ErrorMessage
                              name="focusedBusiness"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="text"
                              name="primaryAudienceRegion"
                              placeholder="What is your primary audience region"
                            />
                            <ErrorMessage
                              name="primaryAudienceRegion"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              className="form-control"
                              type="text"
                              name="socialAudienceRegion"
                              placeholder="Where’s your largest social audience"
                            />
                            <ErrorMessage
                              name="socialAudienceRegion"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              as="select"
                              className="form-control"
                              name="isPaidForContents"
                            >
                              <option value="">
                                Have you ever gotten paid for content you’ve
                                posted or linked?
                              </option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Field>
                            <ErrorMessage
                              name="isPaidForContents"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <div className="col">
                            <Field
                              as="select"
                              className="form-control"
                              name="postOnSocialMedia"
                            >
                              <option value="">
                                How often do you post to your primary social
                                media account?
                              </option>
                              <option value="Daily">Daily</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Less frequently">
                                Less frequently
                              </option>
                            </Field>
                            <ErrorMessage
                              name="postOnSocialMedia"
                              component="div"
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-row">
                      <div className="col-md-6 p-2">
                        <button
                          className="btn btn-secondary btn-block"
                          type="button"
                          disabled={step === 0}
                          onClick={handleBackStep}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-md-6 p-2">
                        <button
                          type={"submit"}
                          className="btn btn-primary btn-block"
                        >
                          {step === 1 ? "Finish" : "Next"}
                        </button>
                      </div>
                    </div>
                    <div className="text-center mt-3">
                      <Button
                        size="large"
                        variant="outlined"
                        color="dark"
                        onClick={() => history.push("/auth")}
                      >
                        SIGN IN
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignUp;
