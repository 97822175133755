import React, { useEffect, useState } from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import OrdersTable from "./OrdersTable";
import axios from "axios";
import config from "../../config";

const Orders = (props) => {
  const { setPage } = props;
  const [ordersList, setOrdersList] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const getOrderList = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${config.apiUrl}/businessadmin/${LoginBOData._id}/order-list?page=${
          pageCount + 1
        }&limit=${rowsPerPage}`
      )
      .then((res) => {
        setOrdersList(res?.data?.data);
      })
      .catch((err) => {
        console.error("orders list get error: ", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOrderList();
  }, [pageCount, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPageCount(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(0);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Orders</Typography>
          </Breadcrumbs>
        </div>
      </div>

      <OrdersTable
        headings={[
          "ORDER-IMAGE",
          "ORDER-NAME",
          // "PRODUCT-DESCRIPTION",
          // "AMOUNT",
          // "QUANTITY",
          "CATEGORY",
          "SUB-CATEGORY",
          // "RETURN",
          // "RETURN-DAYS",
          "CODE",
          // "CUSTOMER NAME",
          // "PHONE NUMBER",
          // "SHIPPING ADDRESS",
          "STATUS",
          "VIEW"
        ]}
        data={ordersList?.orderList}
        total={ordersList?.total}
        isLoading={isLoading}
        pageCount={pageCount}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        {...props}
      />
    </>
  );
};

export default Orders;
