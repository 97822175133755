import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import styles from './SuperAdminLogin.module.css';
import services from '../../../services';
import { toast } from 'react-toastify';
import { setBusiness } from '../../../store/actions';
import { useDispatch } from 'react-redux';

const SuperAdminLogin = ({ history }) => {
  const [login, setLogin] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });

  const SuperAdminLogin = async (event) => {
    // event.preventDefault();
    setIsLoading(true);

    await services.SuperAdminServices.SaLogin(login)
      .then(response => response.json())
      .then(response => {
        setIsLoading(false);
        if (response?.err) {
          notify('error', response?.message);
          return false;
        }
        notify('success', response?.message);
        const { client, expiresIn, token } = response?.data;
        localStorage.setItem('isAuth', true);
        localStorage.setItem('client', JSON.stringify(client));
        localStorage.setItem('expiresAt', expiresIn);
        localStorage.setItem('token', token);
        dispatch(setBusiness(client));

        if (client.roles.includes('business_admin')) {
          history.push('/app');
        }
        if (client.roles.includes('business_owner')) {
          history.push('/business-owners');
        }
        if (client.roles.includes('admin')) {
          history.push('/super-admin/dashboard');
        }

      })
      .catch(e => {
        setIsLoading(false);
        return console.error("Admin login: ", e)
      });
  }

  const RenderBack = () => {
    history.push('/')
  }

  return (
    <div className={styles.main + ' container-fluid d-flex mainPage'}>

      <div className={styles.right_side + ''}>
        <div className="card card-body">
          <form action="">
            <h2 className="d-flex justify-content-between">Admin Sign In

              <CloseOutlined className="closeIcon"
                onClick={RenderBack}
              />
            </h2>
            <div >
              <div className="row my-4">
                <div className="col">
                  <Form
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={SuperAdminLogin}
                  // disabled={validateLoginForm(login)}
                  >
                    <Form.Item
                      name="email"

                      rules={[{ required: true, type: "email", message: 'Please input your Email!' }]}
                    >
                      <Input
                        onChange={(event) => setLogin({ ...login, [event.target.id]: event.target.value })}
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Enter email"
                      />
                    </Form.Item>


                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: 'Please input your Password!' },
                      ]}
                    >
                      <Input.Password
                        type="password"
                        onChange={(event) => setLogin({ ...login, [event.target.id]: event.target.value })}
                        placeholder="Enter password"
                      />
                    </Form.Item>

                    <Form.Item className="mb-0">
                      {isLoading ? (
                        <Button type="primary" loading>
                          Loading
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          size="large"
                          fullWidth="true"
                          htmlType="submit"
                          className="login-form-button"
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                        >
                          Log in
                        </Button>
                      )}
                    </Form.Item>

                  </Form>
                </div>
              </div>


              {/* <div className="d-flex justify-content-between flex-wrap">
            <Button size="large" className="my-2" variant="outlined" color="primary" onClick={() => setPage('signup')}>SIGN UP AS A USER</Button>
            <Button size="large" className="my-2" variant="outlined" color="primary" onClick={() => history.push('/business-owners/signup')}>SIGN UP AS A BUSINESS OWNER</Button>
            <Button size="large" className="my-2" variant="outlined" color="secondary" onClick={() => setPage('forgot')}>FORGOT PASSWORD</Button>
          </div> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SuperAdminLogin