import { LoadingOutlined } from "@ant-design/icons";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";

import "react-dates/initialize";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { Button } from "@material-ui/core";
import axios from "axios";
import "react-dates/lib/css/_datepicker.css";
import { toast } from "react-toastify";
import config from "../../../config";

toast.configure();

// =================
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { axiosConfig } from "../../../utils/utils";
// =================

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #f2f2f2",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const Plans = (props) => {
  const classes = useStyles();
  const { setPage } = props;
  const theme = useTheme();
  const [allPlanData, setAllPlanData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openAddPlan, setOpenAddPlan] = React.useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    price: "",
    included: "",
    interval: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(null);

  const getAllPlans = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/plans/get-plans`,
        null,
        accessToken
      );
      setAllPlanData(response?.data?.data?.plans);
    } catch (error) {
      console.log("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const handleClickOpen = (plan = null) => {
    if (plan) {
      setFormValues({
        name: plan.name,
        price: plan.amount,
        included: plan.description,
        interval: plan.interval,
      });
      setCurrentPlanId(plan._id);
      setIsEditing(true);
    } else {
      setFormValues({
        name: "",
        price: "",
        included: "",
        interval: "",
      });
      setIsEditing(false);
    }
    setOpenAddPlan(true);
  };

  const handleClose = () => {
    setOpenAddPlan(false);
  };

  const handleChange = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission on Enter
      setFormValues({ ...formValues, included: formValues.included + "\n" });
    } else {
      setFormValues({ ...formValues, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async (event) => {
    const accessToken = localStorage.getItem("token");
    event.preventDefault();
    setIsLoading(true);

    const payload = {
      name: formValues.name,
      description: formValues.included,
      amount: formValues.price,
      interval: formValues.interval,
    };

    try {
      if (isEditing) {
        await axiosConfig(
          "patch",
          `${config.apiUrl}/plans/update-plan/${currentPlanId}`,
          payload,
          accessToken
        );
      } else {
        await axiosConfig(
          "post",
          `${config.apiUrl}/plans/create-plan`,
          payload,
          accessToken
        );
      }
      setOpenAddPlan(false);
      getAllPlans();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setFormValues({
        name: "",
        price: "",
        included: "",
        interval: "",
      });
    }
  };

  const handleDeletePlan = async (PlanId) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosConfig(
        "delete",
        `${config.apiUrl}/plans/delete-plan/${PlanId}`,
        null,
        accessToken
      );
      getAllPlans();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Plans</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <div
        className="row my-3 align-items-center"
        style={{ marginLeft: "0", marginRight: "0" }}
      >
        <div className="col-md-7 col-12 pl-0 pr-0">
          <h4>Plans</h4>
        </div>
        <div className="col-md-5 col-12 text-left text-md-right pl-0 pr-0"></div>
      </div>
      <div className="row mt-3" style={{ marginLeft: "0", marginRight: "0" }}>
        <div className={"col pl-0 pr-0 " + classes.root}>
          {
            <>
              <div className="search mb-2">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "250px" }} className="mr-2">
                    <div className="searchFrom products_search_bar w-100"></div>
                  </div>
                  <Button
                    style={{ height: "38px", margin: "22px" }}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    Add Plan
                  </Button>
                </div>
              </div>

              <TableContainer className={classes.container}>
                <Table style={{ minWidth: "750px" }}>
                  <TableHead>
                    <TableRow>
                      {[
                        "PLAN NAME",
                        "PLAN AMOUNT",
                        "WHAT'S INCLUDED ?",
                        "INTERVAL",
                        "ACTION",
                      ].map((head, index) => (
                        <StyledTableCell key={index} align="left">
                          {head}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box
                            sx={{
                              height: "500px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0px 12px",
                              width: "100%",
                            }}
                          >
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: 32,
                                  }}
                                  spin
                                />
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : allPlanData && allPlanData?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={12} style={{ textAlign: "center" }}>
                          No plan found...
                        </TableCell>
                      </TableRow>
                    ) : (
                      allPlanData &&
                      allPlanData.map((Item, I) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={I}>
                            <TableCell style={{ minWidth: "150px" }}>
                              {Item.name}
                            </TableCell>
                            <TableCell style={{ minWidth: "150px" }}>
                              {Item.amount}
                            </TableCell>
                            <TableCell style={{ minWidth: "150px" }}>
                              {Item.description}
                            </TableCell>
                            <TableCell style={{ minWidth: "150px" }}>
                              {Item.interval}
                            </TableCell>
                            <StyledTableCell style={{ minWidth: "150px" }}>
                              <Box sx={{ display: "flex", gap: "12px" }}>
                                <Box
                                  onClick={() => {
                                    handleClickOpen(Item);
                                  }}
                                >
                                  <EditIcon />
                                </Box>
                                <Box onClick={() => handleDeletePlan(Item._id)}>
                                  <DeleteIcon />
                                </Box>
                              </Box>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
        </div>

        {/* Add plan model code hear */}
        <Dialog
          open={openAddPlan}
          onClose={handleClose}
          sx={{ padding: "24px" }}
        >
          <DialogTitle sx={{ padding: "24px" }}>
            {isEditing ? "Edit Plan" : "Add Plan"}
          </DialogTitle>
          <DialogContent>
            <form>
              <label>
                Plan Name:
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                />
              </label>
              <label>
                Price:
                <input
                  type="number"
                  name="price"
                  value={formValues.price}
                  onChange={handleChange}
                />
              </label>
              <label>
                What's Included:
                <textarea
                  name="included"
                  rows="4"
                  cols="50"
                  value={formValues.included}
                  onChange={handleChange}
                ></textarea>
              </label>
              <label>
                Interval:
                <select
                  className="mr-2 form-control"
                  style={{ height: "38px" }}
                  name="interval"
                  value={formValues.interval}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </label>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>
              {isEditing ? "Update" : "Submit"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default Plans;
