import { EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Chip, FormControl, MenuItem, Select } from "@mui/material";
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../config";
import { setCurrentOrder } from "../../store/actions";
import { orderStatus } from "../../utils/utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const OrdersTable = ({
  headings,
  data,
  total,
  setPage,
  isLoading,
  pageCount,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [status, setStatus] = useState([]);
  const dispatch = useDispatch();

  const setCurrentOrderDetails = (order) => {
    localStorage.setItem("producrid", order._id);
    dispatch(setCurrentOrder(order));
    setPage("Orders-details");
  };

  useEffect(() => {
    if (data) {
      const initialStatuses = data.map((item) => item.status || "");
      setStatus(initialStatuses);
    }
  }, [data]);

  const updateOrderStatus = async (orderId, newStatus) => {
    const payload = { status: newStatus };
    await axios
      .patch(
        `${config.apiUrl}/businessadmin/${orderId}/update-order-status`,
        payload
      )
      .then((res) => {})
      .catch((err) => console.error("order update status error: ", err));
  };

  const handleOrderStatusChange = (e, index, orderId) => {
    const newStatus = e.target.value;
    if (newStatus !== "") {
      setStatus((prev) => {
        const updatedStatuses = [...prev];
        updatedStatuses[index] = newStatus;
        return updatedStatuses;
      });
      updateOrderStatus(orderId, newStatus);
    }
  };

  const orderStatusList = orderStatus.reduce((acc, status) => {
    acc[status.value] = status;
    return acc;
  }, {});

  return (
    <Paper className="mt-3">
      <TableContainer>
        <Table style={{ minWidth: "750px" }}>
          <TableHead>
            <TableRow>
              {headings.map((head, index) => (
                <StyledTableCell key={index} align="left">
                  {head}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length ? (
              data?.map((item, mainIndex) => {
                const ordersImgUrl = `https://xclusit-dev.s3.amazonaws.com${item?.productId?.image}`;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={mainIndex}>
                    <TableCell
                      style={{ minWidth: "160px" }}
                      className="text-center"
                    >
                      <img
                        src={ordersImgUrl}
                        width={60}
                        height={60}
                        style={{ objectFit: "contain" }}
                        alt="product-picture"
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>
                      {item?.productId?.name}
                    </TableCell>
                    {/* <TableCell style={{ minWidth: "205px" }}>
                      {item?.productId?.description}
                    </TableCell>
                    <TableCell style={{ minWidth: "90px" }}>
                      $
                      {(
                        parseFloat(item?.amount) + parseFloat(item?.tax) || 0
                      ).toFixed(2)}
                    </TableCell>
                    <TableCell style={{ minWidth: "90px" }}>
                      {item?.quantity}
                    </TableCell> */}
                    <TableCell style={{ minWidth: "130px" }}>
                      {item?.productId?.category}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>
                      {item?.productId?.subcategory || "-"}
                    </TableCell>
                    {/* <TableCell style={{ minWidth: "100px" }}>
                      {item?.productId?.isReturnable ? "Yes" : "No"}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>
                      {item?.productId?.returnDays ?? "N/A"}
                    </TableCell> */}
                    <TableCell style={{ minWidth: "150px" }}>
                      {item?.productId?.code}
                    </TableCell>
                    {/* <TableCell style={{ minWidth: "170px" }}>
                      {item?.deliveryAddressId?.name ?? "N/A"}
                    </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>
                      {item?.deliveryAddressId?.phone ?? "N/A"}
                    </TableCell>
                    <TableCell style={{ minWidth: "250px" }}>
                      {deliveryAddressFormat(item?.deliveryAddressId?.address)}
                    </TableCell> */}
                    {/* <TableCell style={{ minWidth: "100px" }}>
                      <Badge
                        pill
                        variant={
                          item?.productId?.isActive ? "primary" : "secondary"
                        }
                      >
                        {item?.productId?.isActive ? "Active" : "Inactive"}
                      </Badge>
                    </TableCell> */}
                    <TableCell style={{ minWidth: "150px" }}>
                      {/* {item?.status} */}
                      <FormControl variant="standard" fullWidth size="small">
                        {/* <InputLabel variant="standard" htmlFor="status">
                          Status
                        </InputLabel> */}
                        <Select
                          value={status[mainIndex] || ""}
                          onChange={(e) =>
                            handleOrderStatusChange(e, mainIndex, item._id)
                          }
                          inputProps={{
                            name: "status",
                            id: "status",
                          }}
                          renderValue={(selected) => {
                            const selectedStatus =
                              orderStatusList[selected] || {};
                            return (
                              <Chip
                                label={selected}
                                size="small"
                                color={selectedStatus.color || "primary"}
                              />
                            );
                          }}
                        >
                          <MenuItem value="">
                            <em>select status</em>
                          </MenuItem>
                          {orderStatus.map((i, index) => (
                            <MenuItem key={index} value={i.value}>
                              {i.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <StyledTableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setCurrentOrderDetails(item)}
                        startIcon={<EyeOutlined />}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : isLoading ? (
              <TableCell colSpan={11} className="text-center">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 32,
                      }}
                      spin
                    />
                  }
                />
              </TableCell>
            ) : (
              <TableCell colSpan={10} className="text-center">
                No data found!
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={pageCount}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default OrdersTable;
