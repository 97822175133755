import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Select } from "antd";
import "antd/dist/antd.css";
import AWS from "aws-sdk";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import config from "../../config";
import services from "../../services";

const Step2 = ({ business, setNewBusiness }) => {
  const couponImage = require("../../assets/images/logo-placeholder.png");
  const [loading, setLoading] = useState(false);
  const [isTouched, setTouched] = useState(false);
  const [inputValue, setValue] = useState("");
  const [categoryList, setCategoryList] = useState([]);

  const S3_BUCKET = config.aws_bucket_name;
  const REGION = config.aws_region_name;

  AWS.config.update({
    accessKeyId: config.aws_bucket_access_key_ID,
    secretAccessKey: config.aws_bucket_access_key,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const getCategory = () => {
    services.UserServices.getCategory()
      .then((res) => {
        setCategoryList(res?.data?.data?.categoryList);
      })
      .catch((err) => console.error("getCategory error", err));
  };

  useEffect(() => {
    getCategory();
  }, []);

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const uploadLogo = async (e) => {
    var file = e.target.files[0];

    const params = {
      Bucket: S3_BUCKET,
      Key: file.type.split("/")[0] + "/" + "profile/" + Date.now() + file.name,
      Body: file,
      ACL: "public-read",
    };

    myBucket.upload(params, (err, data) => {
      if (err) {
        console.log(err);
        notify("error", "Somthing went wrong");
        return;
      }
      setLoading(false);
      setNewBusiness({ ...business, client_logo: data.Location });
      notify("success", "Image Upload Successfully.");
    });
  };

  const plainOptions = ["Storefront", "E-Commerce", "Brand / CPG"];

  function CheckBoxchange(checkedValues) {
    setNewBusiness({ ...business, businessType: checkedValues });
  }
  const handleInput = (event) => {
    setValue(event.target.value);
    setNewBusiness({ ...business, [event.target.id]: event.target.value });
  };
  return (
    <form noValidate autoComplete="off">
      {/* <h6 className="">Perhaps we need to add a text to let user know that they can select more than one option</h6> */}

      <h5 className="wizard-title py-2">Business Details</h5>
      <div className="row mb-3">
        <div className="col">
          <FormControl component="fieldset">
            <Checkbox.Group
              options={plainOptions}
              value={business?.businessType}
              defaultValue={["Apple"]}
              onChange={CheckBoxchange}
            />
          </FormControl>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            required
            id="client_name"
            value={business?.client_name}
            onChange={(event) =>
              setNewBusiness({
                ...business,
                [event.target.id]: event.target.value,
              })
            }
            placeholder="Business Name"
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <select
            id="category"
            className="form-control"
            defaultValue={business?.category}
            onChange={(event) =>
              setNewBusiness({
                ...business,
                [event.target.id]: event.target.value,
              })
            }
          >
            <option value="">Select a category</option>
            {categoryList?.length === 0 && <option>Loading...</option>}
            {categoryList?.length !== 0 &&
              categoryList?.map((cat, index) => {
                return (
                  <option key={index} value={cat?.category}>
                    {cat?.category}
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <textarea
            id="about"
            required
            onChange={(event) =>
              setNewBusiness({
                ...business,
                [event.target.id]: event.target.value,
              })
            }
            placeholder="About your business"
            className="form-control"
            rows="3"
            defaultValue={business.about}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <input
            className="form-control"
            required
            id="tags"
            value={business?.tags}
            onChange={(event) =>
              setNewBusiness({
                ...business,
                [event.target.id]: event.target.value,
              })
            }
            placeholder="Tags- ex: greek, black-owned, vegan, organic, barbeque, etc "
          />
        </div>
      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please Enter Tags</p>
      )}
      <div className="row mb-3">
        <div className="col">
          <input
            className="form-control"
            required
            id="website"
            type="url"
            value={business?.website}
            onBlur={() => setTouched(true)}
            placeholder="https://example.com"
            pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
            onChange={(event) => handleInput(event)}
          />
        </div>
      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please Enter Right Url</p>
      )}
      <div className="row mb-5">
        <div className="col">
          <p className="uploadlogo">Upload Logo</p>
          <input
            required
            id="logo"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={uploadLogo}
            placeholder="Upload logo"
          />

          <div className="preview mt-2">
            {loading ? (
              <Spinner animation="border" />
            ) : business?.client_logo ? (
              <img src={business?.client_logo} alt="Coupon" width="150" />
            ) : (
              <img src={couponImage} alt="Coupon" width="150" />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Step2;
