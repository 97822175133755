import validator from "validator";
import _ from "lodash";

// Validate login form
export const validateLoginForm = (login) => {
  return (
    _.isEmpty(login.email) ||
    !validator.isEmail(login.email) ||
    _.isEmpty(login.password) ||
    login.password?.length <= 5
  );
};

export const validateForgotPasswordForm = (forgot) => {
  return _.isEmpty(forgot.email) || !validator.isEmail(forgot.email);
};

export const validatePasswordReset = (verify) => {
  return (
    _.isEmpty(verify.code) ||
    _.isEmpty(verify.password) ||
    _.isEmpty(verify.new_password) ||
    verify.password?.length < 8 ||
    verify.password !== verify.new_password
  );
};

// Sign Up form steps validation
export const signUpStep1 = (business) => {
  // Calculate the minimum valid birth date (13 years ago from today)
  const today = new Date();
  const minValidDate = new Date(today.setFullYear(today.getFullYear() - 18));
  const birthday = new Date(business.birthday);

  return (
    _.isEmpty(business.first_name) ||
    _.isEmpty(business.last_name) ||
    _.isEmpty(business.email) ||
    !validator.isEmail(business.email) ||
    _.isEmpty(business.password) ||
    business.password?.length < 8 ||
    _.isEmpty(business.birthday) || // Ensure the birthday is not empty
    birthday > minValidDate // Check if the birthday is at least 13 years ago
  );
};

export const signUpStep2 = (business) => {
  var urlvalid = business.website;
  let error = true;

  if (urlvalid) {
    // var res = urlvalid.match('http://' );
    var res = urlvalid.match("(http|https)://");

    //  var res = urlvalid.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null) error = true;
    else error = false;
  }

  return (
    _.isEmpty(business.client_name) ||
    _.isEmpty(business.tags) ||
    _.isEmpty(business.website) ||
    error ||
    _.isEmpty(business.businessType)
  );
};

export const signUpStep3 = (business) => {
  return (
    ((business.businessType === "Storefront" ||
      business.businessType === "both") &&
      _.isEmpty(business.address)) ||
    _.isEmpty(business.city) ||
    _.isEmpty(business.state) ||
    _.isEmpty(business.tags) ||
    _.isEmpty(business.website) ||
    _.isEmpty(business.zip) ||
    business.zip?.length < 4 ||
    _.isEmpty(business.phone) ||
    business.phone?.length < 14
  );
};

export const signUpStep4 = (business) => _.isEmpty(business.hours_of_operation);

export const signUpStep5 = (business) => _.isEmpty(business.frequency);

// Validateg new campaign creation
export const validateCampaign = (campaign) => {
  return (
    _.isEmpty(campaign.campaign_name) ||
    _.isEmpty(campaign.campaign_start_date) ||
    _.isEmpty(campaign.campaign_description) ||
    _.isEmpty(campaign.campaign_expiration_date)
  );
};

// Validateg new Promotions creation
export const validatePromotions = (campaign) => {
  return (
    _.isEmpty(campaign.campaign_name) ||
    _.isEmpty(campaign.campaign_start_date) ||
    _.isEmpty(campaign.campaign_description) ||
    _.isEmpty(campaign.campaign_expiration_date) ||
    _.isEmpty(campaign.images)
  );
};

export const validateProduct = (campaign) => {
  let urlvalid = campaign.url;
  let error = true;

  if (urlvalid) {
    // var res = urlvalid.match('http' );
    let res = urlvalid.match("(http|https|Https|Http)://");
    //  var res = urlvalid.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    if (res == null) error = true;
    else error = false;
  }

  return (
    _.isEmpty(campaign.name) ||
    _.isEmpty(campaign.price) ||
    error ||
    _.isEmpty(campaign.description) ||
    _.isEmpty(campaign.code) ||
    _.isEmpty(campaign.images) ||
    _.isEmpty(campaign.category) ||
    _.isEmpty(campaign.subcategory) ||
    campaign.purchase_options?.length <= 0
  );
};

export const signUpNext = (business) => {
  return (
    _.isEmpty(business.client_name) ||
    _.isEmpty(business.category) ||
    _.isEmpty(business.about)
  );
};

export const validateProduct1 = (campaign) => {
  var urlvalids = campaign.url;
  let error = true;

  var foo = campaign.price;
  var bar = "" + foo;

  let value = false;

  if (urlvalids) {
    var res = urlvalids.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) error = true;
    else error = false;
  }

  return (
    _.isEmpty(campaign.url) ||
    _.isEmpty(campaign.name) ||
    error ||
    _.isEmpty(campaign.code) ||
    _.isEmpty(campaign.description) ||
    _.isEmpty(campaign.category) ||
    _.isArray(campaign.purchase_options) ||
    _.isArrayLikeObject(campaign.variants) ||
    _.isEmpty(bar)
  );
};

// Validate new coupon creation
export const validateCoupon = (coupon) => {
  const checks = {
    isCouponNameEmpty: _.isEmpty(coupon.coupon_name),
    isStartDateEmpty: _.isEmpty(coupon.start_date),
    isCouponExpirationEmpty: _.isEmpty(coupon.coupon_expiration),
    isCouponDescriptionEmpty: _.isEmpty(coupon.coupon_description),
    isBarcodeEmpty: _.isEmpty(coupon.barcode),
    isCategoryEmpty: _.isEmpty(coupon.category),
    isUseCountEmpty: _.isEmpty(coupon.use_count),
    isImagesEmpty: _.isEmpty(coupon.images),
    isClientEmpty: _.isEmpty(coupon.client),
    isCampaignEmpty: _.isEmpty(coupon.campaign),
    isDiscountInvalid:
      coupon.discount == null || // Check if discount is null or undefined
      !validator.isNumeric(coupon.discount.toString(), { no_symbols: true }) || // Check if discount is a valid number
      parseFloat(coupon.discount) <= 0, // Ensure discount is greater than zero
  };

  return (
    checks.isCouponNameEmpty ||
    checks.isStartDateEmpty ||
    checks.isCouponExpirationEmpty ||
    checks.isCouponDescriptionEmpty ||
    checks.isBarcodeEmpty ||
    checks.isCategoryEmpty ||
    checks.isUseCountEmpty ||
    checks.isImagesEmpty ||
    checks.isClientEmpty ||
    checks.isCampaignEmpty ||
    checks.isDiscountInvalid // Validate discount
  );
};

export const validatePromotionCoupon = (coupon) => {
  return (
    _.isEmpty(coupon.coupon_name) ||
    // _.isEmpty(coupon.start_date) ||
    //_.isEmpty(coupon.coupon_expiration) ||
    _.isEmpty(coupon.coupon_description) ||
    //    _.isEmpty(coupon.barcode) ||
    //  _.isEmpty(coupon.category) ||
    //_.isEmpty(coupon.use_count) ||
    _.isEmpty(coupon.image) ||
    _.isEmpty(coupon.client) ||
    _.isEmpty(coupon.campaign)
  );
};
