import { Grid } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChartSkeleton from "../../components/ChartSkeleton";
import DailyUsageChart from "../../components/Dashboard/DailyUsageChart";
import MonthlyUsageViewsChart from "../../components/Dashboard/MonthlyUsageViewsChart";
import WeeklyUsageChart from "../../components/Dashboard/WeeklyUsageChart";
import services from "../../services/index";
import { formatRevenue } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const Dashboard = ({ setPage }) => {
  const { client } = useSelector((state) => state.client);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [followers, setFollowers] = useState(0);
  const [activeChartName, setActiveChartName] = useState("");
  const [dashboardCharts, setDashboardCharts] = useState({});

  const handleClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      await services.UserServices.getDashboardAnalytics(client._id).then(
        (res) => {
          setActiveChartName("redemptions");
          setFollowers(res?.data?.data?.followers);
          setDashboardCharts(res?.data?.data);
          setLoading(false);
        }
      );
    })();
  }, [client]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChartChange = (chartName) => {
    setActiveChartName(chartName);
  };

  const chartDataSwitch = (name) => {
    switch (name) {
      case "redemptions":
        return dashboardCharts?.usage;
      case "views":
        return dashboardCharts?.views;
      default:
        return dashboardCharts?.usage;
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/" onClick={handleClick}>
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Dashboard</Typography>
          </Breadcrumbs>
        </div>
      </div>
      <Grid container spacing={2} className="my-3">
        <Grid
          item
          md={4}
          xs={12}
          className="box"
          style={{
            display: "grid",
            paddingTop: "0",
            paddingBottom: "0",
            cursor: "pointer",
          }}
          onClick={() => handleChartChange("redemptions")}
        >
          {!loading ? (
            <Card
              className={classes.root}
              style={{
                position: "relative",
                border:
                  activeChartName === "redemptions" ? "1px solid #000" : "none",
              }}
            >
              <CardHeader
                title="Redemptions"
                subheader="Total coupon redemptions"
              />
              <CardContent className="dashboard_card_content">
                <Typography
                  variant="h2"
                  color="textSecondary"
                  component="h2"
                  className="text-center"
                >
                  {formatRevenue(dashboardCharts?.usage?.length, 0)}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          className="box dashboard_card"
          style={{
            display: "grid",
            paddingTop: "0",
            paddingBottom: "0",
            cursor: "pointer",
          }}
          onClick={() => handleChartChange("views")}
        >
          {!loading ? (
            <Card
              className={classes.root}
              style={{
                position: "relative",
                border: activeChartName === "views" ? "1px solid #000" : "none",
              }}
            >
              <CardHeader
                color=""
                title="Views"
                subheader="Total profile views"
              />
              <CardContent className="dashboard_card_content">
                <Typography
                  variant="h2"
                  color="textSecondary"
                  component="h2"
                  className="text-center"
                >
                  {formatRevenue(dashboardCharts?.views?.length, 0)}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          className="box dashboard_card"
          style={{ paddingTop: "0", paddingBottom: "0", cursor: "pointer" }}
          onClick={() => setPage("followers")}
        >
          {!loading ? (
            <Card className={classes.root}>
              <CardHeader
                title="Followers"
                subheader="Total customers following your profile"
              />
              <CardContent>
                <Typography
                  variant="h2"
                  color="textSecondary"
                  component="h2"
                  className="text-center"
                >
                  {formatRevenue(followers, 0)}
                  {/* {followers} */}
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </Grid>
      </Grid>
      <div className="row mb-md-3 mb-0">
        <div className="col box">
          {!loading ? (
            <Card>
              <CardHeader
                title={`Daily ${activeChartName}`}
                subheader={`7-day rolling ${activeChartName} totals`}
              />
              <CardContent>
                <DailyUsageChart
                  data={chartDataSwitch(activeChartName)}
                  title={`Daily ${activeChartName}`}
                  height="200px"
                />
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          {!loading ? (
            <Card>
              <CardHeader
                title={`Weekly ${activeChartName}`}
                subheader={`4-week rolling ${activeChartName} totals`}
              />
              <CardContent>
                <WeeklyUsageChart
                  data={chartDataSwitch(activeChartName)}
                  title={`Weekly ${activeChartName}`}
                />
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </Grid>
        <Grid item lg={6} xs={12}>
          {!loading ? (
            <Card>
              <CardHeader
                title={`Monthly ${activeChartName}`}
                subheader={`3-month rolling ${activeChartName} totals`}
              />
              <CardContent>
                <MonthlyUsageViewsChart
                  data={chartDataSwitch(activeChartName)}
                  title={`Monthly ${activeChartName}`}
                  height="300px"
                />
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
