import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaymentsIcon from "@mui/icons-material/Payments";
import React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SidebarMenu = ({ page, setPage }) => {
  // const [page, setPage] = useState("home");

  const classes = useStyles();
  const [openMenu, setMenu] = React.useState(false);
  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const handleClick = () => {
    setMenu(!openMenu);
  };

  const history = useHistory();

  const logout = () => {
    notify("success", "Logout Successfully");
    localStorage.clear();
    history.push("/");
  };

  return (
    <List component="nav" className={classes.root}>
      {/* <ListItem
                button
                selected={page === 'Dashboard'}
                onClick={_ => setPage('Dashboard')}>
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem> */}
      <ListItem
        button
        selected={page === "ListOfBusiness"}
        onClick={(_) => setPage("ListOfBusiness")}
      >
        <ListItemIcon>
          <RecordVoiceOverIcon />
        </ListItemIcon>
        <ListItemText primary="List Of Business" />
      </ListItem>
      <ListItem
        button
        selected={page === "Revenue"}
        onClick={(_) => setPage("Revenue")}
      >
        <ListItemIcon>
          <CampaignIcon />
        </ListItemIcon>
        <ListItemText primary="Revenue" />
      </ListItem>
      {/* <ListItem
                button
                selected={page === 'DeleteBusiness'}
                onClick={_ => setPage('DeleteBusiness')}>
                <ListItemIcon>
                    <InventoryIcon />
                </ListItemIcon>
                <ListItemText primary="Delete Business" />
            </ListItem> */}
      <ListItem
        button
        selected={page === "bo_payout_history"}
        onClick={(_) => setPage("bo_payout_history")}
      >
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="Payout History" />
      </ListItem>
      <ListItem button onClick={(_) => logout()}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );
};

export default SidebarMenu;
