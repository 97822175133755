import { LoadingOutlined } from "@ant-design/icons";
import { Box, Grid } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../../config";
import { axiosConfig } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const RevenueBo = ({ setPage }) => {
  const classes = useStyles();

  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const [revenueData, setRevenueData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // Revenue get for BO
  const getRevenueData = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner/${LoginBOData._id}/get-revenue`,
        null,
        accessToken
      );
      setRevenueData(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRevenueData();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("ListOfBusiness")}
            >
              <HomeIcon />
            </Link>
            <Typography color="textPrimary">Total Revenue</Typography>
          </Breadcrumbs>
        </div>
      </div>

      {isLoading ? (
        <Box
          sx={{
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 12px",
            width: "100%",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 32,
                }}
                spin
              />
            }
          />
        </Box>
      ) : (
        <Grid container spacing={2} className="my-3">
          <Grid
            item
            md={4}
            xs={12}
            className="box"
            style={{ display: "grid", paddingTop: "0", paddingBottom: "0" }}
          >
            {/* {!loading ? ( */}
            <Card className={classes.root} style={{ position: "relative" }}>
              <CardHeader
                title="Daily Revenue"
                // subheader="Daily Revenue"
              />
              <CardContent className="dashboard_card_content">
                <Typography
                  variant="h2"
                  color="textSecondary"
                  component="h2"
                  className="text-center"
                >
                  ${revenueData?.daily}
                </Typography>
              </CardContent>
            </Card>
            {/* ) : (
            <ChartSkeleton />
          )} */}
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            className="box dashboard_card"
            style={{ display: "grid", paddingTop: "0", paddingBottom: "0" }}
          >
            {/* {!loading ? ( */}
            <Card className={classes.root} style={{ position: "relative" }}>
              <CardHeader
                color=""
                title="Weekly Revenue"
                // subheader="Weekly Revenue"
              />
              <CardContent className="dashboard_card_content">
                <Typography
                  variant="h2"
                  color="textSecondary"
                  component="h2"
                  className="text-center"
                >
                  ${revenueData?.weekly}
                </Typography>
              </CardContent>
            </Card>
            {/* ) : (
            <ChartSkeleton />
          )} */}
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            className="box dashboard_card"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            {/* {!loading ? ( */}
            <Card className={classes.root}>
              <CardHeader
                title="Monthly Revenue"
                // subheader="Monthly Revenue"
              />
              <CardContent>
                <Typography
                  variant="h2"
                  color="textSecondary"
                  component="h2"
                  className="text-center"
                >
                  ${revenueData?.monthly}
                </Typography>
              </CardContent>
            </Card>
            {/* ) : (
            <ChartSkeleton />
          )} */}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default RevenueBo;
