import { Button } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import config from "../../config";
import axios from "axios";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CheckoutForm = ({ successCallback }) => {
  const options = {
    hidePostalCode: true,
  };

  const stripe = useStripe();
  const elements = useElements();

  const [addCardLoading, setAddCardLoading] = useState(false);

  const LoginBOData = JSON.parse(localStorage.getItem("client"));

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    setAddCardLoading(true);

    const result = await stripe.createToken(cardElement);

    if (result.error) {
      console.log(result.error.message);
      setAddCardLoading(false);
    } else {
      const payload = {
        card: result?.token?.id,
      };

      await axios
        .post(
          `${config.apiUrl}/businessadmin/${LoginBOData?._id}/add-card`,
          payload
        )
        .then((res) => {
          if (res?.data?.success) {
            successCallback();
          }
        })
        .catch((err) => {
          console.error("add card error: ", err);
        })
        .finally(() => {
          setAddCardLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleAddPaymentMethod}>
      <div className="mb-3">
        <CardElement options={options} />
      </div>
      <Button type="submit" variant="outlined" className="d-block">
        {addCardLoading ? (
          <Spin indicator={<LoadingOutlined spin />} />
        ) : (
          "Submit"
        )}
      </Button>
    </form>
  );
};

export default CheckoutForm;
