// /* eslint-disable jsx-a11y/alt-text */
// /* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HomeIcon from "@material-ui/icons/Home";
import { Upload } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ChartSkeleton from "../../components/ChartSkeleton";
import services from "../../services/index";
import ProductUsageViewsChart from "./ProductUsageViewChart";
import { getFileDimensions } from "../../helper/getFileDimensions";
import { getS3FileDimensions } from "../../helper/getS3FileDimensions";
import { uploadFileOnS3 } from "../../helper/s3Uploader";
import { renameS3File } from "../../helper/renameS3File";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
  addonCategory,
  returnDaysOptionList,
  validatePrice,
} from "../../utils/utils";
import axios from "axios";
import config from "../../config";
import Select from "react-select";

toast.configure();

const couponImage = require("../../assets/images/logo-placeholder.png");

const ProductCouponDetails = ({ setPage }) => {
  const { client } = useSelector((state) => state);
  const { campaign, category } = client;
  const [img, setImg] = useState([]);
  const [usage, setUsage] = useState([]);
  const [views, setViews] = useState([]);
  const [editCoupon, setEditCoupon] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiFiles, setApiFiles] = useState([]);
  const [variantsList, setVariantsList] = useState([]);
  const [allVariantsList, setAllVariantsList] = useState([]);
  const [allNewMainCategoriesList, setAllNewMainCategoriesList] = useState([]);

  const [newUploadedFile, setNewUploadedFile] = useState([]);
  const [oldEditedFile, setOldEditedFile] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [validated, setValidated] = useState(false);
  const [taxCategories, setTaxCategories] = useState([]);
  const [addonCatErrors, setAddCatErrors] = useState({});

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const id = localStorage.getItem("producrid");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 48,
      minHeight: 48,
    }),
  };

  const getCoupon = async () => {
    await services.CampaignServices.getProduct(id)
      .then((res) => {
        localStorage.setItem("memoriesdata", JSON.stringify(res?.data?.data));
        setEditCoupon({ ...res?.data?.data });
        setApiFiles(res?.data?.data?.images);
        setFileList(res?.data?.data?.images.map((data) => data.images_url));
        setImg(res?.data?.data?.images);
        setUsage(res?.data?.usage);
        setViews(res?.data?.views);
        fetchCategories(res?.data?.data);
      })
      .catch((err) => console.log("getCouponn", err));
  };

  const fetchCategories = async (productItem) => {
    await axios
      .get(`${config.apiUrl}/category/get-category`)
      .then((res) => {
        if (res?.data?.data?.categoryList) {
          setAllCategories(res?.data?.data?.categoryList);
          const variantArr = res?.data?.data.categoryList.find(
            (i) => i?.category === productItem?.category
          )?.variantProperties;
          setVariantsList(variantArr);
        }
      })
      .catch((err) => {
        console.error("fetch categories error: ", err);
      });
  };

  const handleXclusitStoreChange = (e) => {
    const { checked, value } = e.target;
    setEditCoupon({
      ...editCoupon,
      // isXclusitStore: checked,
      isReturnable: null,
      returnDays: "",
      purchase_options: [value],
      color_options: [],
      category: "",
      subcategory: "",
      url: "",
      delivery_fees: "",
      shipping_days: "",
      taxCode: "",
    });
    setAllVariantsList([]);
    setAllNewMainCategoriesList([]);
  };

  function transformApiData(apiData) {
    console.log("apiData====>", apiData);
    const transformedData = [];

    apiData?.forEach((item) => {
      // Find existing entry with the same mainvariantname
      let existingEntry = transformedData.find(
        (entry) => entry.addon_category[0].value === item.mainvariantname
      );

      if (!existingEntry) {
        // If no existing entry is found, create a new one
        existingEntry = {
          addon_category: [
            {
              label: "Addon Category",
              name: "mainvariantname",
              value: item.mainvariantname || "",
              isRequired: true,
            },
            {
              label: "Addon Category Required",
              name: "mendatory",
              value: item.mendatory || false,
              propertyType: "checkbox",
              isRequired: false,
            },
            {
              label: "Is Paid",
              name: "ispaid",
              value: item.ispaid || false,
              propertyType: "checkbox",
              isRequired: false,
            },
            {
              label: "Multi choice",
              name: "ismultichoice",
              value: item.ismultichoice || false,
              propertyType: "checkbox",
              isRequired: false,
            },
            {
              label: "Select up to",
              name: "maxselection",
              value: item?.maxselection || "",
              propertyType: "number",
              isRequired: true,
            },
          ],
          addon_options: [],
        };
        transformedData.push(existingEntry);
      }

      // Group the variants by property
      const variant = item.variant.reduce((acc, variantItem) => {
        const variantObj = {
          _id: variantItem._id,
          property: variantItem.property,
          value: variantItem.value || "",
        };
        if (variantItem.property === "Price") {
          variantObj.propertyType = "number";
        }
        acc.push(variantObj);
        return acc;
      }, []);

      // Push the variant array to addon_options
      existingEntry.addon_options.push({ variant });
    });

    return transformedData;
  }

  const convertArrayStructure = () => {
    return allNewMainCategoriesList.flatMap((item) => {
      const newItems = [];
      let currentItem = {};

      const initializeNewItem = () => {
        currentItem = {};
        // Map addon_category fields to the newItem object
        item?.addon_category.forEach((category) => {
          currentItem[category.name] = category.value;
        });
        currentItem.variant = [];
      };

      initializeNewItem();

      item?.addon_options.forEach((option) => {
        let nameObj = null;
        let priceObj = null;

        option.variant.forEach(({ property, value }) => {
          if (property === "Name") {
            nameObj = { property, value };
          } else if (property === "Price") {
            priceObj = { property, value: parseFloat(value) || "" };
          }

          // If both Name and Price are present in current object, push the current item and start a new one
          if (nameObj && priceObj) {
            currentItem.variant.push(nameObj, priceObj);
            newItems.push(currentItem);
            initializeNewItem();
            nameObj = null;
            priceObj = null;
          }
        });
      });

      // Push the last constructed currentItem if it has variants
      if (currentItem.variant.length > 0) {
        newItems.push(currentItem);
      }

      return newItems;
    });
  };

  const handleEditPrefill = () => {
    setShowEdit(true);
    setSubCategories(
      category.filter((sub) => sub.category === editCoupon?.category)
    );
    setAllVariantsList(editCoupon?.variants);
    const variantsFormat = transformApiData(editCoupon?.variants);
    setAllNewMainCategoriesList(variantsFormat);
  };

  const handleProductEditChange = (e) => {
    const { name, value, required } = e.target;
    if (required && !value.trim()) {
      e.target.setCustomValidity(
        "This field cannot be blank or contain only spaces."
      );
    } else {
      e.target.setCustomValidity("");
    }
    setEditCoupon((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
      return;
    }

    if (Object.keys(addonCatErrors).length) {
      return;
    }

    setLoading(true);
    if (editCoupon?.url) {
      editCoupon.url = editCoupon.url.toLowerCase();
    }

    let dimensions = null;
    if (newUploadedFile?.length) {
      dimensions = await getFileDimensions(newUploadedFile[0]);
    } else if (apiFiles?.length === 0 && editCoupon?.images?.length) {
      dimensions = await getS3FileDimensions(editCoupon?.images[0]);
    }

    let renamedUrl = [];
    let newUrl = [];
    let oldUrl = img.map((file) => {
      return { images_url: file.images_url };
    });

    oldEditedFile &&
      (await renameS3File(oldEditedFile, dimensions, "product")
        .then((res) => {
          if (res?.status) {
            renamedUrl.push(...res?.data);
          } else {
            notify("error", "Something went to wrong.");
            setLoading(false);
            setErrorMessage("");
          }
        })
        .catch((error) => {
          notify("error", "Something went to wrong.");
          console.log("image upload error", error);
          setLoading(false);
          setErrorMessage("");
        }));

    newUploadedFile &&
      (await uploadFileOnS3(newUploadedFile, dimensions, "product")
        .then((res) => {
          res.forEach((item) => {
            newUrl.push({ images_url: item.Location });
          });
        })
        .catch((error) => {
          notify("error", "Something went to wrong.");
          console.log("image upload error", error);
          setLoading(false);
          setErrorMessage("");
        }));

    let files = [];

    if (oldEditedFile?.length === 0 && newUploadedFile?.length !== 0) {
      if (apiFiles?.length === 0) {
        files.push(...newUrl);
      } else {
        files.push(...oldUrl);
        files.push(...newUrl);
      }
      editCoupon.images = files;
    }
    if (oldEditedFile?.length !== 0 && newUploadedFile?.length !== 0) {
      files.push(...renamedUrl);
      files.push(...newUrl);
      editCoupon.images = files;
    }
    if (oldEditedFile?.length !== 0 && newUploadedFile?.length === 0) {
      files.push(...renamedUrl);
      editCoupon.images = files;
    }

    if (editCoupon?.category === "Food and Drink") {
      editCoupon.variants = convertArrayStructure() || [];
    } else {
      editCoupon.variants = allVariantsList || [];
    }

    services.CouponServices.updateProduct(id, editCoupon)
      .then((res) => {
        setPage("product-coupon-details");
        getCoupon();
        setNewUploadedFile([]);
        setOldEditedFile([]);
        if (res?.data?.err) {
          notify("error", res?.data?.message);
          setLoading(false);
          return;
        }
        notify("success", "Product updated successfully.");
        setShowEdit(false);
        setLoading(false);
      })
      .catch((err) => {
        notify("error", "Something went to wrong.");
        console.log("err", err);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    services.CouponServices.deleteProduct(id)
      .then((res) => {
        setPage("ProductCampaign");
        setShowDelete(false);
      })
      .catch((err) => console.log("Delete Product error: ", err));
  };

  useEffect(() => {
    getCoupon();
    setEditCoupon({
      ...editCoupon,
      _id: id,
    });
  }, []);

  let newCouponStartDate;
  if (moment(campaign.campaign_start_date).unix() > moment().unix()) {
    newCouponStartDate = moment(campaign.campaign_start_date).subtract(
      1,
      "days"
    );
  } else {
    newCouponStartDate = moment().subtract(1, "days");
  }

  const onCloseModal = () => {
    setShowEdit(false);
    setFileList(img);
    setLoading(false);
    setNewUploadedFile([]);
  };

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );

      const filteredImages = newUploadedFile.filter((item) => item !== file);
      setNewUploadedFile([...filteredImages]);
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      } else {
        setErrorMessage("");
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);
        setNewUploadedFile([...newUploadedFile, ...tempFile]);
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  const handlePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    if (isImage) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveNewFile = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
    const filteredImages = newUploadedFile.filter((item) => item !== file);
    setNewUploadedFile([...filteredImages]);
    setErrorMessage("");
  };

  const handleRemoveOldFile = (file) => {
    const filteredImages = apiFiles.filter((item) => item.id !== file.id);
    setApiFiles(filteredImages);
    setOldEditedFile(filteredImages);
  };

  useEffect(() => {
    if (newUploadedFile?.length + apiFiles?.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    } else {
      setErrorMessage("");
    }
  }, [newUploadedFile, apiFiles]);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;

    setEditCoupon({
      ...editCoupon,
      category: selectedCategory,
    });

    const filteredSubCategories = allCategories.filter(
      (sub) => sub.category === selectedCategory
    );
    setSubCategories(filteredSubCategories);

    const variantsArr =
      allCategories?.find((i) => i.category === selectedCategory)
        ?.variantProperties || [];

    setVariantsList([...variantsArr]);

    const filteredVariantsArr = variantsArr.reduce((acc, item) => {
      // if (item.property === "Name" || item.property === "Price") {
      //   const exists = acc.find((v) => v.property === item.property);
      //   if (!exists) {
      //     // acc.push({ ...item, value: item.property === "Price" ? 0 : "" });
      //     acc.push({ ...item, value: "" });
      //   }
      // } else {
      acc.push({ ...item, value: "" });
      // }
      return acc;
    }, []);

    const sortedVariantsArr = filteredVariantsArr.sort((a, b) => {
      // Sort to ensure 'Name' comes first, followed by 'Price'
      if (a.property === "Name") return -1;
      if (b.property === "Name") return 1;
      if (a.property === "Price") return -1;
      if (b.property === "Price") return 1;
      return 0;
    });

    const newMainCategory = {
      addon_category: addonCategory(),
      addon_options: [{ variant: sortedVariantsArr }],
    };

    console.log("sortedVariantsArr===>", sortedVariantsArr);

    if (e.target.value !== "") {
      if (e.target.value === "Food and Drink") {
        setAllVariantsList([{ variant: [...sortedVariantsArr] }]);
        setAllNewMainCategoriesList([newMainCategory]);
        return;
      } else {
        setAllVariantsList([
          {
            variant: [
              ...sortedVariantsArr,
              { property: "quantity", value: "" },
            ],
          },
        ]);
        // setAllNewMainCategoriesList([newMainCategory]);
        return;
      }
    }
    setAllVariantsList([]);
    setAllNewMainCategoriesList([]);
  };

  // Handle change for input fields
  const handleVariantChange = (e, index, innerIndex) => {
    const { value, required } = e.target;
    if (required && !value.trim()) {
      e.target.setCustomValidity(
        "This field cannot be blank or contain only spaces."
      );
    } else {
      e.target.setCustomValidity("");
    }
    setAllVariantsList((prevState) => {
      const updatedVariants = [...prevState];
      updatedVariants[index].variant[innerIndex].value = value;
      return updatedVariants;
    });
  };

  const handleAddVariant = () => {
    const newVariant = variantsList.map((variant) => ({
      property: variant?.property,
      value: "",
    }));

    setAllVariantsList((prevState) => [
      ...prevState,
      { variant: [...newVariant, { property: "quantity", value: "" }] },
    ]);
  };

  const handleRemoveVariant = (index) => {
    setAllVariantsList((prevState) =>
      prevState.filter((_, idx) => idx !== index)
    );
  };

  // const handlePurchaseOptionsChange = (event) => {
  //   const { name, checked, value } = event.target;
  //   setEditCoupon((prev) => ({
  //     ...prev,
  //     [name]: checked
  //       ? [...prev[name], value]
  //       : prev[name]?.filter((item) => item !== value),
  //   }));
  // };

  const handlePurchaseOptionsChange = (e) => {
    const { name, value } = e.target;
    setEditCoupon((prev) => ({
      ...prev,
      [name]: [value],
    }));
  };

  const fetchTaxCategories = () => {
    axios
      .get(`${config.apiUrl}/category/get-tax-category`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TAX_CATEGORY_TOKEN}`,
        },
      })
      .then((res) => {
        const options = res?.data?.data?.taxCategory?.map((i) => ({
          label: i.name,
          value: i.product_tax_code,
        }));
        setTaxCategories(options);
      })
      .catch((err) => {
        console.error("tax category res error: ", err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchTaxCategories();
  }, []);

  const handleAddonOptionChange = (
    mainIndex,
    optionIndex,
    variantIndex,
    field,
    value
  ) => {
    setAllNewMainCategoriesList((prev) => {
      const updatedCategories = [...prev];
      const option = updatedCategories[mainIndex]?.addon_options[optionIndex];
      const variant = option?.variant[variantIndex];

      if (variant) {
        // Determine the appropriate value based on the property type
        let newValue = value;

        if (variant.propertyType === "number") {
          newValue = value === "" ? "" : Number(value);
        } else if (value === "") {
          newValue = "";
        }

        // Directly update the field of the variant object
        updatedCategories[mainIndex].addon_options[optionIndex].variant[
          variantIndex
        ] = { ...variant, [field]: newValue };
      }

      return updatedCategories;
    });
  };

  const handleAddonCategoryChange = (
    mainIndex,
    mainCatItemIndex,
    key,
    value
  ) => {
    const updatedCategories = [...allNewMainCategoriesList];
    const selectedCategory =
      updatedCategories[mainIndex].addon_category[mainCatItemIndex];

    selectedCategory[key] = value; // Update the value (e.g., for isPaid, maxselection, etc.)

    // Check if the key is "maxselection"
    if (selectedCategory.name === "maxselection") {
      const maxSelection = Number(value); // Convert to number if it's not already
      const addonOptionsLength =
        updatedCategories[mainIndex].addon_options.length;

      // Validate if addon_options length is less than maxselection
      if (addonOptionsLength < maxSelection) {
        // Set error message for the maxselection field
        setAddCatErrors((prevErrors) => ({
          ...prevErrors,
          [`maxselection-${mainIndex}`]: `At least ${maxSelection} addon options are required.`,
        }));
      } else {
        // Remove the error if validation passes
        setAddCatErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[`maxselection-${mainIndex}`];
          return newErrors;
        });
      }
    }

    // Handle the logic for isPaid field toggle (optional based on your logic)
    if (selectedCategory.name === "ispaid" && value === false) {
      updatedCategories[mainIndex].addon_options = updatedCategories[
        mainIndex
      ].addon_options.map((option) => ({
        ...option,
        variant: option.variant.map((variantItem) => ({
          ...variantItem,
          value: variantItem.property === "Price" ? "" : variantItem.value,
        })),
      }));
    }

    setAllNewMainCategoriesList(updatedCategories); // Update the state with the modified categories
  };

  const handleRemoveMainCategory = (index) => {
    setAllNewMainCategoriesList((prev) =>
      prev.filter((_, idx) => idx !== index)
    );
  };
  const handleRemoveAddonOption = (categoryIndex, optionIndex) => {
    setAllNewMainCategoriesList((prev) => {
      const updatedCategories = [...prev];

      if (
        updatedCategories[categoryIndex] &&
        updatedCategories[categoryIndex].addon_options
      ) {
        updatedCategories[categoryIndex].addon_options = updatedCategories[
          categoryIndex
        ].addon_options.filter((_, idx) => idx !== optionIndex);

        // Validate maxselection after removing an option
        const maxSelection = updatedCategories[
          categoryIndex
        ].addon_category.find((cat) => cat.name === "maxselection")?.value;

        if (maxSelection) {
          const addonOptionsLength =
            updatedCategories[categoryIndex].addon_options.length;

          if (addonOptionsLength < maxSelection) {
            setAddCatErrors((prevErrors) => ({
              ...prevErrors,
              [`maxselection-${categoryIndex}`]: `At least ${maxSelection} addon options are required.`,
            }));
          } else {
            setAddCatErrors((prevErrors) => {
              const newErrors = { ...prevErrors };
              delete newErrors[`maxselection-${categoryIndex}`];
              return newErrors;
            });
          }
        }
      }

      return updatedCategories;
    });
  };

  const handleAddNewMainFoodCategory = () => {
    // Create a new variant array, initializing fields
    const newVariant = variantsList.map((variant) => ({
      ...variant,
      // value: variant.property === "Price" ? 0 : "", // Set Price to 0 and other values to an empty string
      value: "",
    }));

    // Sort the variants to ensure Name comes first, followed by Price
    const sortedNewVariant = newVariant.sort((a, b) => {
      if (a.property === "Name") return -1;
      if (b.property === "Name") return 1;
      if (a.property === "Price") return -1;
      if (b.property === "Price") return 1;
      return "";
    });

    // Create the new main category with the sorted variants
    const newMainCategory = {
      addon_category: addonCategory(),
      addon_options: [
        {
          variant: sortedNewVariant,
        },
      ],
    };

    // Update the state with the new main category
    setAllNewMainCategoriesList((prev) => [...prev, newMainCategory]);
  };

  const handleAddNewAddonOptions = (mainIndex) => {
    // Create a new variant array, ensuring initial fields are empty
    const newVariant = variantsList.map((variant) => ({
      ...variant,
      value: "",
    }));

    // Sort the variants to ensure Name comes first, followed by Price
    const sortedNewVariant = newVariant.sort((a, b) => {
      if (a.property === "Name") return -1;
      if (b.property === "Name") return 1;
      if (a.property === "Price") return -1;
      if (b.property === "Price") return 1;
      return 0;
    });

    // Create the new addon option with the sorted variants
    const newAddonOption = {
      variant: sortedNewVariant,
    };

    setAllNewMainCategoriesList((prev) => {
      const updatedCategories = [...prev];

      if (updatedCategories[mainIndex]) {
        updatedCategories[mainIndex].addon_options.push(newAddonOption);

        // Validate maxselection after adding a new option
        const maxSelection = updatedCategories[mainIndex].addon_category.find(
          (cat) => cat.name === "maxselection"
        )?.value;

        if (maxSelection) {
          const addonOptionsLength =
            updatedCategories[mainIndex].addon_options.length;

          if (addonOptionsLength < maxSelection) {
            setAddCatErrors((prevErrors) => ({
              ...prevErrors,
              [`maxselection-${mainIndex}`]: `At least ${maxSelection} addon options are required.`,
            }));
          } else {
            setAddCatErrors((prevErrors) => {
              const newErrors = { ...prevErrors };
              delete newErrors[`maxselection-${mainIndex}`];
              return newErrors;
            });
          }
        }
      }

      return updatedCategories;
    });
  };

  const variantRow = (mainIndex, index, innerIndex, item, isPaid) => {
    const isRequired = isPaid && item.property === "Price";
    const isNameRequired = item.property === "Name";
    return (
      <Col key={`${index}-${innerIndex}`}>
        {item?.propertyType === "Radio" ? (
          <Form.Group controlId={`variant-${index}-${innerIndex}`}>
            <Form.Label>{item.property}</Form.Label>
            <Form.Check
              inline
              label={item?.value ? "Yes" : "No"}
              name={`${item.property}-${index}-${innerIndex}`}
              style={{ height: 48 }}
              className="return-radios"
              checked={item.value}
              onChange={(e) => handleVariantChange(e, index, innerIndex)}
              type="checkbox"
              id="return"
              required={
                !!editCoupon?.purchase_options.find(
                  (i) => i === "xclusit_store"
                )
              }
            />
            <Form.Control.Feedback type="invalid">
              Please add product {item.property}!
            </Form.Control.Feedback>
          </Form.Group>
        ) : (
          <>
            {(isRequired || isNameRequired || item.property !== "Price") && (
              <Form.Group controlId={`variant-${index}-${innerIndex}`}>
                <Form.Label>{item.property}</Form.Label>
                <Form.Control
                  type={
                    item.property !== "quantity"
                      ? item?.propertyType === "number"
                        ? "number"
                        : "text"
                      : "number"
                  }
                  name={item.property}
                  style={{ height: 48 }}
                  value={item.value}
                  onChange={(e) =>
                    editCoupon?.category === "Food and Drink"
                      ? handleAddonOptionChange(
                          mainIndex,
                          index,
                          innerIndex,
                          "value",
                          e.target.value
                        )
                      : handleVariantChange(e, index, innerIndex)
                  }
                  min={editCoupon?.category === "Food and Drink" ? "0" : "1"}
                  step={item.property === "Price" ? "any" : undefined}
                  placeholder={item.property}
                  required={
                    isNameRequired || // Always required for "Name"
                    (editCoupon?.category === "Food and Drink"
                      ? isRequired
                      : !!editCoupon?.purchase_options.find(
                          (i) => i === "xclusit_store"
                        ))
                  }
                />
                <Form.Control.Feedback type="invalid">
                  Please add product {item.property}!
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </>
        )}
      </Col>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("ProductCampaign")}
            >
              <Typography color="primary">Products</Typography>
            </Link>
            <span color="inherit">
              <Typography color="primary">Product Details</Typography>
            </span>
          </Breadcrumbs>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          {client?.coupon ? (
            <Card>
              <CardHeader
                title="Product Details"
                subheader="Product Details"
                action={
                  <>
                    <IconButton
                      aria-label="edit campaign"
                      title="Edit Product"
                      onClick={handleEditPrefill}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete campaign"
                      title="Delete Product"
                      onClick={() => setShowDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              />
              <CardContent>
                <div className="row">
                  <div className="col-12 col-lg-3">
                    {img ? (
                      img[0]?.fileType === "video" ? (
                        <video
                          src={img[0]?.images_url}
                          width="auto"
                          style={{ width: "auto", height: "120px" }}
                          controls
                        ></video>
                      ) : (
                        <img
                          src={img[0]?.images_url || couponImage}
                          alt={img[0]?.images_url || couponImage}
                          loading="lazy"
                          style={{ width: "auto", height: "120px" }}
                        />
                      )
                    ) : (
                      <img
                        src={couponImage}
                        alt="prev"
                        style={{ width: "auto", height: "120px" }}
                      />
                    )}
                  </div>
                  <div className="col-12 col-lg-9">
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-4 mt-lg-0 mt-3">
                        <h6>
                          <u>Product Name</u>
                        </h6>
                        <p>{editCoupon?.name || "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4 mt-lg-0 mt-md-3 mt-0">
                        <h6>
                          <u>Product Description</u>
                        </h6>
                        <p>{editCoupon?.description || "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <h6>
                          <u>Product Price</u>
                        </h6>
                        <p>{`$${editCoupon?.price}` || "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <h6>
                          <u>Category - Subcategory</u>
                        </h6>
                        <p>
                          {editCoupon?.category || "N/A"} -{" "}
                          {editCoupon?.subcategory || "no subcategory"}
                        </p>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <h6>
                          <u>Product Code</u>
                        </h6>
                        <p>{editCoupon?.code || "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <h6>
                          <u>Product Url</u>
                        </h6>
                        <p style={{ wordBreak: "break-all" }}>
                          {editCoupon?.url || "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <Card>
            <CardHeader
              title="Usage and  Views"
              subheader="Daily trend for this Product."
            />
            <CardContent>
              <ProductUsageViewsChart
                data={{ coupon: client?.coupon, usage, views }}
                height="300px"
              />
            </CardContent>
          </Card>
        </div>
      </div>
      <Modal
        centered
        show={showEdit}
        onHide={() => onCloseModal()}
        size="lg"
        className="edit_product_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>EDIT A PRODUCT</Modal.Title>
        </Modal.Header>
        <div>
          <Form
            id="couponForm"
            encType="multipart/form-data"
            noValidate
            validated={validated}
            onSubmit={handleEdit}
          >
            <div className="modal-body row">
              <div className="col-lg-6 col-12">
                <Form.Group>
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    placeholder="Product Name"
                    style={{ height: "48px" }}
                    name="name"
                    type="text"
                    // defaultValue={editCoupon.name || name}
                    value={editCoupon?.name}
                    // onChange={(event) =>
                    //   setEditCoupon({
                    //     ...editCoupon,
                    //     name: event.target.value,
                    //   })
                    // }
                    onChange={handleProductEditChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the product name!
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Group>
                  <Form.Label>Product Description</Form.Label>
                  <textarea
                    name="description"
                    // onChange={(event) =>
                    //   setEditCoupon({
                    //     ...editCoupon,
                    //     description: event.target.value,
                    //   })
                    // }
                    onChange={handleProductEditChange}
                    className="form-control"
                    rows="3"
                    // defaultValue={editCoupon.description || dec}
                    value={editCoupon?.description}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the product description!
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-lg-7 col-12">
                <Form.Group>
                  <Form.Label>Product Code</Form.Label>
                  <Form.Control
                    placeholder="Product Code"
                    type="text"
                    style={{ height: 48 }}
                    // defaultValue={editCoupon.code || codes}
                    name="code"
                    value={editCoupon?.code}
                    // onChange={(event) =>
                    //   setEditCoupon({ ...editCoupon, code: event.target.value })
                    // }
                    onChange={handleProductEditChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the product Code!
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-lg-5 col-12 ">
                <Form.Group>
                  <Form.Label>Product Price</Form.Label>
                  <Form.Control
                    type="text"
                    style={{ height: 48 }}
                    className={`${
                      validated && !validatePrice.test(editCoupon?.price)
                        ? "border-danger"
                        : ""
                    }`}
                    placeholder="Product Price"
                    // defaultValue={editCoupon.price || price1}
                    name="price"
                    value={editCoupon?.price}
                    // onChange={(event) => {
                    //   const { value } = event.target;
                    //   setEditCoupon({
                    //     ...editCoupon,
                    //     price: value,
                    //   });
                    // }}
                    onChange={handleProductEditChange}
                    required
                    isInvalid={
                      validated && !validatePrice.test(editCoupon?.price)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter the product price!
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12">
                <div className="d-flex align-items-center">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="purchase_options"
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="Xclusit Store"
                      onChange={handleXclusitStoreChange}
                      value="xclusit_store"
                      checked={editCoupon?.purchase_options?.includes(
                        "xclusit_store"
                      )}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Make reservations"
                      onChange={handlePurchaseOptionsChange}
                      value="make_reservations"
                      // name="purchase_options"
                      checked={editCoupon?.purchase_options?.includes(
                        "make_reservations"
                      )}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Book appointments"
                      onChange={handlePurchaseOptionsChange}
                      value="book_appointments"
                      // name="purchase_options"
                      checked={editCoupon?.purchase_options?.includes(
                        "book_appointments"
                      )}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="Shop online"
                      onChange={handlePurchaseOptionsChange}
                      value="buy_now"
                      // name="purchase_options"
                      checked={editCoupon?.purchase_options?.includes(
                        "buy_now"
                      )}
                    />
                  </RadioGroup>
                </div>
              </div>

              <div className="col-lg-6 col-12" controlId="category">
                <Form.Group>
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ height: 48 }}
                    value={editCoupon?.category}
                    onChange={handleCategoryChange}
                    required
                  >
                    <option>Choose a category</option>
                    {category?.map((cat, index) => (
                      <option key={index} value={cat.category}>
                        {cat.category}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter the product category!
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-lg-6 col-12" controlId="subcategory">
                <Form.Group>
                  <Form.Label>Sub-Category</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ height: 48 }}
                    name="subcategory"
                    value={editCoupon?.subcategory}
                    // onChange={(event) =>
                    //   setEditCoupon({
                    //     ...editCoupon,
                    //     subcategory: event.target.value,
                    //   })
                    // }
                    onChange={handleProductEditChange}
                  >
                    {
                      <option value="" selected>
                        Choose a category first
                      </option>
                    }
                    {!_.isEmpty(editCoupon.category) &&
                      _.isEmpty(subCategories?.[0]?.subcategory) && (
                        <option value="">There are no subcategories</option>
                      )}
                    {subCategories[0]?.subcategory.map((subcategory, index) => (
                      <option key={index} value={subcategory}>
                        {subcategory}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter the product sub category!
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {editCoupon?.purchase_options?.find(
                (i) => i === "xclusit_store"
              ) ? (
                <>
                  {editCoupon?.category !== "Food and Drink" ? (
                    <div className="col-12">
                      <Form.Group>
                        <Form.Label>Return Options</Form.Label>
                        <Form.Check
                          inline
                          label="Return"
                          name="isReturnable"
                          value={true}
                          className="return-radios"
                          onChange={(event) => {
                            setEditCoupon({
                              ...editCoupon,
                              isReturnable:
                                event.target.value === "true" ? true : false,
                            });
                          }}
                          type="radio"
                          id="return"
                          checked={editCoupon.isReturnable}
                          required={
                            !!editCoupon?.purchase_options?.find(
                              (i) => i === "xclusit_store"
                            ) && editCoupon?.category !== "Food and Drink"
                          }
                        />
                        <Form.Check
                          inline
                          label="Non Return"
                          name="isReturnable"
                          value={false}
                          className="return-radios"
                          onChange={(event) => {
                            setEditCoupon({
                              ...editCoupon,
                              isReturnable:
                                event.target.value === "true" ? true : false,
                              returnDays: "",
                            });
                          }}
                          type="radio"
                          id="non_return"
                          checked={!editCoupon.isReturnable}
                          required={
                            !!editCoupon?.purchase_options?.find(
                              (i) => i === "xclusit_store"
                            ) && editCoupon?.category !== "Food and Drink"
                          }
                        />
                      </Form.Group>
                    </div>
                  ) : null}

                  {editCoupon.isReturnable ? (
                    <div className="col-12" controlId="returnDays">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <Form.Group>
                            <Form.Label>Product Return days</Form.Label>
                            <Form.Control
                              as="select"
                              style={{ height: 48 }}
                              name="returnDays"
                              value={editCoupon.returnDays}
                              // onChange={(event) => {
                              //   setEditCoupon({
                              //     ...editCoupon,
                              //     returnDays: event.target.value,
                              //   });
                              // }}
                              onChange={handleProductEditChange}
                            >
                              <option value="">Choose days</option>
                              {returnDaysOptionList?.map((cat, index) => (
                                <option key={index} value={cat.value}>
                                  {cat.label}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter the product return days!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                    <Form.Group>
                      <Form.Label>Tax category</Form.Label>
                      <Select
                        name="taxCode"
                        options={taxCategories}
                        value={taxCategories?.find(
                          (i) => i?.value === editCoupon?.taxCode
                        )}
                        onChange={(e) => {
                          setEditCoupon({
                            ...editCoupon,
                            taxCode: e.value,
                          });
                        }}
                        styles={customStyles}
                        required={
                          !!editCoupon?.purchase_options?.find(
                            (i) => i === "xclusit_store"
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the product tax category!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-md-6 col-12 mt-3 mt-lg-0">
                    <Form.Group>
                      <Form.Label>Delivery Fees</Form.Label>
                      <Form.Control
                        type="text"
                        style={{ height: 48 }}
                        className={`${
                          validated &&
                          !validatePrice.test(editCoupon?.delivery_fees)
                            ? "border-danger"
                            : ""
                        }`}
                        placeholder="Delivery Fees"
                        name="delivery_fees"
                        value={editCoupon?.delivery_fees || ""}
                        // onChange={(event) => {
                        //   const { value } = event.target;
                        //   setEditCoupon({
                        //     ...editCoupon,
                        //     delivery_fees: value,
                        //   });
                        // }}
                        onChange={handleProductEditChange}
                        required={
                          !!editCoupon?.purchase_options?.find(
                            (i) => i === "xclusit_store"
                          )
                        }
                        isInvalid={
                          validated &&
                          !validatePrice.test(editCoupon?.delivery_fees)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the delivery fees!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  {editCoupon?.category !== "Food and Drink" ? (
                    <div className="col-md-6 col-12 mt-3 mt-lg-0">
                      <Form.Group>
                        <Form.Label>Shipping Days</Form.Label>
                        <Form.Control
                          type="text"
                          style={{ height: 48 }}
                          placeholder="Shipping Days"
                          name="shipping_days"
                          value={editCoupon?.shipping_days || ""}
                          onChange={handleProductEditChange}
                          required={
                            !!editCoupon?.purchase_options?.find(
                              (i) => i === "xclusit_store"
                            ) && editCoupon?.category !== "Food and Drink"
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter the shipping days!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  ) : null}
                  {editCoupon?.category !== "Food and Drink" ? (
                    <>
                      {allVariantsList?.map((variantObj, index) => (
                        <div key={index} className="col-12">
                          <div className="row align-items-center">
                            {variantObj.variant?.map((item, innerIndex) =>
                              variantRow("", index, innerIndex, item)
                            )}
                            <Col md="auto">
                              {index !== 0 && (
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleRemoveVariant(index)}
                                  className="p-2"
                                  style={{ minWidth: "fit-content" }}
                                >
                                  <DeleteIcon />
                                </Button>
                              )}
                            </Col>
                          </div>
                        </div>
                      ))}

                      {allVariantsList?.length ? (
                        <div className="col-12">
                          <div className="d-flex justify-content-end">
                            <Button
                              variant="outlined"
                              onClick={handleAddVariant}
                            >
                              Add Variant
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    allNewMainCategoriesList?.map((mainItem, mainIndex) => (
                      <div key={mainIndex} className="col-12">
                        <div className="row">
                          {mainItem?.addon_category?.map(
                            (mainCatItem, mainCatItemIndex) => {
                              const isMultiChoice =
                                mainItem?.addon_category?.find(
                                  (cat) => cat.name === "ismultichoice"
                                )?.value;
                              const isAddonCatRequired =
                                mainCatItem.name === "mainvariantname";
                              return (
                                <div
                                  // className="col-md-4 col-12 mt-3 mt-lg-0"
                                  className="col mt-3 mt-lg-0"
                                  key={mainCatItemIndex}
                                >
                                  {mainCatItem?.propertyType === "checkbox" ? (
                                    <Form.Group>
                                      <Form.Label>
                                        {mainCatItem?.label}
                                      </Form.Label>
                                      <Form.Check
                                        inline
                                        label={"Yes"}
                                        name={`radio-group-${mainIndex}-${mainCatItemIndex}`}
                                        className="return-radios"
                                        checked={mainCatItem?.value}
                                        onChange={(e) =>
                                          handleAddonCategoryChange(
                                            mainIndex,
                                            mainCatItemIndex,
                                            "value",
                                            e.target.checked
                                          )
                                        }
                                        type="checkbox"
                                        id={`radio-group-${mainIndex}-${mainCatItemIndex}`}
                                        required={
                                          !!editCoupon?.purchase_options.find(
                                            (i) => i === "xclusit_store"
                                          ) && mainCatItem?.isRequired
                                        }
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        Please enter the {mainCatItem?.label}!
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  ) : (
                                    (mainCatItem?.name !== "maxselection" ||
                                      isMultiChoice) && (
                                      <Form.Group>
                                        <Form.Label>
                                          {mainCatItem?.label}
                                        </Form.Label>
                                        <Form.Control
                                          type={mainCatItem?.propertyType}
                                          min={
                                            mainCatItem?.propertyType ===
                                            "number"
                                              ? "1"
                                              : "0"
                                          }
                                          style={{ height: 48 }}
                                          placeholder={mainCatItem?.label}
                                          name={mainCatItem?.name}
                                          value={mainCatItem?.value || ""}
                                          className={`${
                                            mainCatItem?.name === "maxselection"
                                              ? !!addonCatErrors[
                                                  `maxselection-${mainIndex}`
                                                ]
                                                ? "border-danger"
                                                : ""
                                              : ""
                                          }`}
                                          onChange={(e) =>
                                            handleAddonCategoryChange(
                                              mainIndex,
                                              mainCatItemIndex,
                                              "value",
                                              e.target.value
                                            )
                                          }
                                          required={
                                            (editCoupon?.category ===
                                              "Food and Drink" &&
                                              mainCatItem?.isRequired &&
                                              isMultiChoice) ||
                                            isAddonCatRequired
                                          }
                                          isInvalid={
                                            mainCatItem?.name === "maxselection"
                                              ? !!addonCatErrors[
                                                  `maxselection-${mainIndex}`
                                                ]
                                              : false
                                          }
                                        />
                                         <Form.Control.Feedback type="invalid">
                                          {mainCatItem?.name === "maxselection"
                                            ? mainCatItem?.value == 0 ||
                                              mainCatItem?.value === ""
                                              ? `Please enter atleast 1 value`
                                              : addonCatErrors[
                                                  `maxselection-${mainIndex}`
                                                ]
                                            : `Please enter the ${mainCatItem?.label}!`}
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    )
                                  )}
                                </div>
                              );
                            }
                          )}
                          <Col md="auto" className="align-self-center">
                            {mainIndex !== 0 && (
                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() =>
                                  handleRemoveMainCategory(mainIndex)
                                }
                                className="p-2"
                                style={{ minWidth: "fit-content" }}
                              >
                                <DeleteIcon />
                              </Button>
                            )}
                          </Col>
                        </div>

                        {mainItem?.addon_options?.map(
                          (subCatItem, subCatIndex) => (
                            <div key={subCatIndex} className="row">
                              <React.Fragment>
                                {subCatItem?.variant.map(
                                  (varItem, varIndex) => (
                                    <React.Fragment
                                      key={`${subCatIndex}-${varIndex}`}
                                    >
                                      {variantRow(
                                        mainIndex,
                                        subCatIndex,
                                        varIndex,
                                        varItem,
                                        mainItem.addon_category.find(
                                          (cat) => cat.name === "ispaid"
                                        )?.value
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                                <Col md="auto" className="align-self-center">
                                  {subCatIndex !== 0 && (
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      onClick={() =>
                                        handleRemoveAddonOption(
                                          mainIndex,
                                          subCatIndex
                                        )
                                      }
                                      className="p-2"
                                      style={{ minWidth: "fit-content" }}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  )}
                                </Col>
                              </React.Fragment>
                            </div>
                          )
                        )}

                        <Button
                          variant="outlined"
                          className="mb-3"
                          onClick={() => handleAddNewAddonOptions(mainIndex)}
                        >
                          Add Addon Opt.
                        </Button>
                      </div>
                    ))
                  )}
                  {editCoupon?.category === "Food and Drink" ? (
                    <div className="col-12">
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="outlined"
                          className="mr-2"
                          onClick={handleAddNewMainFoodCategory}
                        >
                          Add Addon Cat.
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="col-12">
                  <Form.Group>
                    <Form.Label>Product URL</Form.Label>
                    <Form.Control
                      type="url"
                      placeholder="https://example.com"
                      style={{ height: "48px" }}
                      // defaultValue={editCoupon.url || ProductUrl}
                      pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                      name="url"
                      value={editCoupon?.url}
                      // onChange={(event) =>
                      //   setEditCoupon({
                      //     ...editCoupon,
                      //     url: event.target.value,
                      //   })
                      // }
                      onChange={handleProductEditChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter the product url!
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              )}

              <div className="col-12">
                <Form.Group>
                  <Form.Label>Product Image / Video (50 MB max.)</Form.Label>
                  <Upload
                    {...propsUploadFile}
                    onPreview={handlePreview}
                    accept="image/*,video/*"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        className="choose_file_button"
                        onClick={() => setErrorMessage("")}
                      >
                        Choose file
                      </Button>
                    </div>
                  </Upload>
                  {errorMessage && (
                    <div className="mt-1 text-danger">{errorMessage}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-12">
                <Form.Label>Product Preview</Form.Label>
                {apiFiles?.length === 0 && newUploadedFile?.length === 0 && (
                  <img
                    src={couponImage}
                    alt="Coupon"
                    width="150px"
                    height="100px"
                  />
                )}
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {apiFiles?.map((file, fileIndex) => {
                    const { images_url, fileType } = file;
                    const isImage = fileType === "image";
                    const isVideo = fileType === "video";

                    return (
                      <div key={fileIndex}>
                        {isImage && (
                          <div className="position-relative d-inline-block">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveOldFile(file)}
                            >
                              {" "}
                              X
                            </div>
                            <img
                              src={images_url}
                              alt={file?.name}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                        {isVideo && (
                          <div className="position-relative d-inline-block">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveOldFile(file)}
                            >
                              X
                            </div>
                            <video
                              controls
                              src={images_url}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {newUploadedFile?.length !== 0 &&
                    newUploadedFile.map((file) => {
                      return (
                        <>
                          {file.type.startsWith("image/") ? (
                            <div className="position-relative">
                              <div
                                className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveNewFile(file)}
                              >
                                X
                              </div>
                              <img
                                src={URL?.createObjectURL(file)}
                                alt={file?.name}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          ) : (
                            <div className="position-relative">
                              <div
                                className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveNewFile(file)}
                              >
                                X
                              </div>
                              <video
                                controls
                                src={URL?.createObjectURL(file)}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="submit"
                variant="contained"
                // disabled={
                //   validateProduct1(editCoupon) ||
                //   loading ||
                //   (editCoupon?.images?.length === 0 &&
                //     newUploadedFile?.length === 0) ||
                //   errorMessage
                // }
                // onClick={handleEdit}
              >
                {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"
                  />
                )}
                Update Product
              </Button>
              <Button
                type="button"
                variant="outlined"
                onClick={() => onCloseModal()}
                style={{ marginLeft: "20px" }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        centered
        show={showDelete}
        onHide={() => setShowDelete(false)}
        size="md"
      >
        <Modal.Header closeButton variant="danger">
          <Modal.Title>DELETE PRODUCT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>"{editCoupon?.name}"</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDelete()}
            className="mr-2"
          >
            Yes
          </Button>
          <Button variant="contained" onClick={() => setShowDelete(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductCouponDetails;
