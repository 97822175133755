import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SidebarMenu = ({ page, setPage }) => {
  // const [page, setPage] = useState("home");

  const classes = useStyles();
  const [openMenu, setMenu] = React.useState(false);
  const notify = (type, text) => toast[type](text, {
    position: toast.POSITION.TOP_RIGHT
  });

  const handleClick = () => {
    setMenu(!openMenu);
  };

  const history = useHistory();

  const logout = () => {
    notify('success', 'Logout Successfully');
    localStorage.clear();
    history.push('/');
  }

  return (
    <List
      component="nav"
      className={classes.root}
    >
      <ListItem
        button
        selected={page === 'Dashboard'}
        onClick={_ => setPage('Dashboard')}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      {/* <ListItem
        button
        selected={page === 'ListOfBusiness'}
        onClick={_ => setPage('ListOfBusiness')}>
        <ListItemIcon>
          <RecordVoiceOverIcon />
        </ListItemIcon>
        <ListItemText primary="List Of Business" />
      </ListItem> */}
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <DescriptionIcon />
        </ListItemIcon>
        <ListItemText primary="List of Business" />
        {openMenu ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nested}
            selected={page === 'ListOfBusinessAdmin'}
            onClick={_ => setPage('ListOfBusinessAdmin')}>
            <ListItemIcon>
              <AvTimerIcon />
            </ListItemIcon>
            <ListItemText primary="Business Admin" />
          </ListItem>
          <ListItem
            button
            className={classes.nested}
            selected={page === 'ListOfBusinessOwner'}
            onClick={_ => setPage('ListOfBusinessOwner')}>
            <ListItemIcon>
              <AvTimerIcon />
            </ListItemIcon>
            <ListItemText primary="Business Owner" />
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        button
        selected={page === 'Plans'}
        onClick={_ => setPage('Plans')}>
        <ListItemIcon>
          <ViewCarouselIcon />
        </ListItemIcon>
        <ListItemText primary="Plans" />
      </ListItem>
      <ListItem
        button
        onClick={_ => logout()}>
        <ListItemIcon>
          <LockOpenIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
    </List>
  );
}

export default SidebarMenu;
