import React, { useEffect, useState } from "react";
import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { FormControl, MenuItem, Select } from "@mui/material";
import ChartSkeleton from "../ChartSkeleton";
import { useSelector } from "react-redux";
import { deliveryAddressFormat, orderStatus } from "../../utils/utils";
import axios from "axios";
import config from "../../config";

const OrderDetails = ({ setPage }) => {
  const { client } = useSelector((state) => state);
  const orderData = useSelector((state) => state?.client?.order);

  const [status, setStatus] = useState("");

  const placeholderImage = require("../../assets/images/logo-placeholder.png");

  const orderStatusList = orderStatus.reduce((acc, status) => {
    acc[status.value] = status;
    return acc;
  }, {});

  useEffect(() => {
    setStatus(orderData?.status);
  }, [orderData]);

  const updateOrderStatus = async (orderId, newStatus) => {
    const payload = { status: newStatus };
    await axios
      .patch(
        `${config.apiUrl}/businessadmin/${orderId}/update-order-status`,
        payload
      )
      .then((res) => {})
      .catch((err) => console.error("order update status error: ", err));
  };

  const handleOrderStatusChange = (e, orderId) => {
    const newStatus = e.target.value;
    if (newStatus !== "") {
      setStatus(newStatus);
      updateOrderStatus(orderId, newStatus);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("dashboard")}
            >
              <HomeIcon />
            </Link>
            <Link
              color="inherit"
              href="javascript:;"
              onClick={() => setPage("Orders")}
            >
              <Typography color="primary">Orders</Typography>
            </Link>
            <span color="inherit">
              <Typography color="primary">Orders Details</Typography>
            </span>
          </Breadcrumbs>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          {client?.coupon ? (
            <Card>
              <CardHeader
                title="Order Details"
                // subheader="Order Details"
                className="pb-0"
                action={
                  <>
                    {/* <IconButton
                      aria-label="edit order status"
                      title="Edit Order Status"
                      // onClick={handleEditPrefill}
                    >
                      <EditIcon />
                    </IconButton> */}
                    {/* <IconButton
                      aria-label="delete campaign"
                      title="Delete Product"
                      onClick={() => setShowDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton> */}
                  </>
                }
              />
              <CardContent>
                <div className="row">
                  <div className="col-12 col-md-8">
                    <div className="card mb-3">
                      <div className="p-3 row">
                        <div className="col-12 col-md-3">
                          {orderData?.productId?.images ? (
                            orderData?.productId?.images[0]?.fileType ===
                            "video" ? (
                              <video
                                src={
                                  orderData?.productId?.images[0]?.images_url
                                }
                                width="auto"
                                style={{ width: "auto", height: "130px" }}
                                className=""
                                controls
                              ></video>
                            ) : (
                              <img
                                src={
                                  orderData?.productId?.images[0]?.images_url ||
                                  placeholderImage
                                }
                                alt={
                                  orderData?.productId?.images[0]?.images_url ||
                                  placeholderImage
                                }
                                loading="lazy"
                                style={{ width: "auto", height: "130px" }}
                                className="rounded-lg"
                              />
                            )
                          ) : (
                            <img
                              src={placeholderImage}
                              alt="prev"
                              style={{ width: "auto", height: "130px" }}
                              className="rounded-lg"
                            />
                          )}
                        </div>
                        <div className="col-12 col-md-9">
                          <div className="d-flex align-items-center justify-content-end">
                            <div>
                              <FormControl
                                // variant="standard"
                                fullWidth
                                size="small"
                              >
                                {/* <InputLabel variant="standard" htmlFor="status">
                          Status
                        </InputLabel> */}
                                <Select
                                  value={status || orderData?.status || ""}
                                  onChange={(e) =>
                                    handleOrderStatusChange(e, orderData._id)
                                  }
                                  inputProps={{
                                    name: "status",
                                    id: "status",
                                  }}
                                  // renderValue={(selected) => {
                                  //   const selectedStatus =
                                  //     orderStatusList[selected] || {};
                                  //   return (
                                  //     <Chip
                                  //       label={selected}
                                  //       size="small"
                                  //       color={
                                  //         selectedStatus.color || "primary"
                                  //       }
                                  //     />
                                  //   );
                                  // }}
                                >
                                  <MenuItem value="">
                                    <em>select status</em>
                                  </MenuItem>
                                  {orderStatus.map((i, index) => (
                                    <MenuItem key={index} value={i.value}>
                                      {i.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div>
                            <h3
                              style={{ fontSize: "26px" }}
                              className="font-weight-bold mb-0"
                            >
                              {orderData?.productId?.name || "N/A"}
                            </h3>
                            <p className="text-muted mb-0">
                              {orderData?.productId?.description || "N/A"}
                            </p>
                            <h3
                              // style={{ fontSize: "22px" }}
                              className="font-weight-bold p-0"
                            >
                              {`$${(
                                parseFloat(orderData?.amount) +
                                  parseFloat(orderData?.tax) || 0
                              ).toFixed(2)}` || "N/A"}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header font-weight-bold px-2">
                        Order Variants
                      </div>
                      <div className="row card-body">
                        {orderData?.productId?.variants ? (
                          orderData?.productId?.variants.filter((i) =>
                            orderData?.variantId.includes(i?._id)
                          ).length ? (
                            orderData?.productId?.variants
                              .filter((i) =>
                                orderData?.variantId.includes(i?._id)
                              )
                              .map((variant, variantIdx) => {
                                return (
                                  <div
                                    key={variantIdx}
                                    className="col-12 col-md-6"
                                  >
                                    {orderData?.productId?.category ===
                                    "Food and Drink" ? (
                                      <div className="mb-2">
                                        <strong className="">
                                          {variant?.mainvariantname}
                                        </strong>
                                        <div className="d-flex align-items-center justify-content-between">
                                          {variant?.variant?.map(
                                            (inVariant, inVariantIdx) => (
                                              <div key={inVariantIdx}>
                                                <p className="mb-0">
                                                  {typeof inVariant?.value ===
                                                  "number"
                                                    ? "$"
                                                    : ""}
                                                  {inVariant?.value !== ""
                                                    ? inVariant?.value
                                                    : `$0`}
                                                </p>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {variant?.variant
                                          ?.filter(
                                            (inVariant) =>
                                              inVariant?.property !== "quantity"
                                          )
                                          .map((inVariant, inVariantIdx) => (
                                            <div
                                              key={inVariantIdx}
                                              className="d-flex align-items-center justify-content-between"
                                            >
                                              <p className="">
                                                {inVariant?.property ?? "N/A"}
                                              </p>
                                              <p className="">
                                                {inVariant?.value !== ""
                                                  ? inVariant?.value
                                                  : `N/A`}
                                              </p>
                                            </div>
                                          ))}
                                      </>
                                    )}
                                  </div>
                                );
                              })
                          ) : (
                            <div className="">No Data Found</div>
                          )
                        ) : (
                          "N/A"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="card mb-3">
                      <div className="card-header font-weight-bold px-2">
                        Order details
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Order Code</p>
                            <p className="mb-0 text-muted">
                              {orderData?.productId?.code || "N/A"}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Category</p>
                            <p className="mb-0 text-muted">
                              {orderData?.productId?.category || "N/A"}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Subcategory</p>
                            <p className="mb-0 text-muted">
                              {orderData?.productId?.subcategory ||
                                "no subcategory"}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Quantity</p>
                            <p className="mb-0 text-muted">
                              {orderData?.quantity || 0}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Order Return</p>
                            <p className="mb-0 text-muted">
                              {orderData?.productId?.isReturnable
                                ? "Yes"
                                : "No"}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Order Return Days</p>
                            <p className="mb-0 text-muted">
                              {orderData?.productId?.returnDays || "N/A"}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="">
                            <p className="mb-0">Special Instruction: </p>
                            <p className="mb-0 text-muted">
                              {orderData?.specialInstructions
                                ? orderData?.specialInstructions
                                : "N/A"}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="card">
                      <div className="card-header font-weight-bold px-2">
                        Customer details
                      </div>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Customer Name</p>
                            <p className="mb-0 text-muted">
                              {orderData?.deliveryAddressId?.name || "N/A"}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0">Customer Phone No.</p>
                            <p className="mb-0 text-muted">
                              {orderData?.deliveryAddressId?.phone || "N/A"}
                            </p>
                          </div>
                        </li>
                        <li className="list-group-item px-2">
                          <div className="">
                            <p className="mb-0">Shipping Address:</p>
                            <p className="mb-0 text-muted">
                              {deliveryAddressFormat(
                                orderData?.deliveryAddressId?.address
                              ) || "N/A"}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ) : (
            <ChartSkeleton />
          )}
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
