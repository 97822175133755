import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { birthdateValidation } from "../../utils/utils";

const Step1 = ({ business, setNewBusiness }) => {
  const [showPass, setshowPassword] = useState(false);
  const [isTouched, setTouched] = useState(false);
  const [inputValue, setValue] = useState("");
  const [isEmailValid, setEmailValid] = useState(true);
  const [isDOBValid, setDOBValid] = useState(false);

  const handleClickShowPassword = () => {
    setshowPassword(!showPass);
  };

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const handleInput = (event) => {
    const { id, value } = event.target;
    setValue(value);
    validate(value, id);
    setNewBusiness({ ...business, [id]: value });
  };

  const validate = (val, id) => {
    const selectedDate = new Date(val);
    const minValidDate = new Date();
    minValidDate.setFullYear(minValidDate.getFullYear() - 18);

    if (id === "birthday") {
      if (selectedDate > minValidDate) setDOBValid(true);
      else setDOBValid(false);
    } else if (id === "email") {
      if (emailIsValid(val)) setEmailValid(false);
      else setEmailValid(true);
    }
  };

  return (
    <>
      <h5 className="wizard-title py-2">Credentials</h5>

      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            id="first_name"
            type="text"
            value={business?.first_name}
            onBlur={() => setTouched(true)}
            onChange={(event) => handleInput(event)}
            placeholder="First Name"
          />
        </div>
      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please enter first name!</p>
      )}

      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            type="text"
            id="last_name"
            value={business?.last_name}
            onChange={(event) => handleInput(event)}
            onBlur={() => setTouched(true)}
            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Last Name"
            required={true}
          />
        </div>
      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please enter last name!</p>
      )}

      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            type="date"
            id="birthday"
            max={birthdateValidation()}
            value={business?.birthday}
            onBlur={() => setTouched(true)}
            onChange={(event) => handleInput(event)}
            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
          />
        </div>
      </div>
      {isTouched && !inputValue && (
        <p style={{ color: "red" }}>Please enter your birth date!</p>
      )}
      {isTouched && isDOBValid && (
        <p style={{ color: "red" }}>Age must be 18 years old!</p>
      )}

      <div className="row mb-4">
        <div className="col">
          <input
            className="form-control"
            type="email"
            id="email"
            value={business?.email}
            onBlur={() => setTouched(true)}
            onChange={(event) => handleInput(event)}
            // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            placeholder="Email"
          />
        </div>
      </div>
      {isTouched && isEmailValid && (
        <p style={{ color: "red" }}>Please enter your valid email!</p>
      )}

      <div className="row mb-4">
        <div className="col">
          <div className="formWrapper">
            <input
              className="form-control"
              id="password"
              type={showPass ? "text" : "password"}
              onBlur={() => setTouched(true)}
              placeholder="Password"
              value={business?.password}
              onChange={(event) => handleInput(event)}

              // onChange={(event) => setNewBusiness({ ...business, [event.target.id]: event.target.value })}
            />
            <IconButton
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
              // onMouseDown={this.handleMouseDownPassword}
            >
              {showPass ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </div>
          {isTouched && !inputValue && (
            <p style={{ color: "red" }}>Please enter password!</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Step1;
