import config from "../config";
import { axiosConfig } from "../utils/utils";

const accessToken = localStorage.getItem("token");

const getInvoiceUsage = async (client_id) => {
  return await axiosConfig(
    "get",
    `${config.apiUrl}/use/${client_id}`,
    null,
    accessToken
  );
};

const getCouponUsage = async (client_id, coupon_id) => {
  return await axiosConfig(
    "get",
    `${config.apiUrl}/use/${client_id}/${coupon_id}`,
    null,
    accessToken
  );
};

export default {
  getCouponUsage,
  getInvoiceUsage,
};
