import React, { useEffect, useState } from "react";
import { axiosConfig, notify } from "../../utils/utils";
import config from "../../config";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../PaymentMethods/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";

const Step6Page = ({ business, setNewBusiness, setPage }) => {
  const [AllPlans, setAllPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");

  const history = useHistory();

  const LoginBAData = JSON.parse(localStorage.getItem("client"));
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const handleStartSubscription = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    const payload = {
      planId: selectedPlanId,
    };

    try {
      const response = await axiosConfig(
        "post",
        `${config.apiUrl}/businessadmin/${LoginBAData._id}/start-subscription`,
        payload,
        accessToken
      );

      window.open(response?.data?.redirectUrl, "_blank");
      history.push(setPage("signin"));
      notify("success", response?.data?.message);
    } catch (error) {
      console.error("subscription error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllPlans = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/plans/get-plans`,
        null,
        accessToken
      );
      let allPlans = response?.data?.data?.plans;
      setAllPlans(allPlans);
    } catch (error) {
      console.error("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const handlePlanSelect = (planId) => {
    setSelectedPlanId(planId);
  };

  return (
    <Grid container spacing={4} className="my-3 d-flex justify-content-center">
      {selectedPlanId ? (
        <div className="w-100 mx-4">
          <Elements
            stripe={stripePromise}
            // options={options}
          >
            <CheckoutForm successCallback={handleStartSubscription} />
          </Elements>
        </div>
      ) : isLoading ? (
        <div
          style={{
            border: "1px solid #e0e0e0",
            borderRadius: "20px",
            padding: "32px",
            background: "#FFFFFF",
            width: "313px",
            height: "465px",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spin indicator={<LoadingOutlined spin />} />
        </div>
      ) : AllPlans.length ? (
        AllPlans.map((Item, planIndex) => {
          const features = Item.description?.trim()?.split("\n");
          return (
            <Grid item xs={12} md={6} key={planIndex}>
              <div
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "20px",
                  padding: "32px",
                  background: "#FFFFFF",
                  height: "100%",
                }}
              >
                <Typography style={{ fontSize: "22px", fontWeight: 600 }}>
                  {Item.name} ({Item.interval})
                </Typography>
                <Typography
                  style={{
                    fontSize: "48px",
                    fontWeight: 700,
                    padding: "6px 0px 12px 0px",
                  }}
                >
                  ${Item.amount}
                </Typography>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    paddingBottom: "28px",
                  }}
                >
                  Whats include:
                </Typography>
                <Box sx={{ minHeight: "150px" }}>
                  {features.map((subItem, featureIndex) => {
                    return (
                      <Typography
                        key={featureIndex}
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          lineHeight: "32px",
                        }}
                      >
                        {subItem}
                      </Typography>
                    );
                  })}
                </Box>
                <Button
                  variant="contained"
                  style={{
                    width: "100%",
                    height: "48px",
                    marginTop: "20px",
                  }}
                  onClick={() => handlePlanSelect(Item?.stripePlanId)}
                >
                  Get Started
                </Button>
              </div>
            </Grid>
          );
        })
      ) : (
        <div
          style={{
            border: "1px solid #e0e0e0",
            borderRadius: "20px",
            padding: "32px",
            background: "#FFFFFF",
            width: "313px",
            height: "465px",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          No Available Plans
        </div>
      )}
    </Grid>
  );
};

export default Step6Page;
