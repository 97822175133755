// /* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import HomeIcon from "@material-ui/icons/Home";
import { Upload } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, InputGroup } from "react-bootstrap";
import {
  DateRangePicker,
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
} from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCount } from "../../categories";
import ChartSkeleton from "../../components/ChartSkeleton";
import CouponUsageViewsChart from "../../components/Coupon/CouponUsageViewsCharts";
import config from "../../config";
import services from "../../services/index";
import { uploadFileOnS3 } from "../../helper/s3Uploader";
import { renameS3File } from "../../helper/renameS3File";
import { getFileDimensions } from "../../helper/getFileDimensions";
import { getS3FileDimensions } from "../../helper/getS3FileDimensions";
toast.configure();

const CouponDetails = ({ setPage, loadtype }) => {
  const couponImage = require("../../assets/images/logo-placeholder.png");
  const { client } = useSelector((state) => state);
  const { campaign, category } = client;
  const [coupon, setCoupon] = useState(client.coupon);
  const [usage, setUsage] = useState([]);
  const [views, setViews] = useState([]);
  const [saves, setSaves] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editCoupon, setEditCoupon] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [getCategory, setCategory] = useState("");
  const [getSubcategory, setSubcategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [focusedInput, setFocusedInput] = useState(null);
  const [img, setImg] = useState();
  const [isDiscountValid, setIsDiscountInvalid] = useState(false);

  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [apiFiles, setApiFiles] = useState([]);
  const [newUploadedFile, setNewUploadedFile] = useState([]);
  const [oldEditedFile, setOldEditedFile] = useState([]);

  console.log("editCoupon====>", editCoupon, subCategories);

  const notify = (type, text) =>
    toast[type](text, {
      position: toast.POSITION.TOP_RIGHT,
    });

  const getCoupon = async () => {
    await services.CouponServices.retrieveCoupon(coupon._id)
      .then((res) => {
        console.log("get coupon", res?.data);
        setApiFiles(res?.data?.data?.coupon?.images);
        setCoupon(res?.data?.data?.coupon);
        // setEditCoupon(res?.data?.data?.coupon);
        setUsage(res?.data?.data?.usage);
        setViews(res?.data?.data?.views);
        setImg(res?.data?.data?.coupon.image);
        setSaves(res?.data?.data?.saves);
        setCategory(res?.data?.data?.coupon?.category);
        setSubcategory(res?.data?.data?.coupon?.subcategory || "");
      })
      .catch((err) => console.error("getCouponn", err));
  };

  const handleEdit = async () => {
    setLoading(true);

    let dimensions;
    if (apiFiles?.length === 0) {
      dimensions = await getFileDimensions(newUploadedFile[0]);
    } else {
      dimensions = await getS3FileDimensions(apiFiles[0]);
    }

    let renamedUrl = [];
    let newUrl = [];
    let oldUrl = coupon?.images?.map((file) => {
      return { images_url: file.images_url };
    });

    oldEditedFile &&
      (await renameS3File(oldEditedFile, dimensions, "product")
        .then((res) => {
          if (res?.status) {
            renamedUrl.push(...res?.data);
          } else {
            notify("error", "Something went to wrong.");
            setLoading(false);
            setErrorMessage("");
          }
        })
        .catch((error) => {
          notify("error", "Something went to wrong.");
          console.log("image upload error", error);
          setLoading(false);
          setErrorMessage("");
        }));

    newUploadedFile &&
      (await uploadFileOnS3(newUploadedFile, dimensions, "product")
        .then((res) => {
          res.forEach((item) => {
            newUrl.push({ images_url: item.Location });
          });
        })
        .catch((error) => {
          notify("error", "Something went to wrong.");
          console.log("image upload error", error);
          setLoading(false);
          setErrorMessage("");
        }));

    let files = [];

    if (oldEditedFile?.length === 0 && newUploadedFile?.length !== 0) {
      if (apiFiles?.length === 0) {
        files.push(...newUrl);
      } else {
        files.push(...oldUrl);
        files.push(...newUrl);
      }
      editCoupon.images = files;
    }
    if (oldEditedFile?.length !== 0 && newUploadedFile?.length !== 0) {
      files.push(...renamedUrl);
      files.push(...newUrl);
      editCoupon.images = files;
    }
    if (oldEditedFile?.length !== 0 && newUploadedFile?.length === 0) {
      files.push(...renamedUrl);
      editCoupon.images = files;
    }

    await services.CouponServices.updateCoupon(coupon._id, editCoupon)
      .then((res) => {
        if (res?.data?.err) {
          setLoading(false);
          notify("error", res?.data?.message);
          return;
        }
        setShowEdit(false);
        setCoupon(res?.data?.data?.coupon);
        getCoupon();
        setNewUploadedFile([]);
        setOldEditedFile([]);
        setLoading(false);
        notify("success", "Offer updated successfully.");
      })
      .catch((e) => {
        notify("error", "Something went to wrong.");
        console.log(e);
        setLoading(false);
      });
  };

  const handleDelete = () => {
    services.CouponServices.deleteCoupon(coupon._id)
      .then((res) => {
        setPage("campaign-details");
      })
      .catch((err) => console.log("Delete Coupon error: ", err));
  };

  const id = localStorage.getItem("campaignid");

  useEffect(() => {
    getCoupon();
    setEditCoupon({
      ...editCoupon,
      category: parseInt(coupon?.category),
      subcategory: parseInt(coupon?.subcategory),
      start_date: moment(coupon?.start_date).utc(),
      expiration: moment(coupon?.expiration).utc(),
      _id: id,
    });
  }, []);

  let newCouponStartDate;
  if (moment(campaign.campaign_start_date).unix() > moment().unix()) {
    newCouponStartDate = moment(campaign.campaign_start_date).subtract(
      1,
      "days"
    );
  } else {
    newCouponStartDate = moment().subtract(1, "days");
  }

  const setRunPeriod = (startDate, endDate) => {
    var newEndDate = endDate != null ? moment(endDate) : moment(startDate);
    setEditCoupon({
      ...editCoupon,
      start_date: startDate,
      expiration: newEndDate,
    });
  };

  const onCloseModal = () => {
    setShowEdit(false);
    setLoading(false);
    setNewUploadedFile([]);
  };

  let tempFile = [];
  const propsUploadFile = {
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((item) => item !== file)
      );

      const filteredImages = newUploadedFile.filter((item) => item !== file);
      setNewUploadedFile([...filteredImages]);
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      const isVideo = file.type.startsWith("video/");

      const maxSize = 50 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorMessage("File size exceeds the maximum allowed size (50MB).");
        return false;
      } else {
        setErrorMessage("");
      }

      // If it's an image or video, add it to the fileList
      if (isImage || isVideo) {
        setFileList((prevFileList) => [...prevFileList, file]);
        tempFile.push(file);
        setNewUploadedFile([...newUploadedFile, ...tempFile]);
      } else {
        setErrorMessage(
          "Invalid file type. Only images and videos are allowed."
        );
        return false;
      }
      return false;
    },
    fileList,
    multiple: true,
    showUploadList: false,
  };

  const handlePreview = (file) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isImage) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    } else if (isVideo) {
    }
  };

  const handleRemoveNewFile = (file) => {
    setFileList((prevFileList) => prevFileList.filter((item) => item !== file));
    const filteredImages = newUploadedFile.filter((item) => item !== file);
    setNewUploadedFile([...filteredImages]);
    setErrorMessage("");
  };

  const handleRemoveOldFile = (file) => {
    const filteredImages = apiFiles.filter((item) => item.id !== file.id);
    setApiFiles(filteredImages);
    setOldEditedFile(filteredImages);
  };

  useEffect(() => {
    if (newUploadedFile?.length + apiFiles?.length > 5) {
      setErrorMessage("You can only upload up to 5 files.");
    } else {
      setErrorMessage("");
    }
  }, [newUploadedFile, apiFiles]);

  const handleDiscountChange = (event) => {
    const value = event.target.value;
    if (value < 1 || value > 100) {
      setIsDiscountInvalid(true);
    } else {
      setIsDiscountInvalid(false);
    }
    setEditCoupon({
      ...editCoupon,
      discount: Number(value),
    });
  };

  return (
    <>
      <>
        <div className="row">
          <div className="col">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                href="javascript:;"
                onClick={() => setPage("dashboard")}
              >
                <HomeIcon />
              </Link>
              {loadtype == "C" && (
                <Link
                  color="inherit"
                  href="javascript:;"
                  onClick={() => setPage("campaigns")}
                >
                  <Typography color="primary">Campaigns</Typography>
                </Link>
              )}
              {loadtype == "P" && (
                <Link
                  color="inherit"
                  href="javascript:;"
                  onClick={() => setPage("promotions")}
                >
                  <Typography color="primary">Promotions</Typography>
                </Link>
              )}
              {loadtype == "C" && (
                <Link
                  color="inherit"
                  href="javascript:;"
                  onClick={() => setPage("campaign-details")}
                >
                  <Typography color="primary">Campaign Details</Typography>
                </Link>
              )}
              {loadtype == "P" && (
                <Link
                  color="inherit"
                  href="javascript:;"
                  onClick={() => setPage("promotion-details")}
                >
                  <Typography color="primary">Promotion Details</Typography>
                </Link>
              )}
              <Typography color="textPrimary">Offer Details</Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {coupon ? (
              <Card>
                <CardHeader
                  title="Offer Details"
                  subheader="Manage offer details"
                  action={
                    <>
                      <IconButton
                        aria-label="edit campaign"
                        title="Edit Product"
                        onClick={() => {
                          setShowEdit(true);
                          setEditCoupon({
                            ...editCoupon,
                            category: getCategory,
                          });
                          setSubCategories(
                            category.filter(
                              (sub) => sub.category === getCategory
                            )
                          );
                          setApiFiles(coupon?.images);
                          // setSubCategories(subcategories.filter(sub => sub.categoryId === (getCategory)))
                        }}
                      >
                        <EditIcon title="Edit Offer" />
                      </IconButton>
                      <IconButton
                        aria-label="delete campaign"
                        title="Delete Offer"
                        onClick={() => setShowDelete(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  }
                />
                <CardContent>
                  <div className="row">
                    <div className="col-12 col-xl-3">
                      {img ? (
                        img?.includes("video") ? (
                          <video
                            src={`${config.awsBucketUrl}${img}`}
                            style={{ width: "auto", height: "120px" }}
                            controls
                          ></video>
                        ) : (
                          <img
                            src={`${config.awsBucketUrl}${img}`}
                            alt="Coupon"
                            style={{ width: "auto", height: "120px" }}
                            loading="lazy"
                          />
                        )
                      ) : (
                        <img
                          src={couponImage}
                          alt="prev"
                          style={{ width: "auto", height: "120px" }}
                        />
                      )}
                    </div>
                    <div className="col-12 col-xl-9 mt-3 mt-xl-0">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                          <h6>
                            <u>Offer Name</u>
                          </h6>
                          <p>{coupon.name}</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <h6>
                            <u>Offer Description</u>
                          </h6>
                          <p>{coupon.description}</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <h6>
                            <u>Run Time</u>
                          </h6>
                          <p>
                            {moment(coupon.start_date).format("MMM Do YYYY")} -{" "}
                            {moment(coupon.expiration).format("MMM Do YYYY")}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <h6>
                            <u>Category - Subcategory</u>
                          </h6>
                          <p>
                            {(getCategory && getCategory) || ""} -{" "}
                            {(getSubcategory && getSubcategory) ||
                              "no subcategory"}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <h6>
                            <u>Use Per Customer</u>
                          </h6>
                          <p>{coupon.use_count || 1}</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <h6>
                            <u>Barcode</u>
                          </h6>
                          <p>{coupon.barcode}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ) : (
              <ChartSkeleton />
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <Card>
              <CardHeader
                title="Usage, Views, and Saves"
                subheader="Daily trend for this offer."
              />
              <CardContent>
                <CouponUsageViewsChart
                  data={{ coupon, usage, views, saves }}
                  height="300px"
                />
              </CardContent>
            </Card>
          </div>
        </div>
      </>

      {/* edit offer */}
      <Modal
        centered
        show={showEdit}
        onHide={() => onCloseModal()}
        size="lg"
        className="edit_offer_modal"
      >
        <Modal.Header closeButton variant="danger">
          <Modal.Title>EDIT A OFFER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="couponForm" encType="multipart/form-data">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="form-group">
                  <Form.Label>Offer Name</Form.Label>
                  <Form.Control
                    className="DateRangePickerInput__withBorder DateRangePickerInput__withBorder_2 DateRangePickerInput DateInput_input"
                    placeholder="Offer Name"
                    type="text"
                    defaultValue={editCoupon.name || coupon.name}
                    onChange={(event) =>
                      setEditCoupon({ ...editCoupon, name: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                <Form.Label>Offer Run Period</Form.Label>
                <DateRangePicker
                  orientation={
                    window.innerWidth >= 768 ? "horizontal" : "vertical"
                  }
                  startDateId="startDate"
                  endDateId="endDate"
                  isOutsideRange={(day) =>
                    isInclusivelyAfterDay(
                      day,
                      moment(campaign.campaign_expiration_date).add(1, "day")
                    ) || isInclusivelyBeforeDay(day, newCouponStartDate)
                  }
                  startDate={editCoupon?.start_date || coupon.start_date}
                  endDate={editCoupon?.expiration || coupon.expiration}
                  //  onDatesChange={({ startDate, endDate }) => setEditCoupon({ ...editCoupon, start_date: startDate, expiration: endDate })}
                  onDatesChange={({ startDate, endDate }) =>
                    setRunPeriod(startDate, endDate)
                  }
                  focusedInput={focusedInput}
                  onFocusChange={(focused) => setFocusedInput(focused)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-12 mt-3">
                <Form.Label>Offer Description</Form.Label>
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={(event) =>
                    setEditCoupon({
                      ...editCoupon,
                      description: event.target.value,
                    })
                  }
                  defaultValue={editCoupon.description || coupon.description}
                />
              </div>

              <div className="col-lg-6 col-12 mt-3" controlId="discount">
                <Form.Label>Discount</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="number"
                    min={1}
                    max={100}
                    style={{ height: "48px" }}
                    placeholder="Discount"
                    defaultValue={editCoupon.discount || coupon.discount}
                    onChange={handleDiscountChange}
                    isInvalid={isDiscountValid}
                    required
                  />
                  <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    Please enter a discount value between 1 and 100.
                  </Form.Control.Feedback>
                </InputGroup>
              </div>
              <div className="col-lg-6 col-12 mt-3">
                <Form.Label>Barcode</Form.Label>
                <Form.Control
                  style={{ height: 48 }}
                  placeholder="Barcode #"
                  type="text"
                  defaultValue={editCoupon.barcode || coupon.barcode}
                  onChange={(event) =>
                    setEditCoupon({
                      ...editCoupon,
                      barcode: event.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12 mt-3" controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  style={{ height: 48 }}
                  value={
                    editCoupon.category ||
                    coupon?.category ||
                    (getCategory && getCategory)
                  }
                  onChange={(event) => {
                    setEditCoupon({
                      ...editCoupon,
                      category: event.target.value,
                      subcategory: "",
                    });
                    setSubCategories(
                      category.filter(
                        (sub) => sub.category === event.target.value
                      )
                    );
                  }}
                >
                  <option>Choose a category</option>
                  {category?.map((cat, index) => (
                    <option key={index} value={cat.category}>
                      {cat.category}
                    </option>
                  ))}
                </Form.Control>
              </div>
              <div className="col-lg-4 col-12 mt-3" controlId="subcategory">
                <Form.Label>Sub-Category</Form.Label>
                <Form.Control
                  as="select"
                  style={{ height: 48 }}
                  value={editCoupon.subcategory || getSubcategory}
                  onChange={(event) => {
                    console.log("sub category value", event.target.value);
                    setEditCoupon({
                      ...editCoupon,
                      subcategory: event.target.value,
                    });
                    setSubcategory(event.target.value || "");
                  }}
                >
                  <option value="">Choose a category first</option>
                  {!_.isEmpty(editCoupon.category) &&
                    _.isEmpty(subCategories?.[0]?.subcategory) && (
                      <option value="">There are no subcategories</option>
                    )}
                  {subCategories[0]?.subcategory?.map((subcategory, index) => (
                    <option key={index} value={subcategory}>
                      {subcategory}
                    </option>
                  ))}
                </Form.Control>
              </div>
              <div className="col-lg-4 col-12 mt-3">
                <Form.Label>Use Per Customer</Form.Label>
                <Form.Control
                  as="select"
                  style={{ height: 48 }}
                  value={editCoupon.use_count}
                  onChange={(event) =>
                    setEditCoupon({
                      ...editCoupon,
                      use_count: event.target.value,
                    })
                  }
                >
                  {useCount.map((use, index) => (
                    <option
                      key={index}
                      selected={coupon.use_count === use.id}
                      value={use.id}
                    >
                      {use.name}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div>
                  <Form.Label>Offer Image / Video (50 MB max.)</Form.Label>
                  <Upload
                    {...propsUploadFile}
                    onPreview={handlePreview}
                    accept="image/*,video/*"
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        className="choose_file_button"
                        onClick={() => setErrorMessage("")}
                      >
                        Choose file
                      </Button>
                    </div>
                  </Upload>
                  {errorMessage && (
                    <div className="mt-1 text-danger">{errorMessage}</div>
                  )}
                </div>
              </div>
              <div className="col-12 mt-3">
                <Form.Label>Offer Preview</Form.Label>
                {apiFiles?.length === 0 && newUploadedFile?.length === 0 && (
                  <img
                    src={couponImage}
                    alt="Coupon"
                    width="150px"
                    height="100px"
                  />
                )}
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {apiFiles.map((file, fileIndex) => {
                    const { images_url, fileType } = file;
                    const isImage = fileType === "image";
                    const isVideo = fileType === "video";

                    return (
                      <div key={fileIndex}>
                        {isImage && (
                          <div className="position-relative d-inline-block">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveOldFile(file)}
                            >
                              {" "}
                              X
                            </div>
                            <img
                              src={images_url}
                              alt={file.name}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                        {isVideo && (
                          <div className="position-relative d-inline-block">
                            <div
                              className="upload_preview_cancel_icon"
                              onClick={() => handleRemoveOldFile(file)}
                            >
                              X
                            </div>
                            <video
                              controls
                              src={images_url}
                              className="border rounded"
                              style={{ width: "auto", height: "100px" }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  {newUploadedFile?.length !== 0 &&
                    newUploadedFile.map((file) => {
                      return (
                        <>
                          {file.type.startsWith("image/") ? (
                            <div className="position-relative">
                              <div
                                className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveNewFile(file)}
                              >
                                X
                              </div>
                              <img
                                src={URL?.createObjectURL(file)}
                                alt={file.name}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          ) : (
                            <div className="position-relative">
                              <div
                                className="upload_preview_cancel_icon"
                                onClick={() => handleRemoveNewFile(file)}
                              >
                                X
                              </div>
                              <video
                                controls
                                src={URL?.createObjectURL(file)}
                                className="border rounded"
                                style={{ width: "auto", height: "100px" }}
                              />
                            </div>
                          )}
                        </>
                      );
                    })}
                </div>
                {/* <Row gutter={16}>
                  {coupon?.images?.map(item => {
                    const { _id, images_url, fileType } = item;
                    const isImage = fileType === "image";
                    const isVideo = fileType === "video";
                    return (
                      <Col className="gutter-row" key={_id} style={{ marginBottom: '16px' }}>
                        {isImage && <img src={images_url} className='border rounded' alt={`Image ${_id}`} style={{ width: 'auto', height: '100px' }} />}
                        {isVideo && (
                          <video controls src={images_url} className='border rounded' style={{ width: 'auto', height: '100px', minWidth: "150px" }}></video>
                        )}
                      </Col>
                    );
                  })}
                </Row> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={
              loading ||
              (apiFiles?.length === 0 && newUploadedFile?.length === 0) ||
              errorMessage ||
              isDiscountValid
            }
            // disabled={
            //   validateCoupon(formatValidationJson()) ||
            //   loading ||
            //   errorMessage ||
            //   isDiscountValid
            // }
            onClick={() => handleEdit()}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="mr-2"
              />
            )}
            Update Offer
          </Button>
          <Button
            variant="outline-dark"
            onClick={() => onCloseModal()}
            style={{ marginLeft: "20px" }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete offer */}
      <Modal
        centered
        show={showDelete}
        onHide={() => setShowDelete(false)}
        size="md"
      >
        <Modal.Header closeButton variant="danger">
          <Modal.Title>DELETE A OFFER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <b>"{coupon.name}"</b>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => handleDelete()}>
            Yes
          </Button>
          <Button variant="outline-dark" onClick={() => setShowDelete(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CouponDetails;
