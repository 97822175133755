import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

const CouponUsageViewsChart = ({ data }) => {
  const { coupon, usage, views, saves } = data;
  // // const start = moment(coupon.start_date).startOf('day');
  // // const end = moment(coupon.expiration).endOf('day');
  // const dayCount = end.diff(start, 'days') + 1;

  let cusage = [];
  let cviews = [];

  let categories = [];
  // for (var i=0; i < dayCount; i++) {
  //   const date = moment(coupon.start_date).add(i, 'days').format('MM/DD');
  //   const useCount = usage.filter(use => moment(use.created_at).format('MM/DD') === date) || [];
  //   const viewCount = views.filter(view => moment(view.created_at).format('MM/DD') === date) || [];
  //   const saveCount = saves.filter(save => moment(save.created_at).format('MM/DD') === date) || [];

  //   categories.push(date);
  //   cusage.push(useCount?.length);
  //   cviews.push(viewCount?.length);
  //   csaves.push(saveCount?.length);
  // }

  const options = {
    chart: {
      type: 'column'
    },
    colors: ['#1de9b6', '#1dc4e9', '#A389D4', '#899FD4'],
    title: {
      text: ''
    },
    xAxis: {
      categories: categories,
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Usage, Save, and View Count'
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0
      }
    },
    series: [{
      name: 'Usage',
      data: [usage?.length]
    }, {
      name: 'Views',
      data: [views?.length]
    }, {
      name: 'Saves',
      data: [saves?.length]
    }],
    credits: {
      enabled: false
   }
  };
  return (
      <HighchartsReact
          highcharts={Highcharts}
          options={options}
      />
  );
}

export default CouponUsageViewsChart;