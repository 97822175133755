import { SearchOutlined } from "@ant-design/icons";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { debounce } from "lodash";
import PropTypes from "prop-types";

import { LoadingOutlined } from "@ant-design/icons";
import { Box, TablePagination } from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import config from "../../../config";
import { axiosConfig } from "../../../utils/utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    // border: 'none',
  },
});

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const BusinessOwnerTable = ({ headings }) => {
  const [allBusinessData, setAllBusinessData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState("");

  const [isActiveBusinessOwnerValue, setIsActiveBusinessOwnerValue] =
    useState("");
  const [isPostOnSocialMediaValue, setIsPostOnSocialMediaValue] = useState("");
  const [isIsPaidForContentsValue, setIsIsPaidForContentsValue] = useState("");
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageCount, setPageCount] = useState(0);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPageCount(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageCount(0);
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query !== lastSearchQuery) {
      // Check if the query has changed
      debouncedSearch(query);
    }
  };

  const getAllBusinessOwner = async () => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner?page=${
          pageCount + 1
        }&limit=${rowsPerPage}`,
        null,
        accessToken
      );
      setAllBusinessData(response?.data?.data);
    } catch (error) {
      console.log("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBusinessOwner();
  }, []);

  const getAllBusinessOwnerIsActive = async (isActiveBO) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner?page=${
          pageCount + 1
        }&limit=${rowsPerPage}&isActive=${isActiveBO}`,
        null,
        accessToken
      );
      setAllBusinessData(response?.data?.data);
      setTotal(response?.data?.data?.total);
    } catch (error) {
      console.log("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeIsActiveBusinessOwner = (e) => {
    setIsActiveBusinessOwnerValue(e.currentTarget.value);
    getAllBusinessOwnerIsActive(e.currentTarget.value);
  };

  const FilteredProductBOIsPaidForContents = async (IsPaidForContents) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner?page=${
          pageCount + 1
        }&limit=${rowsPerPage}&isPaidForContents=${IsPaidForContents}`,
        null,
        accessToken
      );
      setAllBusinessData(response?.data?.data);
      setTotal(response?.data?.data?.total);
    } catch (error) {
      console.log("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeIsPaidForContents = (e) => {
    setIsIsPaidForContentsValue(e.currentTarget.value);
    FilteredProductBOIsPaidForContents(e.currentTarget.value);
  };

  const FilteredProductBOPostOnSocialMedia = async (PostOnSocialMedia) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "get",
        `${config.apiUrl}/businessowner?page=${
          pageCount + 1
        }&limit=${rowsPerPage}&postOnSocialMedia=${PostOnSocialMedia}`,
        null,
        accessToken
      );
      setAllBusinessData(response?.data?.data);
      setTotal(response?.data?.data?.total);
    } catch (error) {
      console.log("Error getting all business", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePostOnSocialMedia = (e) => {
    setIsPostOnSocialMediaValue(e.currentTarget.value);
    FilteredProductBOPostOnSocialMedia(e.currentTarget.value);
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      const accessToken = localStorage.getItem("token");
      setIsLoading(true);
      try {
        const response = await axiosConfig(
          "get",
          `${config.apiUrl}/businessowner?page=${
            pageCount + 1
          }&limit=${rowsPerPage}&search=${query}`,
          null,
          accessToken
        );
        setAllBusinessData(response?.data?.data);
        setTotal(response?.data?.data?.total);
        setLastSearchQuery(query);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    }, 500),
    []
  );

  const resetFilters = () => {
    setIsActiveBusinessOwnerValue("");
    setIsPostOnSocialMediaValue("");
    setIsIsPaidForContentsValue("");
    setSearchQuery("");
    setPage(1);
    getAllBusinessOwner();
  };

  const businessOwnerActiveDeActive = async (UserID) => {
    const accessToken = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosConfig(
        "patch",
        `${config.apiUrl}/businessowner/${UserID}`,
        null,
        accessToken
      );
      setAllBusinessData(response?.data?.data);
      getAllBusinessOwner();
      console.log("response.data", response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {
        <>
          <div className="search mb-2">
            <div style={{ display: "flex" }}>
              <select
                className="mr-2 form-control"
                style={{ height: "38px" }}
                value={isActiveBusinessOwnerValue}
                onChange={handleChangeIsActiveBusinessOwner}
              >
                <option value="">BusinessOwner Status</option>
                <option value="true">ACTIVATE</option>
                <option value="false">DEACTIVATE</option>
              </select>

              <select
                className="mr-2 form-control"
                style={{ height: "38px" }}
                value={isPostOnSocialMediaValue}
                onChange={handleChangePostOnSocialMedia}
              >
                <option value="">Post Frequenc</option>
                <option value="Less frequently">Less frequently</option>
                <option value="Monthly">Monthly</option>
                <option value="Weekly">Weekly</option>
                <option value="Daily">Daily</option>
              </select>

              <select
                className="mr-2 form-control"
                style={{ height: "38px" }}
                value={isIsPaidForContentsValue}
                onChange={handleChangeIsPaidForContents}
              >
                <option value="">Is Paid Content</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>

              <div className="mr-2">
                <div className="searchFrom products_search_bar w-100">
                  <input
                    type="text"
                    value={searchQuery}
                    style={{ height: "38px" }}
                    onChange={handleSearchInputChange}
                    id="kt_filter_search"
                    className="form-control"
                    placeholder="Search.."
                  />
                  <SearchOutlined />
                </div>
              </div>
              <Button
                style={{ height: "38px" }}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  resetFilters();
                }}
              >
                Reset
              </Button>
            </div>
          </div>

          <TableContainer className={classes.container}>
            <Table style={{ minWidth: "750px" }}>
              <TableHead>
                <TableRow>
                  {headings.map((head, index) => (
                    <StyledTableCell key={index} align="left">
                      {head}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Box
                        sx={{
                          height: "500px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0px 12px",
                          width: "100%",
                        }}
                      >
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 32,
                              }}
                              spin
                            />
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : allBusinessData && allBusinessData.users?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: "center" }}>
                      No data found...
                    </TableCell>
                  </TableRow>
                ) : (
                  allBusinessData &&
                  allBusinessData.users.map((Item, I) => {
                    const clientLogoUrl = `https://xclusit-dev.s3.amazonaws.com${Item.client_logo}`;
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={I}>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.name}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.email}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.phone}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.city}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.state}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.postOnSocialMedia ?? "-"}
                        </TableCell>
                        <TableCell style={{ minWidth: "150px" }}>
                          {Item.isPaidForContents ? "Yes" : "No"}
                        </TableCell>
                        <StyledTableCell style={{ minWidth: "150px" }}>
                          <Button
                            variant="contained"
                            color={Item.active ? "Secondary" : "primary"}
                            className={classes.button}
                            onClick={() =>
                              businessOwnerActiveDeActive(Item._id)
                            }
                          >
                            {Item.active ? "DEACTIVATE" : "ACTIVATE"}
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell style={{ minWidth: "150px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              background: Item.isVerified
                                ? "#DCF4EF"
                                : "#F4DACD",
                              width: "fit-content",
                              padding: "4px 8px",
                              borderRadius: "30px",
                              gap: "4px",
                              color: Item.isVerified ? "#00805D" : "#FF7100",
                              fontWeight: "600",
                            }}
                          >
                            {Item.isVerified ? (
                              <VerifiedIcon
                                sx={{ height: "18px", width: "18px" }}
                              />
                            ) : (
                              <HourglassTopIcon
                                sx={{ height: "18px", width: "18px" }}
                              />
                            )}
                            <Typography
                              variant="body1"
                              style={{ fontSize: "12px" }}
                            >
                              {Item.isVerified ? "VERIFIED" : "PENDING"}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={pageCount}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      }
    </>
  );
};

export default BusinessOwnerTable;
